<div *ngIf="!position.Deleted" class="card document-position-card">
  <div class="flexContainer">
    <div class="flexContainerItem">
      <div (click)="gotoPosition()">
        <p>
          <b>{{ positionsHeadline }}</b>
        </p>
        <p *ngIf="numberValue">{{ numberValue }}</p>
        <p *ngIf="mengeOrZeit">{{ mengeOrZeit }}</p>
        <p *ngIf="einzelpreis">{{ einzelpreis }}</p>
        <p *ngIf="gesamtPreis">{{ gesamtPreis }}</p>
      </div>
      <app-bss-switchbox
        *ngIf="showPrintStuecklistenChoice"
        [labelText]="'Stücklisten beim Druck auflösen'"
        [value]="printStueckliste"
        (valueChange)="changeStueckListenPrint(order, position)"
        [disabled]="disabled"
      ></app-bss-switchbox>
    </div>
    <dx-button *ngIf="deleteAble" [icon]="'trash'" (click)="deletePosition(order, position)"></dx-button>
  </div>
</div>
