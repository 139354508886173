import moment from 'moment';
import {
  deDateFormat,
  deDateTimeFormat,
  deDateTimeFormatWithSeconds,
  timeFormat,
} from '../../../../../apps/handwerkPWA/src/app/config';
import { GlobalHelper } from './globalHelper';

export class TimeHelper {
  /**@description Prüft ob ein String als ein Valides Date interpretiert werden kann
   */
  static validateDateInput(dateString: string, dateFormats: string[]): boolean {
    const validFormats = dateFormats ? dateFormats : [deDateTimeFormatWithSeconds, deDateTimeFormat, deDateFormat];
    return moment(dateString, validFormats, true).isValid();
  }

  /**Nimmt ein formatiertes Datum im Format 31.12.2001 00:00:00 und gibt ein date zurück */
  static databaseDateToDate(dateString: string, withTime?: boolean): Date {
    const isValid = TimeHelper.validateDateInput(dateString, [deDateTimeFormatWithSeconds]);
    if (!isValid) return null;

    if (withTime) return moment(dateString, deDateTimeFormat).toDate();
    else return moment(dateString, deDateFormat).toDate();
  }

  /**@description takes a date string "31.12.2001" and a time String "00:00" and returns a Date */
  static createDateFromStrings(dateString: string, timeString: string): Date {
    const [day, month, year] = dateString.split('.').map(Number);
    const [hours, minutes] = timeString.split(':').map(Number);
    // Month -1 due to Date object starting the months at 0 for January
    const date = new Date(year, month - 1, day, hours, minutes);
    return date;
  }

  /**@description Nimmt ein Date und gibt es im Format dd.mm.yyyy zurück (dd.mm.yyyy HH:mm wenn withTime auf true) (dd.mm.yyyy HH:mm:ss wenn withTime auf true) */
  static dateToDatabaseDate(date: Date, withTime?: boolean, withSeconds?: boolean): string {
    if (!date) {
      throw new Error('Date can not be null');
    }

    let formatString = deDateFormat;
    if (withTime) formatString = deDateTimeFormat;
    if (withSeconds) formatString = deDateTimeFormatWithSeconds;
    const dateString = moment(date).format(formatString);
    return dateString;
  }

  /**@description Nimmt eine Zeit in Minuten und gibt sie im Format x Stunden y Minuten zurück */
  static getStundenMinutenString(zeit: number): string {
    const hours = Math.floor(zeit / 60);
    const minutes = zeit % 60;
    let stundenstring = ' Stunde ';
    let minutenString = ' Minuten ';
    if (hours > 1) {
      stundenstring = ' Stunden ';
    }
    if (minutes === 1) {
      minutenString = ' Minute ';
    }
    if (hours === 0) {
      return minutes + minutenString;
    }
    if (minutes === 0) {
      return hours + stundenstring;
    }

    return hours + stundenstring + minutes + minutenString;
  }

  /**parsed einen Date string im Format dd.MM.YYYY oder dd.MM.YYYY HH:mm */
  static parseTermin(dateString: string): Date {
    dateString = dateString?.trim();
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(dateString)) return null;
    const split = dateString.split(' ');
    if (split.length === 1) return moment(dateString, deDateFormat).toDate();
    if (split.length === 2) return moment(dateString, deDateTimeFormat).toDate();
    return null;
  }

  /**führt führende Nullen ein */
  static padTime(hoursOrMinutes: number): string {
    const value = hoursOrMinutes.toString();
    return value.padStart(2, '0');
  }

  static dateTimeToDateString(date: Date): string {
    const dateString = moment(date).format(deDateFormat);
    return dateString;
  }

  static dateTimeToTimeString(date: Date): string {
    const timeString = moment(date).format(timeFormat);
    return timeString;
  }

  static dateToDateTimeString(date: Date): string {
    return moment(date).format(deDateTimeFormat);
  }

  static getYear(date: Date): string {
    return moment(date).format('YYYY');
  }

  static getMonth(date: Date): string {
    return moment(date).format('MM');
  }

  static getDay(date: Date): string {
    return moment(date).format('DD');
  }

  static addMonths(date: Date, months: number): Date {
    const dateCopy = new Date(date);
    return new Date(dateCopy.setMonth(date.getMonth() + months));
  }

  static addDays(date: Date, days: number): Date {
    const dateCopy = new Date(date);
    return new Date(dateCopy.setDate(date.getDate() + days));
  }

  static getDate(date: Date): Date {
    const dateObj = moment(TimeHelper.dateTimeToDateString(date), deDateFormat);
    return dateObj.toDate();
  }
}
