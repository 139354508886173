import { Injectable } from '@angular/core';
import { FeatureNames, Right, RightsService } from '@handwerk-pwa/shared';
import { BehaviorSubject } from 'rxjs';
import { PageSetting } from '../../config';

@Injectable({
  providedIn: 'root',
})
export class PageSettingService {
  currentPageSettings = new BehaviorSubject<PageSetting[]>(null);
  constructor(private rightService: RightsService) {
    this.rightService.getCurrentRightAsync().subscribe(right => {
      this.createNewPageSettings(right);
    });
  }

  createNewPageSettings(inputRight: Right): void {
    // Right is null during loginProcess before the User loaded Rights
    const employeeRight = inputRight?.employeeRights;
    if (!employeeRight) {
      this.currentPageSettings.next([]);
      return;
    }
    const pageSettings = [
      new PageSetting(
        'adresse/:guid/offene-posten',
        employeeRight.addressRights.showOpenPositions,
        FeatureNames.base,
        false,
      ),
      new PageSetting('adresse/:guid/umsatz', employeeRight.addressRights.showTurnover, FeatureNames.base, false),
      new PageSetting('adresse/:guid/termine', employeeRight.appointmentRights.showAll, FeatureNames.base, false),
      new PageSetting('termin/:id', employeeRight.addressRights.showCustomers, FeatureNames.base, false),
      new PageSetting(
        'termin/neu/:date/:kundennummer',
        employeeRight.appointmentRights.allowCreation,
        FeatureNames.base,
        false,
      ),
      new PageSetting('termin/neu/:date', employeeRight.appointmentRights.allowCreation, FeatureNames.base, false),
      new PageSetting('belege', employeeRight.receiptRights.enableModule, FeatureNames.base, false),
      new PageSetting('beleg/:name', employeeRight.receiptRights.enableModule, FeatureNames.base, false),

      // Aufträge
      new PageSetting('reparaturauftraege', employeeRight.repairOrderRights.enableModule, FeatureNames.base, true),
      new PageSetting(
        'reparaturauftrag/:guid/edit',
        employeeRight.repairOrderRights.enableModule,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'reparaturauftrag/neu',
        employeeRight.repairOrderRights.createRepairOrder,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'reparaturauftrag/neu/:address_type/:guid',
        employeeRight.repairOrderRights.createRepairOrder,
        FeatureNames.base,
        true,
      ),
      new PageSetting('nachrichten', employeeRight.repairOrderRights.enableModule, FeatureNames.base, true),
      new PageSetting('nachricht/neu', employeeRight.repairOrderRights.enableModule, FeatureNames.base, true),
      new PageSetting('nachricht/neu/:guid', employeeRight.repairOrderRights.enableModule, FeatureNames.base, true),
      new PageSetting(
        'auftragpositionen/reparaturauftrag/:id',
        employeeRight.repairOrderRights.enableModule,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'auftragpositionen/reparaturauftrag/:id/hinzufuegen',
        employeeRight.repairOrderRights.createRepairOrder,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'auftragpositionen/reparaturauftrag/:id/:position_uuid',
        employeeRight.repairOrderRights.enableModule,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'auftragsabschluss/reparaturauftrag/:id',
        employeeRight.repairOrderRights.enableModule,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'auftragpositionen/wartungsauftrag/:id',
        employeeRight.maintenanceRights.handleOrder,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'auftragpositionen/wartungsauftrag/:id/hinzufuegen',
        employeeRight.maintenanceRights.handleOrder,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'auftragpositionen/wartungsauftrag/:id/:position_uuid',
        employeeRight.maintenanceRights.handleOrder,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'auftragsabschluss/wartungsauftrag/:id',
        employeeRight.maintenanceRights.handleOrder,
        FeatureNames.base,
        true,
      ),

      // Wartung und Service
      // Achtung, Wartungsanlagen existierten bereits vor den Serviceuafträgen und sind somit teil des Featurs "Basis", nicht "Wartung und Service"
      new PageSetting('wartungsauftraege', employeeRight.maintenanceRights.handleOrder, FeatureNames.base, true),
      new PageSetting(
        'wartungsauftrag/:uuid/edit',
        employeeRight.maintenanceRights.handleOrder,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'wartungsanlagen',
        employeeRight.maintenanceRights.enableMaintenanceSystems,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'wartungsanlage/:uuid/edit',
        employeeRight.maintenanceRights.enableMaintenanceSystems,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'wartungsanlage/:uuid/standort',
        employeeRight.maintenanceRights.enableMaintenanceSystems,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'wartungsanlage/:uuid/termine',
        employeeRight.maintenanceRights.enableMaintenanceSystems,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'wartungsanlage/:uuid/technische-daten-1',
        employeeRight.maintenanceRights.enableMaintenanceSystems,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'wartungsanlage/:uuid/technische-daten-2',
        employeeRight.maintenanceRights.enableMaintenanceSystems,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'wartungsanlage/:uuid/messungen',
        employeeRight.maintenanceRights.enableMaintenanceSystems,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'wartungsanlage/:uuid/messung/neu',
        employeeRight.maintenanceRights.inputMeasuredValues,
        FeatureNames.base,
        true,
      ),

      // Aufmass
      new PageSetting('aufmasse', employeeRight.measurementRights.enableModule, FeatureNames.base, true),
      new PageSetting('aufmass/neu', employeeRight.measurementRights.enableModule, FeatureNames.base, true),
      new PageSetting('aufmass/neu/type/:type', employeeRight.measurementRights.enableModule, FeatureNames.base, true),
      new PageSetting(
        'aufmass/editierung/:uuid',
        employeeRight.measurementRights.enableModule,
        FeatureNames.base,
        true,
      ),
      new PageSetting('aufmass/:uuid/raumbuch', employeeRight.measurementRights.enableModule, FeatureNames.base, true),
      new PageSetting(
        'aufmass/:uuid/raum/:raumid',
        employeeRight.measurementRights.enableModule,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'aufmass/grundriss/:aufmassid/:raumid',
        employeeRight.measurementRights.enableModule,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'aufmass/messung/:aufmassid/:raumid',
        employeeRight.measurementRights.enableModule,
        FeatureNames.base,
        true,
      ),
      new PageSetting(
        'aufmass/:uuid/raum/:raumid/masskette/:massketteid',
        employeeRight.measurementRights.enableModule,
        FeatureNames.base,
        true,
      ),
    ];

    this.currentPageSettings.next(pageSettings);
  }
}
