import { Component, Input } from '@angular/core';
import { ContactHelper } from '@handwerk-pwa/shared';
import { Anschrift } from '../../../../entities';

@Component({
  selector: 'app-bss-contact-bar',
  templateUrl: './bss-contact-bar.component.html',
  styleUrls: ['./bss-contact-bar.component.scss'],
})
export class BssContactBarComponent {
  @Input() telephoneDescription = false;
  @Input() eMailAddress: string;
  @Input() address: Anschrift;
  @Input() telephoneNumber: string;
  @Input() mobileNumber: string;
  @Input() website: string;
  callPhone = ContactHelper.callPhone;
  mailTo = ContactHelper.mailTo;
  goToHomepage = ContactHelper.goToHomepage;

  openMaps(item: Anschrift): void {
    const ort = item?.Ort;
    const street = item?.Strasse;
    if (ort && street) {
      window.open('https://www.google.com/maps/search/?api=1&query=' + street + '%2C+' + ort, '_blank');
      return;
    }
    const fallbackString = item?.FallbackSearchString;
    if (fallbackString) {
      const mapsPrefix = 'https://www.google.de/maps/place/';
      window.open(mapsPrefix + fallbackString);
    }
  }
}
