import { GlobalHelper } from '@handwerk-pwa/shared';
import { HWAddress } from '../../../entities';

export class SortHelper {
  /**@description Orders the addresses by Type (K, L, F, M) and then by customerNumber */
  static sortAddresses(addresses: HWAddress[]): HWAddress[] {
    return this.orderByType(this.orderByCustomerNumber(addresses));
  }

  /**@description Orders the addresses by Type (K, L, F, M) an */
  private static orderByType(allAddresses: HWAddress[]): HWAddress[] {
    const customers = allAddresses.filter(e => e.ADRTYP === 'K');
    const supplier = allAddresses.filter(e => e.ADRTYP === 'L');
    const freie = allAddresses.filter(e => e.ADRTYP === 'F');
    const employees = allAddresses.filter(e => e.ADRTYP === 'M');

    return customers.concat(supplier, freie, employees);
  }

  private static orderByCustomerNumber(allAddresses: HWAddress[]): HWAddress[] {
    return allAddresses.sort((a, b) => GlobalHelper.compareFunction(a.KU_NR, b.KU_NR));
  }
}
