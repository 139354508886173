import { GlobalHelper } from '@handwerk-pwa/shared';
import { SyncObject } from '../models/SyncObject';
import { IndexedDBTypes } from './dbType';

const kontaktArten = [
  { Bezeichnung: 'Ausgehender Anruf ohne Antwort', imgpath: 'assets/icons/PhoneOC.png' },
  { Bezeichnung: 'Eingehender Anruf ohne Antwort', imgpath: 'assets/icons/PhoneIC.png' },
  { Bezeichnung: 'Ausgehender Anruf mit Antwort', imgpath: 'assets/icons/PhoneOA.png' },
  { Bezeichnung: 'Eingehender Anruf mit Antwort', imgpath: 'assets/icons/PhoneIA.png' },
  { Bezeichnung: 'Termin vereinbart', imgpath: 'assets/icons/calendarEdit.png' },
  { Bezeichnung: 'Notiz', imgpath: 'assets/icons/edit.png' },
  { Bezeichnung: 'keine Angabe', imgpath: 'assets/icons/edit.png' },
];
export class HWContact extends IndexedDBTypes.DbType implements SyncObject {
  @IndexedDBTypes.KlassenName('HWContact') KlassenNAME: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: number;
  @IndexedDBTypes.IndexField('string') KU_NR: string;
  @IndexedDBTypes.DataField('string') BETREFF: string;
  @IndexedDBTypes.DataField('string') HERRFRAU: string;
  @IndexedDBTypes.DataField('string') NAME: string;
  @IndexedDBTypes.DataField('string') ART: string;
  @IndexedDBTypes.DataField('string') ANSPRECH: string;
  @IndexedDBTypes.DataField('string') BEMERKUNG: string;
  @IndexedDBTypes.DataField('string') VON: string;
  @IndexedDBTypes.DataField('string') ArtReadable: string;
  @IndexedDBTypes.DataField('string') ArtImagePath: string;
  @IndexedDBTypes.DataField('Data') addedToIdb: Date;

  //Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: HWContact) {
    super();
    Object.assign(this, data);
    this.addTimeStampIfNotExisting();
    this.setReadableArt();
  }

  static toString(): string {
    return 'HWContact';
  }

  /**@description Wenn noch keiner vorhanden ist, wird der timestamp hinzugefügt */
  addTimeStampIfNotExisting(): void {
    if (!GlobalHelper.isNullOrUndefined(this.addedToIdb)) {
      return;
    }
    const now = new Date();
    this.addedToIdb = now;
  }

  setReadableArt(): void {
    if (GlobalHelper.isNullOrUndefined(this.ART)) {
      return;
    }
    const artNumber = parseInt(this.ART, 10);
    const correctArtEntry = kontaktArten[artNumber];
    this.ArtReadable = correctArtEntry.Bezeichnung;
    this.ArtImagePath = correctArtEntry.imgpath;
  }
}
