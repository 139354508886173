<div class="fieldbox">
  <app-bss-number-popup
    class="marginAuto customInputStyle"
    [ignoreLabelStyles]="true"
    [labelText]="'Zeit in Stunden'"
    [title]="'Zeit in Stunden'"
    [mengenTyp]="'St.'"
    [inputValue]="timeInput.displayDecimal"
    (outputValue)="valueChanged($event, 'Decimal')"
    [DecimalPlaces]="2"
    [DecimalPlacesInputEnabled]="true"
    [ValidateInputDecimals]="true"
    [disabled]="disabled"
  >
  </app-bss-number-popup>

  <app-bss-number-popup
    class="marginAuto customInputStyle"
    [ignoreLabelStyles]="true"
    [labelText]="'Zeit in Minuten'"
    [title]="'Zeit in Minuten'"
    [mengenTyp]="'Min.'"
    [inputValue]="timeInput.displayMinutes"
    (outputValue)="valueChanged($event, 'Minutes')"
    [DecimalPlaces]="0"
    [DecimalPlacesInputEnabled]="false"
    [ValidateInputDecimals]="true"
    [disabled]="disabled"
  >
  </app-bss-number-popup>
</div>
