import { Injectable } from '@angular/core';
import { DialogService, RestService, RightsService, UserInfo } from '@handwerk-pwa/shared';
import { HWEmailData, HWNachricht, HWRepairOrder, SyncObject } from '../../entities';
import { MessageHelper } from '../../helper';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';
import { DataService } from './data.service';
import { RepairOrderService } from './repairOrder.service';

@Injectable({
  providedIn: 'root',
})
export class NachrichtenService implements DataService {
  serviceName = 'NachrichtenService';

  constructor(
    private controllerService: ControllerService,
    private repairOrderService: RepairOrderService,
    private restService: RestService,
    private dialogService: DialogService,
    private baseService: BaseService,
    private rightsService: RightsService,
  ) {}

  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    await this.pushToWebService(userInfo);
    await this.getFromWebService(userInfo, silent);
  }

  /**@description Holt alle Nachrichten aus der IDB */
  async getAll(): Promise<HWNachricht[]> {
    const messages: HWNachricht[] = await this.baseService.getAll(HWNachricht);
    return messages.reverse();
  }

  /**@description Holt alle gesendete E-Mails aus der IDB */
  async getAllEmailsSend(): Promise<HWEmailData[]> {
    const allEmails = await this.controllerService.getData<HWEmailData[]>('HWEmailData');
    return allEmails;
  }

  /**@description Überschreibt Nachricht in IDB, sbpw um gelesen zu setzen */
  async save(message: HWNachricht): Promise<void> {
    await this.destroy('ID', message.ID);
    await this.writeNachrichtenToIDB([message], false);
  }

  /**
   * @description Löscht Nachrichten mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(HWNachricht, selector, value);
  }

  /**@description Sendet eine Nachricht ans Handwerk */
  async sendNachricht(userInfo: UserInfo, message: HWNachricht, silent = false): Promise<void> {
    message.setGeraeteId(userInfo);
    const response = await this.restService.returnData<HWNachricht>('AddNachricht', message, silent);
    const messageResponse = new HWNachricht(response);
    const messageToWrite = messageResponse.ID === '' ? message : messageResponse;
    await this.writeNachrichtenToIDB([messageToWrite], false);
  }

  /**@description Schreibt die Nachrichten in die IDB */
  async writeNachrichtenToIDB(message: HWNachricht[], clear?: boolean): Promise<void> {
    if (clear) {
      await this.controllerService.clearStore('HWNachricht');
    }
    await this.controllerService.setData('HWNachricht', message);
  }

  /**@description Teilt dem Handwerk mit, welche Nachrichten gelesen wurden */
  async sendHandwerkGelesen(userInfo: UserInfo, messages: HWNachricht[]): Promise<void> {
    messages.forEach(message => {
      message.setGeraeteId(userInfo);
    });
    await this.restService.returnData('GeleseneNachrichten', messages);
  }

  async pushToWebService(userInfo: UserInfo): Promise<void> {
    const idbMessages = await this.getAll();
    const sortedIdbMessages = MessageHelper.sortBySender(idbMessages);
    const outgoingMessages = sortedIdbMessages.sendFromPWA;
    const unpushedElements = outgoingMessages.filter(object => object.ID === '');
    if (unpushedElements.length === 0) {
      return;
    }
    const result = [];
    for (const unpushedElement of unpushedElements) {
      result.push(this.sendNachricht(userInfo, unpushedElement, true));
    }
    await Promise.all(result);
  }

  async writeEmailToIDB(email: HWEmailData): Promise<void> {
    if (email) {
      await this.controllerService.setData('HWEmailData', [email]);
    }
  }

  /**@description Sendet eine Nachricht ans Handwerk */
  async getFromWebService(userInfo: UserInfo, silent: boolean): Promise<void> {
    const right = this.rightsService.getCurrentRight();
    if (!right.employeeRights.showObjektadressen) return;
    if (!silent) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...hole Nachrichten...');
    }
    const messageData = await this.restService.returnData<HWNachricht[]>(
      'Nachrichten/mandant/' + userInfo.mandant + '/username/' + userInfo.monteur + '/monteur/' + userInfo.monteur,
      null,
      silent,
    );
    const messages: HWNachricht[] = [];
    if (!messageData) {
      return;
    }

    for (const data of messageData) {
      const message = new HWNachricht(data);
      const orderNumber = message.AuftragsNr;
      if (!orderNumber) {
        const repairOrder = await this.repairOrderService.getOrderByOrderNumber(orderNumber);
        message.Auftrag = repairOrder;
      }
      messages.push(message);
    }

    await this.writeNachrichtenToIDB(messages, true);
  }

  getRequiredObjects(): SyncObject[] {
    return [HWRepairOrder];
  }
}
