import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, GlobalHelper, Right, RightsService, RoutingService, UserInfo } from '@handwerk-pwa/shared';
import { Subscription } from 'rxjs';
import { GlobalSettings, PositionType, editImpossible, noEditServiceOrderReason } from '../../../config';
import {
  HWAnlage,
  HWRepairOrder,
  HWRepairOrderItem,
  Positionrequest,
  ServiceAuftrag,
  Textposition,
} from '../../../entities';
import {
  ActivityTrackerService,
  AddressService,
  ConnectionDialogues,
  ConnectionService,
  GlobalSettingService,
  MonteurService,
  RepairOrderItemService,
  RepairOrderService,
  ServiceOrderService,
} from '../../../services';

@Component({
  selector: 'app-order-item-new',
  templateUrl: './order-item-new.component.html',
  styleUrls: ['./order-item-new.component.scss'],
})
export class OrderItemNewComponent implements OnInit, OnDestroy {
  popupTitle = 'Mengenauswahl';
  // positionType except for "Leistungsposition"
  types: string[] = ['Artikel', 'Lohn', 'Leistung', 'Kostenart', 'Manuelle Position', 'Textposition'];
  selectedObject: HWRepairOrderItem;
  showPopup = false;
  searchEnabled = false;
  autolohnActive: boolean;
  order: HWRepairOrder | ServiceAuftrag;
  maintenanceSystem: HWAnlage;
  itemCountBadgeNumber = 0;
  searchExpressionForResponseList = ['KurzText', 'MatNr', 'Such', 'Bezeichnung'];
  positionRequest: Positionrequest;
  mengeneinheiten: string[] = [];
  responsePositions: HWRepairOrderItem[] | Textposition[];
  rights: Right;
  textVorlagen: Textposition[];
  userInfo: UserInfo;
  requestType: PositionType = 'Artikel';
  orderType: string;
  orderId: string;
  subscription: Subscription;
  hasShownOfflineMessage = false;

  constructor(
    private monteurService: MonteurService,
    private repairOrderItemService: RepairOrderItemService,
    private globalSettingService: GlobalSettingService,
    private repairOrderService: RepairOrderService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private rightsService: RightsService,
    private addressService: AddressService,
    private serviceOrderService: ServiceOrderService,
    private activityTrackerService: ActivityTrackerService,
    private connectionService: ConnectionService,
  ) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.subscription = this.routingService.save.subscribe((nextRoute: string) => {
      void this.saveAndExit(nextRoute);
    });
    this.userInfo = await this.globalSettingService.getUserInfo();
    this.rights = this.rightsService.getCurrentRight();
    const isEditable = await this.loadOrder(this.userInfo);
    if (!isEditable) {
      await this.dialogService.openErrorMessage(editImpossible, noEditServiceOrderReason);
      this.routingService.routeBack();
      return;
    }
    const kundenNummer = this.order.getKundennummer();
    const kunde = await this.addressService.findOneBy('KU_NR', kundenNummer);
    this.textVorlagen = await this.globalSettingService.getEntity(GlobalSettings.TextTemplates);
    this.positionRequest = new Positionrequest(this.userInfo.mandant, this.userInfo.monteur);
    this.positionRequest.addPositionSettingData(this.rights.positionSettings);
    this.positionRequest.kundenPriceGroup = kunde.priceGroup;
    this.positionRequest.changeType('Artikel');
    const employee = await this.monteurService.getMonteurFromIDB();
    this.mengeneinheiten = employee.Mengeneinheiten?.map(mengeneinheit => mengeneinheit.Bez);
    this.setBadge(this.order);
  }

  async loadOrder(userInfo: UserInfo): Promise<boolean> {
    this.orderType = this.routingService.getRouteParam('type');
    this.orderId = this.routingService.getRouteParam('id');

    if (this.orderType === 'reparaturauftrag') {
      this.order = await this.repairOrderService.findOneBy('Guid', this.orderId);
    } else if (this.orderType === 'wartungsauftrag') {
      this.order = await this.serviceOrderService.findOneBy('UUID', this.orderId);
      this.types.push('Km Pauschale');
      this.maintenanceSystem = this.order.AnlageObject;
    }
    return this.order.isEditable(userInfo);
  }

  setBadge(order: ServiceAuftrag | HWRepairOrder): void {
    const positions = order.getDisplayPositions();
    this.itemCountBadgeNumber = positions.length;
  }

  async searchItems(positionRequest: Positionrequest): Promise<void> {
    const type = positionRequest.getType();
    const emptySearch = this.checkForEmptySearch(positionRequest, type);
    if (emptySearch) return;
    void this.dialogService.openLoadingDialog('Laden', 'Lade ' + type + '.');
    this.responsePositions = await this.repairOrderItemService.getItemsByChoice(positionRequest);
    if (GlobalHelper.isNullOrUndefinedOrEmptyArray(this.responsePositions)) {
      this.dialogService.openInformDialog('Leeres Ergebnis', 'Leider ergab Ihre Suche keine Treffer.', 'Ok');
      return;
    }
    this.dialogService.closeLoadingDialog();
  }

  // Input ist string array, weshalb output eins der ausgewählten strings ist
  postenArtChange(selectedType: string): void {
    this.positionRequest.changeType(selectedType as PositionType);
    this.positionRequest.SearchText = '';
    this.responsePositions = [];
    this.searchEnabled = false;
    this.requestType = this.positionRequest.getType();
    if (selectedType === 'Lohn') {
      this.searchEnabled = true;
      void this.searchItems(this.positionRequest);
      return;
    }
    if (selectedType === 'Textposition') {
      this.responsePositions = this.textVorlagen;
      return;
    }
  }

  /**@description Sobald ein konkretes Item aus der Liste der Suchergebnisse angeklickt wird */
  async selectItem(itemData: HWRepairOrderItem): Promise<void> {
    if (this.requestType === 'Textposition') {
      this.selectedObject = itemData;
      const textposition = new Textposition(this.selectedObject);
      await this.setMengeOrZeit(textposition, 1, this.positionRequest);
      return;
    }
    const kundenNummer = this.order.getKundennummer();
    const kunde = await this.addressService.findOneBy('KU_NR', kundenNummer);
    const leistungsPositionen = itemData?.getTransferredLeistungspositionen(kunde);
    if (itemData) itemData.Leistungspositionen = leistungsPositionen;
    const containsUnterleistung = leistungsPositionen?.some(position => position.getLongtype() === 'Unterleistung');
    if (containsUnterleistung) {
      await this.dialogService.openErrorMessage(
        'Mehrstufige Leistung',
        'Die Verarbeitung von mehrstufigen Leistungen ist in der my blue:app hand:werk zur Zeit noch nicht vorgesehen. ',
      );
      return;
    }
    const proceed = await this.warnOnRohstoffartikel(itemData);
    if (!proceed) return;
    this.selectedObject = itemData;
    this.showPopup = true;
  }

  /**@description Item wird der RepairOrder hinzugefügt */
  async addItemToOrder(item: HWRepairOrderItem): Promise<void> {
    await this.activityTrackerService.countUpAutoSyncs();

    if (!this.hasShownOfflineMessage) {
      await this.connectionService.checkOnline(ConnectionDialogues.PushData);
      this.hasShownOfflineMessage = true;
    }
    this.order.addPosition(item);
    this.setBadge(this.order);
    this.dialogService.closeLoadingDialog();
    this.routingService.dataChanged.next(true);
  }

  async goToRepairOrderItems(): Promise<void> {
    await this.routingService.navigateTo('auftragpositionen/' + this.orderType + '/' + this.orderId);
  }

  /**@description Setzt die Menge, bzw. Zeit bei einer Lohnposition */
  async setMengeOrZeit(
    position: HWRepairOrderItem,
    popupOutput: number | boolean,
    positionRequest: Positionrequest,
  ): Promise<void> {
    this.showPopup = false;
    const type = positionRequest.getType();
    const autolohnClicked = GlobalHelper.isBoolean(popupOutput);
    position.isAutolohnActive = autolohnClicked;
    const popupOutputValue = autolohnClicked ? 1 : (popupOutput as number);
    position.Menge = popupOutputValue;
    if (type === 'Lohn') {
      position.Zeit = popupOutputValue * 60;
      position.Menge = 1;
      position.MengenEinheit = 'Std.';
    }
    await this.addItemToOrder(position);
  }

  getPopupTitle(selectedObject: HWRepairOrderItem): string {
    return selectedObject?.Suchbegriff || '';
  }

  getPopupContent(selectedObject: HWRepairOrderItem): string {
    return selectedObject?.KurzText || '';
  }

  async saveAndExit(nextRoute: string): Promise<void> {
    if (this.order instanceof HWRepairOrder)
      await this.repairOrderService.sendRepairOrderToWebService(this.order, false);
    if (this.order instanceof ServiceAuftrag)
      await this.serviceOrderService.sendServiceOrderToWebService(this.order, false);
    await this.routingService.navigateTo(nextRoute);
    this.dialogService.closeLoadingDialog();
  }

  private checkForEmptySearch(positionRequest: Positionrequest, type: string): boolean {
    const searchText = positionRequest?.SearchText?.trim();
    if (searchText === '' && type !== 'Lohn') {
      this.dialogService.openInformDialog('Fehler', 'Sie müssen einen Suchbegriff angeben.', 'Ok');
      return true;
    }
    return false;
  }

  /**@description Warnt vor der (inkorrekten) Nutzung eines Rohstoffartikels - gibt dem Nutzer die Möglichkeit den Artikel zu verwerfen */
  private async warnOnRohstoffartikel(itemData: HWRepairOrderItem): Promise<boolean> {
    const rohstoffEnthalten = itemData.RSDaten?.length > 0;
    let proceed = true;
    if (rohstoffEnthalten) {
      proceed = await this.dialogService.openConfirmDialog(
        'Achtung',
        'Für die Verwendung von Rohstoffartikeln ist die my blue:app hand:werk zur Zeit nicht vorgesehen. Wenn Sie den Artikel einfügen,' +
          ' gehen die Rohstoffwerte dieses Artikels verloren. Wollen Sie den Artikel verwenden?',
        'Ja',
        'Nein',
        false,
      );
    }
    return proceed;
  }
}
