import { EmployeeRights, GlobalHelper, TimeHelper } from '@handwerk-pwa/shared';
import moment from 'moment';
import { AppointmentType, deDateFormat } from '../../config';
import { HWAddress, HWTermin } from '../../entities';

export class AppointmentHelper {
  /**@description Takes an appointment array and replaces (where possible) the appointment with a more specific order specific version */
  static appointmentsToMoreSpecificDatasets(
    currentFilterAppointmentsRaw: HWTermin[],
    right: EmployeeRights,
  ): HWTermin[] {
    const repairOrderRights = right.repairOrderRights.enableModule;
    const serviceOrderRights = right.maintenanceRights.handleOrder;
    for (const appointment of currentFilterAppointmentsRaw) {
      if (!repairOrderRights && appointment.bss_type === AppointmentType.RepairOrder) {
        currentFilterAppointmentsRaw = currentFilterAppointmentsRaw.filter(e => e !== appointment);
        continue;
      }
      if (!serviceOrderRights && appointment.bss_type === AppointmentType.MaintenanceAppointment) {
        currentFilterAppointmentsRaw = currentFilterAppointmentsRaw.filter(e => e !== appointment);
        continue;
      }
    }
    return currentFilterAppointmentsRaw;
  }

  static getAllAppointmentsForCustomerToday(customerAppointments: HWTermin[]): HWTermin[] {
    const today = new Date();
    const todayIndex = TimeHelper.dateToDatabaseDate(today);
    const customerAppointmentsToday = customerAppointments?.filter(
      searchTermin => searchTermin.indexDate === todayIndex,
    );
    return customerAppointmentsToday;
  }

  static getEmployeeNamesFromAppointments(appointments: HWTermin[]): string[] {
    const employeeNames: string[] = [];
    for (const appointment of appointments) {
      const employeeName = appointment.mitarbeiterName;
      if (employeeNames.includes(employeeName) || GlobalHelper.isNullOrUndefinedOrEmptyString(employeeName)) continue;
      employeeNames.push(employeeName);
    }

    return employeeNames;
  }

  /**@description Sorts the appointments by date
   * @param descending Reversed order
   */
  static sortAppointmentsByDate(appointments: HWTermin[], descending?: boolean): HWTermin[] {
    return appointments.sort((a, b) => GlobalHelper.compareFunction(a.start, b.start, descending));
  }

  static putEmployeeNameAndCustomerName(
    appointment: HWTermin,
    customerAddresses: HWAddress[],
    employeeAddresses: HWAddress[],
  ): void {
    const customerNumber = appointment.adr;
    if (!GlobalHelper.isNullOrUndefined(customerNumber) && customerNumber !== '') {
      const customer = customerAddresses.find(filterCustomer => filterCustomer.KU_NR === customerNumber);
      if (!GlobalHelper.isNullOrUndefined(customer)) {
        appointment.kundenName = customer.NAME;
      }
    }
    const employeeNumber = appointment.mitarbeiter;
    if (!GlobalHelper.isNullOrUndefined(employeeNumber) && employeeNumber !== '') {
      const employee = employeeAddresses.find(filterEmployee => filterEmployee.KU_NR === employeeNumber);
      if (!GlobalHelper.isNullOrUndefined(employee)) {
        appointment.mitarbeiterName = employee.NAME;
      }
    }
  }

  /**@description  See if an appointment extends over more than one day */
  static isTerminOverDays(currentTermin: HWTermin): boolean {
    const startDay = moment(currentTermin.startDate).format(deDateFormat);
    const endDay = moment(currentTermin.endDate).format(deDateFormat);
    const isOverDays = startDay !== endDay || currentTermin.isTerminOverDays;
    return isOverDays;
  }

  /**@description Reads the list of IDs from the series appointments and returns them in the correct format (id1,id2,id3...) as a string */
  static extractIdsStringFromAppointments(recurringAppointmentStart: HWTermin[]): string {
    let ids = '';
    for (const recurringAppointment of recurringAppointmentStart) {
      const id = recurringAppointment.id;
      ids = ids + ',' + id;
    }
    const idsLength = ids.length;
    ids = ids.substring(1, idsLength);
    return ids;
  }

  static getOverDaysBaseTermin(appointments: HWTermin[], id: string): HWTermin {
    const overDaysBaseTermin = appointments.find(
      appointment => appointment.id === id && this.isTerminOverDays(appointment),
    );
    return overDaysBaseTermin;
  }
}
