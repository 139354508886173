import { GlobalHelper } from '../../../../helper';
import { AddressRights } from './AddressRights';
import { AppointmentRights } from './AppointmentRights';
import { MaintenanceRights } from './MaintenanceRights';
import { MeasurementRights } from './MeasurementRights';
import { RepairOrderRights } from './RepairOrderRights';
import { ReceiptRights } from './receiptRights';

export class EmployeeRights {
  addressRights: AddressRights;
  appointmentRights: AppointmentRights;
  maintenanceRights: MaintenanceRights;
  repairOrderRights: RepairOrderRights;
  measurementRights: MeasurementRights;
  receiptRights: ReceiptRights;
  /** Displays the license status - True = license, False = no license */
  showObjektadressen = true;

  constructor(employeeRights: EmployeeRights) {
    GlobalHelper.assignIfPropertyExists(this, employeeRights);
    this.addressRights = new AddressRights(employeeRights?.addressRights);
    this.appointmentRights = new AppointmentRights(employeeRights?.appointmentRights);
    this.maintenanceRights = new MaintenanceRights(employeeRights?.maintenanceRights);
    this.repairOrderRights = new RepairOrderRights(employeeRights?.repairOrderRights);
    this.measurementRights = new MeasurementRights(employeeRights?.measurementRights);
    this.receiptRights = new ReceiptRights(employeeRights?.receiptRights);
  }

  restrictEverything(): void {
    this.addressRights.restrictEverything();
    this.appointmentRights.restrictEverything();
    this.maintenanceRights.restrictEverything();
    this.repairOrderRights.restrictEverything();
    this.measurementRights.restrictEverything();
    this.receiptRights.restrictEverything();
    this.showObjektadressen = false;
  }

  allowEverything(): void {
    this.addressRights.allowEverything();
    this.appointmentRights.allowEverything();
    this.maintenanceRights.allowEverything();
    this.repairOrderRights.allowEverything();
    this.measurementRights.allowEverything();
    this.receiptRights.allowEverything();
    this.showObjektadressen = true;
  }
}
