import { Component, OnInit } from '@angular/core';
import { EmployeeRights, RightsService, RoutingService, TimeHelper } from '@handwerk-pwa/shared';
import { MeasurementTypes, SliderNames } from '../../config';
import { HWAddress, HWObjectAddress, MultiViewSite } from '../../entities';
import { AppointmentHelper } from '../../helper';
import {
  AddressService,
  AppointmentService,
  GlobalSettingService,
  MaintenanceSystemService,
  MeasurementService,
  ObjectaddressService,
  RepairOrderService,
  ServiceOrderService,
  SliderService,
  SyncService,
} from '../../services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  rights: EmployeeRights;
  isPremium: boolean;
  multiViewSites: MultiViewSite[] = [];
  finishedLoading: boolean;

  constructor(
    private globalSettingService: GlobalSettingService,
    private rightsService: RightsService,
    private addressService: AddressService,
    private objectAddressService: ObjectaddressService,
    private repairOrderService: RepairOrderService,
    private appointmentService: AppointmentService,
    private maintenanceAndService: MaintenanceSystemService,
    private serviceOrderService: ServiceOrderService,
    private routingService: RoutingService,
    private measurementService: MeasurementService,
    private syncService: SyncService,
    private sliderService: SliderService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.rights = this.rightsService.getCurrentRight().employeeRights;
    this.isPremium = this.rightsService.isPremium();
    this.finishedLoading = true;
    await this.setMultiViewDataInOrder();
    await this.syncService.getNewThingsToDiscover();
  }
  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  /**@description Gets all the data needed for the MultiView and their sets order */
  private async setMultiViewDataInOrder(): Promise<void> {
    const allSliders = await this.sliderService.getDataFromIndexDB();
    const multiViewItems = allSliders.filter(view => !view.userDisabled && this.sliderService.filterByRight(view));

    const userInfo = await this.globalSettingService.getUserInfo();

    /** Things needed in multiple places */
    const allAddresses = await this.getAllAddresses();
    const allAppointments = await this.appointmentService.getAllAppointmentsFromIDB(userInfo);
    const allMeasurements = await this.measurementService.getAll();

    const allMaintenanceSystems = await this.maintenanceAndService.getAnlagenFromIDB();
    for (const view of multiViewItems) {
      switch (view.cardName) {
        case SliderNames.addresses:
          view.dataArray = allAddresses;
          break;
        case SliderNames.repairOrders:
          const allRepairOrders = this.repairOrderService.sortRepairOrders(
            await this.repairOrderService.getAllRepairOrdersFromIDB(),
          );
          view.dataArray = allRepairOrders;
          break;
        case SliderNames.appointments:
          const today = TimeHelper.dateToDatabaseDate(new Date());
          view.title = `Termine ${today}`;
          const appointmentsForDay = AppointmentHelper.getAllAppointmentsForCustomerToday(allAppointments);
          const appointmentsWithDetails = AppointmentHelper.appointmentsToMoreSpecificDatasets(
            appointmentsForDay,
            this.rights,
          );
          view.dataArray = appointmentsWithDetails;
          break;
        case SliderNames.maintenanceSystems:
          view.dataArray = allMaintenanceSystems;
          break;
        case SliderNames.serviceOrders:
          const employees = allAddresses.filter(address => address.ADRTYP === 'M') as HWAddress[];
          const allServiceOrders = await this.serviceOrderService.getAllServiceOrdersWithAllDetailsFromIDB(
            employees,
            allMaintenanceSystems,
            allAppointments,
          );
          view.dataArray = allServiceOrders;
          break;
        case SliderNames.roomMeasurements:
          view.dataArray = allMeasurements.filter(e => e.Type === MeasurementTypes.roomMeasurement);
          break;
        case SliderNames.freeMeasurement:
          view.dataArray = allMeasurements.filter(e => e.Type === MeasurementTypes.freeMeasurement);
          break;
      }
    }
    this.multiViewSites = multiViewItems;
  }

  private async getAllAddresses(): Promise<(HWAddress | HWObjectAddress)[]> {
    let allAddresses: (HWAddress | HWObjectAddress)[] = [];
    const addresses = await this.addressService.getAllDisplayable();
    allAddresses = addresses.filter(address => address.LeaveDate == null || address.LeaveDate >= new Date());
    const allObjectAddresses = await this.objectAddressService.getAll();
    allAddresses = allAddresses.concat(allObjectAddresses);
    return allAddresses;
  }
}
