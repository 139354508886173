import { GlobalHelper } from '../../../helper';
import { BlockSetting, PDFSettings } from './PDFSettings';

export class PDFSettingsServiceAuftrag extends PDFSettings {
  AddressAndContact: BlockSetting;
  Monteure: BlockSetting;
  DateBeginning: BlockSetting;
  TimeBeginning: BlockSetting;
  DateEnd: BlockSetting;
  TimeEnd: BlockSetting;
  SumBlock: BlockSetting;
  constructor(data?: PDFSettingsServiceAuftrag) {
    super(data, false);
    this.createServiceOrderSpecificBlockSettings();
    GlobalHelper.assignIfPropertyExists(this, data);
    this.overwriteSettingsNames([
      'Firmensettings',
      'Kommunikationssettings',
      'Anschriftsettings',
      'Auftragssettings',
      'Auftragspositionssettings',
      'DatumAuftragsabschluss',
      'UnterschriftSettings',
      'AddressAndContact',
      'Monteure',
      'DateBeginning',
      'TimeBeginning',
      'DateEnd',
      'TimeEnd',
      'SumBlock',
    ]);
  }

  /**@description Abweichende oder zusätzliche SettingNames in das Objekt schreiben */
  overwriteSettingsNames(newSettingNames: string[]): void {
    this.settingsNames = newSettingNames;
  }
  createServiceOrderSpecificBlockSettings(): void {
    this.AddressAndContact = new BlockSetting('Adresse & Kontakt aus Anlagenstamm', 70, 20);
    this.Monteure = new BlockSetting('Monteure', 10, 70);
    this.DateBeginning = new BlockSetting('Datum für Wartungstermin (Beginn)', 10, 75);
    this.TimeBeginning = new BlockSetting('Uhrzeit für Wartungstermin (Beginn)', 40, 75);
    this.DateEnd = new BlockSetting('Datum für Wartungstermin (Ende)', 70, 75);
    this.TimeEnd = new BlockSetting('Uhrzeit für Wartungstermin (Ende)', 100, 75);
    this.SumBlock = new BlockSetting('Summenblock drucken', -1, -1);
  }
}
