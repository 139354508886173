import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TextBoxType } from 'devextreme/ui/text_box';
import { Observable, of } from 'rxjs';
import { QuickButtonIcons } from '../../../../../../../apps/handwerkPWA/src/app/config';
import { RoutingService } from '../../../services';

@Component({
  selector: 'app-bss-textbox',
  templateUrl: './bss-textbox.component.html',
  styleUrls: ['./bss-textbox.component.scss'],
})
export class BssTextboxComponent {
  @Input() labelText;
  @Input() buttonIcon: QuickButtonIcons;
  @Input() value: string;
  @Input() maxLength = null;
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() placeholder = '';
  @Input() mode: TextBoxType = 'text';
  @Input() buttonEnabled = true;
  @Input() rtlEnabled = false;
  @Input() width;
  @Input() isEmail: boolean;
  @Output() valueChange = new EventEmitter<string>();
  @Output() buttonClick = new EventEmitter<void>();
  @Output() focusOut = new EventEmitter<string>();
  @Output() isValid: Observable<boolean> = new EventEmitter<boolean>();

  constructor(private routingService: RoutingService) {}

  valueChanged(value: string): void {
    this.routingService.dataChanged.next(true);
    this.valueChange.emit(value);
  }

  buttonClicked(): void {
    this.buttonClick.emit();
  }

  focusWentOut(value: string): void {
    this.focusOut.emit(value);
  }
  onValidated(event: { isValid: boolean }): void {
    this.isValid = of(event.isValid);
  }
}
