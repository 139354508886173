import moment from 'moment';
import { deDateFormat } from '../../../../../apps/handwerkPWA/src/app/config';
import {
  Base64Pdf,
  base64UnterschriftMock,
  Dokumentposition,
  HWAddress,
  HWAnlage,
  HWMonteur,
  HWRepairOrder,
  HWRepairOrderItem,
  HWTermin,
  ServiceAuftrag,
} from '../../../../../apps/handwerkPWA/src/app/entities';
import { Right } from '../entities';

export class ExampleHelper {
  static createExampleRepairOrder(): HWRepairOrder {
    const repairOrder = new HWRepairOrder(null, null);
    repairOrder.Nummer = 'nnnnn/jj';
    repairOrder.Bericht = 'Hier steht der Arbeitsbericht';
    repairOrder.AusfAnspr = 'Hier steht der Ansprechpartner';
    repairOrder.ArbeitsOrt = 'Hier steht der Arbeitsort';
    repairOrder.Monteur1 = 'Hier steht der Mitarbeiter';
    repairOrder.Arbeitszeiten = [new Date(), new Date()];
    repairOrder.ArbeitDat = moment(new Date()).format(deDateFormat);

    const positions = this.createExamplePositions();
    repairOrder.setPositions(positions);
    return repairOrder;
  }

  /**@description Erstellt Mustermonteur, Kunden,pdf und ein AdminRight für die Auswertung */
  static createBaseMusterPDF(): {
    pdf: Base64Pdf;
    employee: HWMonteur;
    right: Right;
    customer: HWAddress;
    allEmployees: HWAddress[];
  } {
    const customer = this.createExampleCustomer();
    const employee = this.createExampleEmployee();
    const pdf = this.createExamplePDF();
    const right = this.createExampleSuperRight();
    const allEmployees = this.createAllExampleEmployees();
    return { pdf, employee, right, customer, allEmployees };
  }

  static createExampleDocumentPositions(): Dokumentposition[] {
    const documentPositions: Dokumentposition[] = [];
    const itemPositionen = this.createExamplePositions();
    let index = 1;
    for (const itemPosition of itemPositionen) {
      const documentPosition = new Dokumentposition(itemPosition);
      documentPosition.PosNr = index.toString();
      documentPosition.MatVK_G = parseInt(documentPosition.PosNr, 10) * 100;
      documentPositions.push(documentPosition);
      index++;
    }
    return documentPositions;
  }

  static createExampleDate(): HWTermin {
    const fakeDate = new Date(2021, 23, 3, 11, 37, 56);
    const exampleDate = new HWTermin(null, fakeDate);
    exampleDate.finish = '23.03.2021 12:37:11';
    return exampleDate;
  }

  static createExampleServiceOrder(allMonteure: HWAddress[]): ServiceAuftrag {
    const anlage = this.createExampleAnlage();
    const date = this.createExampleDate();
    const serviceOrder = new ServiceAuftrag(null, allMonteure, [anlage], [date]);
    serviceOrder.Anlage = anlage.ANLAGE;
    serviceOrder.Projekt = 'WV10003A';
    serviceOrder.Auftragsnummer = '00001A/21';
    return serviceOrder;
  }

  private static createExampleAnlage(): HWAnlage {
    const anlage = new HWAnlage(null, [], []);
    anlage.ANLAGE = '00000001';
    const standort = anlage.Anlagenstandort;
    standort.NAME = 'Name Anlagenstandort';
    standort.STRASSE = 'Straße Anlagenstandort';
    standort.PLZ = 'Postleitzahl';
    standort.ORT = 'Ort Anlagenstandort';
    standort.STANDORT1 = 'Standort1 Anlagenstandort';
    standort.STANDORT2 = 'Standort1 Anlagenstandort';
    standort.TEL = 'Telefonnummer Anlagenstandort';
    standort.ANFAHRTKM = 'Anfahrtskilometer';
    return anlage;
  }

  private static createExampleEmployee(): HWMonteur {
    const employee = new HWMonteur(null);
    employee.Name = 'Hier steht der Mitarbeiter';
    employee.Email = 'mail@adresse.de';
    employee.Nummer = '123321';
    employee.Telefon = '01234/56789';
    employee.Fax = '01234/56789';
    employee.Web = 'www.ihre_webseite.de/';
    employee.FirmenAnschrift = 'Hier steht die Firmenanschrift';
    return employee;
  }

  private static createExampleCustomer(): HWAddress {
    const maxMustermann = new HWAddress(null);
    maxMustermann.ADRTYP = 'K';
    maxMustermann.HERRFRAU = 'Kundenanschrift Zeile 1';
    maxMustermann.NAME = 'Kundenanschrift Zeile 2';
    maxMustermann.NAME2 = 'Kundenanschrift Zeile 3';
    maxMustermann.STRASSE = 'Kundenanschrift Zeile 4';
    maxMustermann.PLZ = 'Kundenanschrift Zeile 5';
    maxMustermann.ORT = '';
    maxMustermann.priceGroup = '01';
    return maxMustermann;
  }

  private static createExamplePositions(): HWRepairOrderItem[] {
    const positions: HWRepairOrderItem[] = [];
    for (let index = 1; index < 4; index++) {
      const position = new HWRepairOrderItem(null);
      position.PosNr = index;
      position.Menge = 1;
      position.MengenEinheit = 'ST';
      position.Bezeichnung = 'Testposition ' + index;
      position.Ident = 'M';
      position.VK = index * 100;
      position.UstS = 1;
      position.UstS = 19;
      position.EUMATVK1 = index * 100;
      position.LohnVK = 0;
      position.Zeit = 60;
      positions.push(position);
    }
    return positions;
  }
  private static createAllExampleEmployees(): HWAddress[] {
    const employee1 = new HWAddress(null);
    employee1.NAME = 'Max Mustermann';
    employee1.KU_NR = '-2';
    const employee2 = new HWAddress(null);
    employee2.NAME = 'Michael Mustermeier';
    employee2.KU_NR = '-3';
    const employees: HWAddress[] = [];
    employees.push(employee1);
    employees.push(employee2);
    return employees;
  }

  private static createExampleSuperRight(): Right {
    const right = new Right(null);
    right.createSuperRight();
    return right;
  }

  private static createExamplePDF(): Base64Pdf {
    const pdf = new Base64Pdf();
    pdf.data = base64UnterschriftMock;
    pdf.landScape = true;
    return pdf;
  }
}
