import { Component, OnInit } from '@angular/core';
import { ContactHelper, GlobalHelper, RightsService, RoutingService } from '@handwerk-pwa/shared';
import { HWAnlage } from '../../../../entities';
import { MaintenanceSystemService, MediaService } from '../../../../services';

@Component({
  selector: 'app-system-edit',
  templateUrl: './system-edit.component.html',
  styleUrls: ['./system-edit.component.scss'],
})
export class SystemEditComponent implements OnInit {
  anlage: HWAnlage;
  showBeschreibung = false;
  scrollOffsetTop: number;
  currentFilesAmount: string;
  callPhone = ContactHelper.callPhone;
  mailTo = ContactHelper.mailTo;
  inputSystemMeasurementRight: boolean;

  constructor(
    private maintenanceSystemService: MaintenanceSystemService,
    private routingService: RoutingService,
    private mediaService: MediaService,
    private rightsService: RightsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.inputSystemMeasurementRight =
      this.rightsService.getCurrentRight().employeeRights.maintenanceRights.inputMeasuredValues;
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.maintenanceSystemService.findOneAnlageBy('UUID', anlageUUID);
    await this.getFileAmount();
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  private async getFileAmount(): Promise<void> {
    const files = await this.mediaService.getAllBy('Baseuuid', this.anlage.UUID);
    this.currentFilesAmount = GlobalHelper.calculateDataAmount(files);
  }
}
