import { GlobalHelper } from '../../../../helper';
import { Module } from './RightCategory';

export class RepairOrderRights extends Module {
  allowCashPayment = true;
  createRepairOrder = true;

  constructor(repairOrderRights: RepairOrderRights) {
    super();
    GlobalHelper.assignIfPropertyExists(this, repairOrderRights);
  }
}
