import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@handwerk-pwa/shared';
import { Anlagentermin, HWAnlage } from '../../../../entities';
import { MaintenanceSystemService } from '../../../../services';

@Component({
  selector: 'app-system-appointment',
  templateUrl: './system-appointment.component.html',
  styleUrls: ['./system-appointment.component.scss'],
})
export class SystemAppointmentComponent implements OnInit {
  anlage: HWAnlage;
  anlagenTermin: Anlagentermin;
  wayOfNotification: string;

  constructor(
    private routingService: RoutingService,
    private maintenanceSystemService: MaintenanceSystemService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAnlage();
    this.anlagenTermin = this.anlage.Anlagentermin;
    this.wayOfNotification = this.maintenanceSystemService.getWayOfNotification(this.anlagenTermin);
  }

  async loadAnlage(): Promise<void> {
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.maintenanceSystemService.findOneAnlageBy('UUID', anlageUUID);
  }
}
