import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService, FormulaHelper, GlobalHelper, RoutingService } from '@handwerk-pwa/shared';
import { GlobalSettings } from '../../../../config';
import { Aufmass, MeasurementFormula, MeasurementFormulaRaw, ValueNamePair } from '../../../../entities';
import { GlobalSettingService, RoomBookService } from '../../../../services';

@Component({
  selector: 'app-aufmass-formel',
  templateUrl: './aufmass-formel.component.html',
  styleUrls: ['./aufmass-formel.component.scss'],
})
export class AufmassFormelComponent implements OnInit {
  @Output() generatedFormula = new EventEmitter<string>();
  @Input() aufmass: Aufmass;
  freeInput = false;
  formulas: MeasurementFormula[];
  selectedFormula: MeasurementFormula;
  formulaString: string;
  allMeasured = false;
  originalInput: string;

  constructor(
    private dialogService: DialogService,
    private routingService: RoutingService,
    private roomBookService: RoomBookService,
    private globalSettingService: GlobalSettingService,
  ) {}

  getDecimalPlaces(pair: ValueNamePair): number {
    return pair.value ? pair.value?.toString().split('.')[1]?.length : 2;
  }

  async ngOnInit(): Promise<void> {
    const massUuid = this.routingService.getRouteParam('massketteId');
    const dimensionChain = this.roomBookService.findOneRoomBookBy(this.aufmass, 'Uuid', massUuid);
    if (dimensionChain) {
      this.formulaString = dimensionChain.Aufmass;
      this.originalInput = dimensionChain.Aufmass;
    }

    const formulaData = await this.globalSettingService.getEntity<MeasurementFormulaRaw[]>(GlobalSettings.Formeln);
    this.formulas = formulaData.map(data => new MeasurementFormula(data));
  }

  measurementChanged(value: number, displayName: string): void {
    const changedPair = this.selectedFormula.ValueNamePairs.find(pair => pair.displayName === displayName);
    changedPair.value = value;
    const allMeasured = this.selectedFormula?.checkAllPairsMeasured();
    if (allMeasured) {
      this.formulaString = this.selectedFormula.generateValueFilledFormula();
      this.formulaString = this.formulaString.replaceAll('[', '').replaceAll(']', '');
      this.validateFormula(this.formulaString);
      this.allMeasured = true;
    }
  }

  validateFormula(formula: string): boolean {
    const valid = FormulaHelper.validateFormula(formula);
    if (!valid) {
      const errorText = GlobalHelper.isNullOrUndefinedOrEmptyString(formula)
        ? 'Die Formel darf nicht leer sein. '
        : `Ihre Eingabe ${formula} ist ungültig und kann nicht berechnet werden. Bitte geben Sie nur Zahlen, Rechenzeichen und Klammern ein.`;
      void this.dialogService.openErrorMessage('Eingabefehler', errorText);
    }
    if (valid) this.generatedFormula.emit(formula);
    return valid;
  }

  getImage(base64ImageData: string): string {
    return 'data:image/png;base64,' + base64ImageData;
  }

  /**@description Sets the selected formula to zero and restores the original formula (if available) */
  clearFormulaSelection(): void {
    this.selectedFormula = null;
    this.formulaString = this.originalInput;
  }
}
