import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalSettings } from '../../../../../apps/handwerkPWA/src/app/config';
import { GlobalSettingService } from '../../../../../apps/handwerkPWA/src/app/services';
import { environment } from '../../../../../apps/handwerkPWA/src/environments/environment';
import { Right, Setting, UserInfo } from '../entities';
import { GlobalHelper } from '../helper';
import { DialogService } from './dialog.service';
import { EncryptionService } from './encryption.service';
import { PositionService } from './position.service';
import { RestService } from './rest.service';
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root',
})
export class RightsService {
  private currentRight = new BehaviorSubject<Right>(null);

  constructor(
    private globalSettingService: GlobalSettingService,
    private dialogService: DialogService,
    private restService: RestService,
    private positionService: PositionService,
    private roleService: RoleService,
    private encryptionService: EncryptionService,
  ) {}

  async sendSettingToWebService(setting: Setting, userInfo: UserInfo): Promise<void> {
    const pdfSettingString = GlobalHelper.isEmptyGUIData(setting.PDFSettings)
      ? null
      : JSON.stringify(setting.PDFSettings);
    const briefPapierString = GlobalHelper.isEmptyGUIData(setting.briefPapier)
      ? null
      : JSON.stringify(setting.briefPapier, Object.keys(setting.briefPapier).sort());
    const emailTextTemplateString = GlobalHelper.isEmptyGUIData(setting.emailTextvorlage)
      ? null
      : JSON.stringify(setting.emailTextvorlage);
    const textTemplateString = GlobalHelper.isEmptyGUIData(setting.textVorlagen)
      ? null
      : JSON.stringify(setting.textVorlagen);
    if (setting?.emailConnectionInfo)
      setting.emailConnectionInfo.smtpPassword = window.btoa(setting?.emailConnectionInfo.smtpPassword);
    const emailConnectionInfoString = setting?.emailConnectionInfo
      ? JSON.stringify(setting?.emailConnectionInfo)
      : null;

    const pdfSettingServiceOrderString = GlobalHelper.isEmptyGUIData(setting.PDFSettingsServiceAuftrag)
      ? null
      : JSON.stringify(setting.PDFSettingsServiceAuftrag);
    const sendSettings = {
      PDFSettings: pdfSettingString,
      briefPapier: briefPapierString,
      emailTextvorlage: emailTextTemplateString,
      textVorlagen: textTemplateString,
      mandant: userInfo.mandant,
      emailConnectionInfo: emailConnectionInfoString,
      PDFSettingsServiceAuftrag: pdfSettingServiceOrderString,
    };
    await this.restService.returnData('WritePWASettings', sendSettings);
  }

  /**
   * @description gets the Settings from the WebService without replacing the emailData or Sliders
   * @returns unmodified webService Settings
   */
  async getSettingFromWebService(userInfo: UserInfo, silent: boolean): Promise<Setting> {
    const settingData = await this.restService.returnData<Setting>('ReadPWASettings', userInfo.mandant, silent);
    const setting = new Setting(settingData);
    return setting;
  }

  async getSettingsAndRightsFromWebService(
    userInfo: UserInfo,
    silent: boolean,
    actOnNewPositionSettings: boolean,
  ): Promise<Right> {
    if (!silent)
      void this.dialogService.openLoadingDialog(
        'Synchronisation',
        '...aktualisiere Rechte und Einstellungen des angemeldeten Nutzers...',
      );
    const oldRights = this.getCurrentRight();
    const oldPositionSettings = oldRights?.positionSettings;
    const right = await this.getEmployeesRightsFromWebService(userInfo, silent);
    if (GlobalHelper.isNullOrUndefined(oldRights) || !GlobalHelper.objectEqualTo(right, oldRights))
      this.updateCurrentRight(right);
    const newPositionSettings = right?.positionSettings;
    const setting = await this.getSettingFromWebService(userInfo, silent);

    // Encrypt the password before saving it in the IDB
    const oldSettings = await this.globalSettingService.getEntity<Setting>(GlobalSettings.Settings);
    if (oldSettings?.emailConnectionInfo) {
      setting.emailConnectionInfo = oldSettings?.emailConnectionInfo;
    } else if (setting?.emailConnectionInfo?.smtpPassword) {
      setting.emailConnectionInfo.smtpPassword = this.encryptionService.encrypt(
        atob(setting.emailConnectionInfo.smtpPassword),
      );
    }
    await this.writeRightsAndSettingsToIDB(right, setting);
    if (!silent) this.dialogService.closeLoadingDialog();
    if (actOnNewPositionSettings)
      await this.positionService.actOnNewPositionSettings(oldPositionSettings, newPositionSettings, right, userInfo);
    return right;
  }

  updateCurrentRight(right: Right): void {
    this.currentRight.next(right);
  }

  getCurrentRight(): Right {
    return this.currentRight.value;
  }

  getCurrentRightAsync(): BehaviorSubject<Right> {
    return this.currentRight;
  }

  async saveRightLocally(right: Right): Promise<void> {
    await this.globalSettingService.setEntity(right, GlobalSettings.Rights);
  }

  isPremium(): boolean {
    return this.getCurrentRight()?.employeeRights?.showObjektadressen;
  }

  private async writeRightsAndSettingsToIDB(right: Right, setting: Setting): Promise<void> {
    await this.globalSettingService.setEntity(right, GlobalSettings.Rights);
    await this.globalSettingService.setEntity(setting, GlobalSettings.Settings);
  }

  private async getEmployeesRightsFromWebService(userInfo: UserInfo, silent = false): Promise<Right> {
    const role = await this.roleService.getEmployeesRoleFromWebService(userInfo, silent, this.getCurrentRight());
    if (environment.configurationname === 'dev') role.right.employeeRights.showObjektadressen = true; // LIZENZ HARDCODED AN
    return role.right;
  }
}
