import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@handwerk-pwa/shared';
import { vacantPositionsSearch } from '../../../config';
import { HWOffeneposten } from '../../../entities';
import { AddressService, GlobalSettingService, OffenepostenService } from '../../../services';

@Component({
  selector: 'app-address-vacant-positions',
  templateUrl: './address-vacant-positions.component.html',
  styleUrls: ['./address-vacant-positions.component.scss'],
})
export class AddressVacantPositionsComponent implements OnInit {
  searchExpressions = vacantPositionsSearch;
  vacantPositions: HWOffeneposten[] = [];

  constructor(
    private globalSettingService: GlobalSettingService,
    private offenepostenService: OffenepostenService,
    private routingService: RoutingService,
    private addressService: AddressService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAddressVacantPositions();
  }

  private async loadAddressVacantPositions(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const addressGuid = this.routingService.getRouteParam('guid');
    const address = await this.addressService.findOneBy('Guid', addressGuid);
    this.vacantPositions = await this.offenepostenService.getOffenePostenByKundennummerByChoice(
      userInfo,
      address.KU_NR,
    );
  }
}
