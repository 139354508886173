import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalHelper, RoutingService } from '@handwerk-pwa/shared';
import { ValueChangedEvent } from 'devextreme/ui/date_box';
import moment from 'moment';
import { DxDateType } from '../../../config';

@Component({
  selector: 'app-bss-datetime-box',
  templateUrl: './bss-datetime-box.component.html',
  styleUrls: ['./bss-datetime-box.component.scss'],
})
export class BssDatetimeBoxComponent implements OnInit {
  @Input() labelText: string;
  @Input() value: Date | string;
  @Input() type: DxDateType = DxDateType.date;
  @Input() buttonIcon = 'event';
  @Input() min: Date;
  @Input() max: Date;
  @Input() disabled = false;
  @Output() outValue = new EventEmitter<Date>();
  @Output() outStringValue = new EventEmitter<string>();
  displayFormat = 'dd.MM.yyyy';
  ready = false;

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {
    this.setDisplayFormat(this.type);
    this.value = this.setCorrectDate(this.value, this.type);
    this.ready = true;
  }

  dateChanged(event: ValueChangedEvent, type: DxDateType): void {
    const value = event.value;
    const newDate = this.parseValueChange(value, type);
    this.value = newDate;
    let momentString: string;
    switch (type) {
      case DxDateType.date:
        momentString = moment(newDate).format('DD.MM.YYYY');
        break;
      case DxDateType.dateTime:
        momentString = moment(newDate).format('DD.MM.YYYY HH:mm');
        break;
      case DxDateType.time:
        momentString = moment(newDate).format('HH:mm');
        break;
    }
    this.outValue.emit(newDate);
    this.outStringValue.emit(momentString);
    this.routingService.dataChanged.next(true);
  }

  /** Private Functions */

  private parseValueChange(value: Date | string, type: DxDateType): Date {
    if (GlobalHelper.isDate(value)) return new Date(value);
    return this.setCorrectDate(value, type);
  }

  private setDisplayFormat(type: DxDateType): void {
    switch (type) {
      case DxDateType.date:
        this.displayFormat = 'dd.MM.yyyy';
        break;
      case DxDateType.dateTime:
        this.displayFormat = 'dd.MM.yyyy HH:mm';
        break;
      case DxDateType.time:
        this.displayFormat = 'HH:mm';
        break;
    }
  }

  private setCorrectDate(value: string | Date, type: DxDateType): Date {
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(value as string) || value === ' ') return null;
    let returnValue: Date;
    switch (type) {
      case DxDateType.date:
        returnValue = moment(value, 'DD.MM.YYYY').toDate();
        break;
      case DxDateType.dateTime:
        returnValue = moment(value, 'DD.MM.YYYY HH:mm').toDate();
        break;
      case DxDateType.time:
        returnValue = moment(value, ['DD.MM.YYYY HH:mm', 'HH:mm']).toDate();
        break;
    }
    return returnValue;
  }
}
