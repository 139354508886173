import { GlobalHelper } from '../../../../helper';
import { Module } from './RightCategory';

export class AppointmentRights extends Module {
  allowCreation = true;
  allowEdit = true;
  showAll = true;

  constructor(appointmentRights: AppointmentRights) {
    super();
    GlobalHelper.assignIfPropertyExists(this, appointmentRights);
  }
}
