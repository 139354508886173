import { GlobalHelper } from '../../../../helper';
import { Module } from './RightCategory';

export class MaintenanceRights extends Module {
  inputMeasuredValues = true;
  enableMaintenanceSystems = true;
  handleOrder = true;

  constructor(maintenanceRights: MaintenanceRights) {
    super();
    GlobalHelper.assignIfPropertyExists(this, maintenanceRights);
  }
}
