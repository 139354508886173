import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ignoreChangesOn, sitesToAutosaveOnChange } from '../../../../../apps/handwerkPWA/src/app/config';
import { ServiceAuftrag } from '../../../../../apps/handwerkPWA/src/app/entities';
import { BaseAuftrag } from '../../../../../apps/handwerkPWA/src/app/interfaces';
import { GlobalSettingService, SyncObjectService } from '../../../../../apps/handwerkPWA/src/app/services';
import { GlobalHelper } from '../helper';
import { DialogService, ScreenService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class RoutingService implements OnDestroy {
  /**Behaviour Subject, kann mit save.next('ROUTE') abgeschickt werden- Komponenten hören auf diesem Kanal und sobald sie angesprochen werden speichern sie */
  save = new Subject<string>();
  addMedium = new Subject<string>();
  dataChanged = new BehaviorSubject<boolean>(false);
  /**Speichert die letzten Routen, da window.history.back unzuverlässig war (position[1] enthält vorherige route) */
  lastRoutes: string[] = [];
  currentRoute = new Subject<string>();
  menuOpen = new BehaviorSubject<boolean>(false);
  private routerSubscription: Subscription;

  constructor(
    private angularRouter: Router,
    private dialogService: DialogService,
    private screen: ScreenService,
    private globalSettingsService: GlobalSettingService,
    private syncObjectService: SyncObjectService,
  ) {
    // Wenn sich Routeparameter ändern,wird hierdurch die Component neu geladen
    this.angularRouter.routeReuseStrategy.shouldReuseRoute = (): boolean => false;

    // mainMenu: alle hauptansichten, also ansichten, in denen das Menü angezeigt werden soll statt eines Back buttons
    this.routerSubscription = this.angularRouter.events
      .pipe(filter((state): state is NavigationEnd => state instanceof NavigationEnd))
      .subscribe(navigationEnd => {
        this.dataChanged.next(false);
        const url = navigationEnd.url;
        this.addRouteWithoutCircle(url);
        this.currentRoute.next(url);
        this.nextMenuState(false);
      });
  }

  public nextMenuState(open: boolean): void {
    const largeAndOpened = this.screen.sizes['screen-large'] && this.menuOpen.getValue();
    const nextState = open || largeAndOpened;
    this.menuOpen.next(nextState);
  }

  getNextPlannedRoute(): string {
    return this.angularRouter.routerState.snapshot.url;
  }

  getCurrentRoute(): string {
    return this.angularRouter.url;
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  getLastRoute(): string {
    return this.lastRoutes[1] || '';
  }

  getAllCurrentRouteParams(): Params {
    return this.angularRouter.routerState.snapshot.root.children[0].params;
  }

  getRouteParam(parameterName: string): string {
    const routeParams = this.getAllCurrentRouteParams();
    const parameterValue = routeParams[parameterName] as string;
    return parameterValue;
  }

  /**@description Prüft ob geänderte Daten vorliegen, fragt den Nutzer anschließend ob er diese speichern möchte, bevor er weitergeht */
  async navigateTo(nextRoute: string): Promise<void> {
    this.syncObjectService.currentSyncObjects.next([]);
    let saveData = false;
    const currentRoute = this.lastRoutes[0];
    const autosaveOnCurrentRoute = this.checkRouteIncluded(sitesToAutosaveOnChange, currentRoute);
    const ignoreChanges =
      this.checkRouteIncluded(ignoreChangesOn, currentRoute) || this.globalSettingsService.globalIgnoreChanges;
    if (this.dataChanged.getValue() && !autosaveOnCurrentRoute && !ignoreChanges) {
      saveData = await this.dialogService.openConfirmDialog(
        'Nicht gesicherte Daten!',
        'Sie haben Daten geändert, dies aber noch nicht gesichert. Möchten Sie Ihre Änderungen Sichern?',
        'Sichern',
        'Verwerfen',
        false,
      );
    }
    if (this.dataChanged.getValue() && autosaveOnCurrentRoute && !ignoreChanges) saveData = true;
    if (saveData) {
      this.dataChanged.next(false);
      this.save.next(nextRoute);
      return;
    }
    this.routeTo(nextRoute);
  }

  /**@description Lädt die Aktuelle Komponente neu durch aufruf der reloadkomponente */
  reload(): void {
    let currentSite = this.lastRoutes[0];
    if (currentSite.startsWith('/')) currentSite = currentSite.substring(1);
    this.routeTo('reload' + '/' + currentSite);
  }

  /**@description Routet auf die Seite, auf der man war, bevor man den Vorgang des Auftragsabschluss durchgeführt hat - also entweder Startseite oder Aufträge
   * - bereinigt die history, damit danach nicht zurück zum abgewschlossenen auftrag geroutet werden kann.
   */
  async routeBackAfterOrderFinish(order: BaseAuftrag): Promise<void> {
    const lastRoutes = this.lastRoutes;
    const correctAuftraege = order instanceof ServiceAuftrag ? '/wartungsauftraege' : '/reparaturauftraege';
    let startseitenIndex = lastRoutes.indexOf('/startseite');
    if (startseitenIndex === -1) startseitenIndex = Number.MAX_VALUE;
    let auftraegeIndex = lastRoutes.indexOf(correctAuftraege);
    if (auftraegeIndex === -1) auftraegeIndex = Number.MAX_VALUE;
    let correctIndex = lastRoutes.indexOf(correctAuftraege);
    let newRoutesArray = lastRoutes.slice(correctIndex + 1, lastRoutes.length);
    if (startseitenIndex < auftraegeIndex) {
      correctIndex = lastRoutes.indexOf('/startseite');
      newRoutesArray = ['/startseite'];
    }
    const routeTo = lastRoutes[correctIndex];
    this.lastRoutes = newRoutesArray;
    await this.navigateTo(routeTo);
  }

  /**
   * @description Routet zur letzten Route
   */
  routeBack(ignoreChanges = false): void {
    if (ignoreChanges) this.dataChanged.next(false);
    const lastRoute = this.lastRoutes[1];
    if (GlobalHelper.isNullOrUndefined(lastRoute)) return;
    void this.navigateTo(lastRoute);
  }

  /**@description Guckt ob eine Route stimmt, auch mit parametern */
  private checkRouteIncluded(routes: string[], routeToCheck: string): boolean {
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(routeToCheck)) return false;
    // startende '/' im zweifel entfernen
    routes = routes.map(rout => (rout.startsWith('/') ? rout.substring(1) : rout));
    routeToCheck = routeToCheck.startsWith('/') ? routeToCheck.substring(1) : routeToCheck;

    if (routes.includes(routeToCheck)) return true;

    const routeToCheckSplit = routeToCheck.split('/');

    // Guckt wie viele parameter eine route enthält - stimmt die route mit der zu überprüfenden überein an (N - Parameteranzahl) stellen, haben wir ein match
    for (const route of routes) {
      const routeSplit = route.split('/');
      const paramAmount = (route.match(/:/g) || [])?.length; // anzahl vorkommen doppelpunkte = params
      let index = 0;
      let matches = 0;
      for (const subRoute of routeToCheckSplit) {
        if (subRoute === routeSplit[index]) matches++;
        index++;
      }
      if (matches === routeSplit.length - paramAmount) return true;
      index = 0;
      matches = 0;
    }
    return false;
  }

  /**@description Führt den eigentlichen Routingvorgang aus */
  private routeTo(destination: string): void {
    const lastRoute = this.lastRoutes[0];
    if (lastRoute === destination) destination = 'reload' + '/' + destination;
    if (GlobalHelper.isNullOrUndefined(destination)) destination = '/Login';
    void this.angularRouter.navigate([destination]);
  }

  /**@description Fügt die Route dem routenarray hinzu, vermeidet dabei aber zyklen - setzt array auf startseite wenn man auf startseite ist,um riesige arrays zu vermeiden */
  private addRouteWithoutCircle(url: string): void {
    if (url === '/startseite') {
      this.lastRoutes = ['/startseite'];
      return;
    }
    const lastRoutes = this.lastRoutes;
    if (url === lastRoutes[0]) return;
    if (lastRoutes.length < 2) {
      this.lastRoutes.unshift(url);
      return;
    }
    if (lastRoutes[1] === url) {
      this.lastRoutes.shift();
      return;
    }
    this.lastRoutes.unshift(url);
  }
}
