import { Injectable } from '@angular/core';
import { DialogService, GlobalHelper, RestService, UserInfo } from '@handwerk-pwa/shared';
import { GlobalSettings } from '../../config';
import { HWMonteur, SyncObject, Textposition } from '../../entities';
import { ControllerService } from '../globalServices/controller.service';
import { GlobalSettingService } from '../globalServices/globalSetting.service';
import { BaseService } from './base.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class MonteurService implements DataService {
  serviceName = 'MonteurService';
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private dialogService: DialogService,
    private globalSettingService: GlobalSettingService,
    private baseService: BaseService,
  ) {}

  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    await this.pushToWebService(userInfo, silent);
    await this.getFromWebService(userInfo, silent);
  }

  /**@description Holt die jeweiligen Kontakte.
   *  Schreibt sie in die IDB, löscht zuvor die jeweilige Tabelle in die geschrieben wird */
  async getFromWebService(userInfo: UserInfo, silent = false): Promise<void> {
    if (!silent) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...aktualisiere Daten des angemeldeten Nutzers...');
    }
    const monteurData = await this.restService.returnData<HWMonteur>(
      'Monteur/mandant/' + userInfo.mandant + '/adminname/' + userInfo.user + '/monteur/' + userInfo.monteur,
    );
    if (GlobalHelper.isNullOrUndefined(monteurData)) {
      return null;
    }
    const monteur = new HWMonteur(monteurData);
    await this.globalSettingService.setEntity(monteur.ZusatzBezeichnung, GlobalSettings.AdditionalDesignations);
    const textpositionen: Textposition[] = this.getTextpositionen(monteur.TextVorlagen);
    await this.globalSettingService.setEntity(textpositionen, GlobalSettings.TextTemplates);
    await this.writeMonteurToIDB(monteur, true);
  }

  // create / edit not possible
  async pushToWebService(userInfo: UserInfo, silent: boolean): Promise<void> {}

  /**@description Gets the currently logged in employee from the IDB */
  async getMonteurFromIDB(): Promise<HWMonteur> {
    const monteurData = await this.controllerService.getData<HWMonteur[]>('HWMonteur');
    const monteur = new HWMonteur(monteurData[0]);
    return monteur;
  }

  /**@description Gibt true zurück, wenn ein Nutzer eingeloggt ist */
  async userSignedIn(): Promise<boolean> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const signedIn = !GlobalHelper.isNullOrUndefined(userInfo?.pin);

    return signedIn;
  }

  getRequiredObjects(): SyncObject[] {
    return [];
  }

  /*******************************************************************************
   *
   *
   *
   * PRIVATE FUNCTIONS
   *
   *
   *
   ******************************************************************************** */

  /**@description Schreibt die Monteure in die IDB */
  private async writeMonteurToIDB(monteur: HWMonteur, clear: boolean): Promise<void> {
    if (clear) {
      await this.controllerService.clearStore('HWMonteur');
    }
    await this.controllerService.setData('HWMonteur', [monteur]);
  }

  private getTextpositionen(positionData: any): Textposition[] {
    const positionen: Textposition[] = [];
    if (GlobalHelper.isNullOrUndefined(positionData)) {
      return positionen;
    }

    return this.baseService.rawDataArrayToObjectsArray(Textposition, positionData);
  }
}
