import { GlobalHelper } from '@handwerk-pwa/shared';
import { RoomBookPositionLevels } from '../../config';
import { IndexedDBTypes } from './dbType';

export class RoomName extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('RoomName') KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') Uuid: string = null;
  @IndexedDBTypes.IndexField('string') Typ: string = null;
  @IndexedDBTypes.DataField('string') Bezeichng: string = null;
  constructor(roomName: RoomName) {
    super();
    GlobalHelper.assignIfPropertyExists(this, roomName);
  }

  static filterRoomsPerLevel(roomNames: RoomName[], level: RoomBookPositionLevels): RoomName[] {
    const allRoomNames = roomNames.slice();
    if (level === RoomBookPositionLevels.apartment || level === RoomBookPositionLevels.room)
      return allRoomNames.filter(roomName => roomName.Typ === '2' || roomName.Typ === '1'); // sonderfall - könnte wohnung oder raum wollen
    const roomsPerLevel = allRoomNames.filter(roomName => roomName.Typ === `${level}`);
    return roomsPerLevel;
  }

  static toString(): string {
    return 'RoomName';
  }
}
