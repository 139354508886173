import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@handwerk-pwa/shared';
import { HWAnlage } from '../../../../entities';
import { MaintenanceSystemService } from '../../../../services';

@Component({
  selector: 'app-system-technical-data1',
  templateUrl: './system-technical-data1.component.html',
  styleUrls: ['./system-technical-data1.component.scss'],
})
export class SystemTechnicalData1Component implements OnInit {
  anlage: HWAnlage;
  technischeDatenZusaetze: string[];
  technischeDatenBezeichnung: string[];

  constructor(
    private routingService: RoutingService,
    private maintenanceSystemService: MaintenanceSystemService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAnlage();
    const technischeDaten = this.anlage.TechnischeDaten;
    this.technischeDatenZusaetze = technischeDaten.ZUSAETZE.slice(0, 10);
    this.technischeDatenBezeichnung = technischeDaten.BezeichnungenExtended.slice(0, 10);
  }

  async loadAnlage(): Promise<void> {
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.maintenanceSystemService.findOneAnlageBy('UUID', anlageUUID);
  }
}
