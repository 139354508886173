import { Injectable } from '@angular/core';
import { GlobalHelper, Mobiledevices, UserInfo } from '@handwerk-pwa/shared';
import { environment } from '../../../environments/environment';
import { AddressShortTypes, GlobalSettings } from '../../config';
import { AppOnlySettings, HWGlobalSetting, RepairOrderStatus } from '../../entities';
import { ControllerService } from './controller.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingService {
  lastAddressSelectionType: AddressShortTypes;
  letzterStartseitenIndex: number;
  lastRepairOrderSelectiontype: RepairOrderStatus;
  askForFileSync = true;
  globalIgnoreChanges = false;

  constructor(private controllerService: ControllerService) {}

  async getUserInfo(): Promise<UserInfo> {
    if (this.isFrontendGUIRechte()) return GlobalHelper.readFromLocalStorage('guiUserInfoRechte');
    if (this.isFrontendGUI()) return GlobalHelper.readFromLocalStorage('guiUserInfo');
    let userInfoData = await this.getEntity<UserInfo>(GlobalSettings.UserInfo);
    if (!userInfoData) userInfoData = await this.getEntity<UserInfo>('Userinfo');
    if (userInfoData) {
      const userInfo = new UserInfo(userInfoData);
      userInfo.Device = new Mobiledevices(userInfo.Device);
      return userInfo;
    }
    return null;
  }

  async deleteEntry(key: string): Promise<void> {
    await this.controllerService.deleteData('HWGlobalSetting', key);
  }

  isInProdEnvironment(): boolean {
    const hostname = window.location.hostname;
    let productionUrl = true;
    if (
      hostname.startsWith('192.') ||
      hostname.startsWith('local') ||
      hostname.startsWith('topappdev') ||
      hostname.startsWith('topappqs')
    ) {
      productionUrl = false;
    }
    return environment.production && productionUrl;
  }

  isInQsEnvironment(): boolean {
    const hostname = window.location.hostname;
    let productionUrl = true;
    if (hostname.startsWith('topappqs')) {
      productionUrl = true;
    }
    return environment.production && productionUrl;
  }

  isFrontendGUI(): boolean {
    // is set in appComponent
    return GlobalHelper.readFromLocalStorage('productName') === 'frontendGUI';
  }

  isFrontendGUIRechte(): boolean {
    // is set in appComponent
    return GlobalHelper.readFromLocalStorage('productName') === 'frontendGUIRechte';
  }

  async setEntity<Type>(entity: Type, key: string): Promise<void> {
    await this.controllerService.setData('HWGlobalSetting', [{ key, value: entity }]);
  }

  /**@description Gets the values from the HWGlobalSetting table that don't need a constructor */
  async getEntity<Type>(key: string): Promise<Type> {
    const data = await this.controllerService.getData<HWGlobalSetting[]>('HWGlobalSetting', key);
    if (data.length === 0) return null;
    return data[0].value as Type;
  }

  async getAppOnlySettings(): Promise<AppOnlySettings> {
    const dbAppSettings = await this.getEntity<AppOnlySettings>(GlobalSettings.AppOnlySettings);
    return dbAppSettings ? dbAppSettings : new AppOnlySettings(null);
  }
}
