import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import {
  DxAccordionModule,
  DxBoxModule,
  DxButtonModule,
  DxCalendarModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFormModule,
  DxGalleryModule,
  DxListModule,
  DxLoadPanelModule,
  DxMultiViewModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSpeedDialActionModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTemplateModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTooltipModule,
  DxTreeViewModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { ReplacePipe } from '../assets/replace.pipe';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { GlobalErrorHandler } from './globalErrorHandler/GlobalErrorHandler';

/** Components */
import { AppComponent } from './app.component';

import {
  AddressCardComponent,
  AppointmentCardComponent,
  AufmassFormelComponent,
  BssArrayOrganizerComponent,
  BssButtonLinkComponent,
  BssCheckBoxComponent,
  BssContactBarComponent,
  BssDatetimeBoxComponent,
  BssListComponent,
  BssMeasuringFieldComponent,
  BssMultiViewComponent,
  BssNumberPopupComponent,
  BssPicturePopupComponent,
  BssPlainTextBoxComponent,
  BssRadiobuttonComponent,
  BssSearchPopupComponent,
  BssSpeedDialComponent,
  BssSuggestionFieldComponent,
  BssTimeInputComponent,
  BssToggleTextareaComponent,
  ChooseRaumvorlageComponent,
  ContactCardComponent,
  ContactPersonCardComponent,
  EmailMessageCardComponent,
  HeaderAndSideNavigationModule,
  KmAllowanceComponent,
  ManuellePositionComponent,
  ManuellLoginComponent,
  MeasurementCardComponent,
  MessageCardComponent,
  OrderItemCardComponent,
  ProjectCardComponent,
  QrcodeComponent,
  RaumvorlageComponent,
  RepairOrderCardComponent,
  RoomBookCardComponent,
  SaleCardComponent,
  ServiceOrderCardComponent,
  SignaturePadComponent,
  SupportingDocumentCardComponent,
  SupportingDocumentPositionCardComponent,
  SystemCardComponent,
  VacantPositionCardComponent,
} from './components';

import {
  BssButtonComponent,
  BssNumberboxComponent,
  BssSelectBoxComponent,
  BssSwitchboxComponent,
  BssTextareaComponent,
  BssTextboxComponent,
  MailtestComponent,
  ReloadComponent,
} from '@handwerk-pwa/shared';

/** Pages */
import {
  AddressContactComponent,
  AddressContactPersonComponent,
  AddressEditComponent,
  AddressesIndexComponent,
  AddressSalesComponent,
  AddressVacantPositionsComponent,
  AppointmentEditComponent,
  AppointmentsIndexComponent,
  AufmassDrawComponent,
  AufmassIndexComponent,
  AufmassMessungComponent,
  AufmassNewComponent,
  CalendarComponent,
  ExploreComponent,
  FilesComponent,
  FreeMeasurementIndexComponent,
  HomeComponent,
  ImpressumComponent,
  LoginComponent,
  MassketteEditComponent,
  MeasurementNewComponent,
  MeasurementsIndexComponent,
  MessageNewComponent,
  MessagesIndexComponent,
  ObjectAddressEditComponent,
  ObjectAddressesIndexComponent,
  OrderFinalizeComponent,
  OrderItemEditComponent,
  OrderItemNewComponent,
  OrderItemsIndexComponent,
  RaumEditComponent,
  RepairOrderEditComponent,
  RepairOrderIndexComponent,
  RepairOrderNewComponent,
  RoomBookEditComponent,
  ServiceOrderEditComponent,
  ServiceOrdersIndexComponent,
  SettingsComponent,
  SupportingDocumentShowComponent,
  SupportingDocumentsIndexComponent,
  SystemAppointmentComponent,
  SystemEditComponent,
  SystemLocationComponent,
  SystemsIndexComponent,
  SystemTechnicalData1Component,
  SystemTechnicalData2Component,
} from './pages';

/** Services */
import { ScreenService } from '@handwerk-pwa/shared';

@NgModule({
  declarations: [
    ReplacePipe,
    AppComponent,
    LoginComponent,
    QrcodeComponent,
    AddressesIndexComponent,
    AddressEditComponent,
    ObjectAddressesIndexComponent,
    ObjectAddressEditComponent,
    SupportingDocumentsIndexComponent,
    SupportingDocumentShowComponent,
    CalendarComponent,
    AppointmentEditComponent,
    ImpressumComponent,
    AppointmentsIndexComponent,
    ReloadComponent,
    RepairOrderEditComponent,
    RepairOrderIndexComponent,
    OrderItemsIndexComponent,
    OrderItemEditComponent,
    RepairOrderNewComponent,
    OrderFinalizeComponent,
    OrderItemNewComponent,
    SignaturePadComponent,
    HomeComponent,
    MessagesIndexComponent,
    MessageNewComponent,
    SettingsComponent,
    BssNumberPopupComponent,
    BssPicturePopupComponent,
    BssSearchPopupComponent,
    FilesComponent,
    AddressVacantPositionsComponent,
    AddressSalesComponent,
    AddressContactPersonComponent,
    AddressContactComponent,
    BssContactBarComponent,
    AddressCardComponent,
    RepairOrderCardComponent,
    AufmassDrawComponent,
    AufmassMessungComponent,
    AppointmentCardComponent,
    MessageCardComponent,
    OrderItemCardComponent,
    ManuellePositionComponent,
    SupportingDocumentPositionCardComponent,
    ProjectCardComponent,
    SystemsIndexComponent,
    SystemCardComponent,
    SystemEditComponent,
    SystemLocationComponent,
    SystemAppointmentComponent,
    SystemTechnicalData1Component,
    SystemTechnicalData2Component,
    MeasurementNewComponent,
    BssMeasuringFieldComponent,
    MeasurementsIndexComponent,
    ServiceOrderCardComponent,
    BssListComponent,
    ExploreComponent,
    ManuellLoginComponent,
    MailtestComponent,
    BssRadiobuttonComponent,
    ServiceOrderEditComponent,
    ServiceOrdersIndexComponent,
    BssArrayOrganizerComponent,
    BssTimeInputComponent,
    BssTextboxComponent,
    BssSelectBoxComponent,
    BssTextareaComponent,
    BssNumberboxComponent,
    BssPlainTextBoxComponent,
    BssButtonLinkComponent,
    BssMultiViewComponent,
    BssSwitchboxComponent,
    BssDatetimeBoxComponent,
    SupportingDocumentCardComponent,
    ContactPersonCardComponent,
    ContactCardComponent,
    VacantPositionCardComponent,
    SaleCardComponent,
    BssToggleTextareaComponent,
    MeasurementCardComponent,
    RoomBookEditComponent,
    AufmassIndexComponent,
    RoomBookCardComponent,
    RaumvorlageComponent,
    RaumEditComponent,
    AufmassNewComponent,
    BssSuggestionFieldComponent,
    EmailMessageCardComponent,
    MassketteEditComponent,
    AufmassFormelComponent,
    BssCheckBoxComponent,
    ChooseRaumvorlageComponent,
    KmAllowanceComponent,
    BssSpeedDialComponent,
    BssButtonComponent,
    FreeMeasurementIndexComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ZXingScannerModule,
    DxSelectBoxModule,
    DxListModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    DxFormModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxDateBoxModule,
    DxButtonModule,
    DxTextBoxModule,
    DxMultiViewModule,
    DxCalendarModule,
    DxTemplateModule,
    DxLoadPanelModule,
    DxPopupModule,
    BrowserAnimationsModule,
    DxSwitchModule,
    DxDataGridModule,
    DxRadioGroupModule,
    DxBoxModule,
    DxScrollViewModule,
    DxGalleryModule,
    DxTooltipModule,
    DxTreeViewModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BackButtonDisableModule.forRoot({
      preserveScroll: true,
    }),
    HeaderAndSideNavigationModule,
    DxSpeedDialActionModule,
    DxAccordionModule,
    DxTabPanelModule,
    DxCheckBoxModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    ScreenService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}
