import { Injectable } from '@angular/core';
import { DialogService, Mobiledevices, RestService, RoutingService, UserInfo } from '@handwerk-pwa/shared';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as uuid from 'uuid';
import {
  BackgroundService,
  ConnectionDialogues,
  ConnectionService,
  ControllerService,
  Database,
  GlobalSettingService,
  LocalstorageService,
  StateService,
  SyncService,
} from '..';
import { GlobalSettings } from '../../config';
import { AppOnlySettings, HWGlobalSetting, MultiViewSite, RoomName, RoomTemplate } from '../../entities';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private controllerService: ControllerService,
    private syncService: SyncService,
    private globalSettingService: GlobalSettingService,
    private dialogService: DialogService,
    private backgroundService: BackgroundService,
    private routingService: RoutingService,
    private restService: RestService,
    private deviceService: DeviceDetectorService,
    private localStorageService: LocalstorageService,
    private stateService: StateService,
    private connectionService: ConnectionService,
    private dataBaseService: Database,
  ) {}

  /**@description Logt den User aus - löscht alle Daten */
  async logOut(inUpdateRoutine = false): Promise<void> {
    // login ist schlecht zum ansteuern nach einem pwa Update, da eine Site refreshed werden muss - von der login component aber wird man eventuell zu früh weitergeleitet
    const routeAfterLogout = inUpdateRoutine ? '/impressum' : '/Login';
    try {
      this.backgroundService.stopBackgroundTasks();
      const {
        userInfo,
        discoverLastModifiedBase,
        discoverLastModifiedPremium,
        appOnlySettings,
        multiViewSettings,
        appVersion,
      } = await this.getDataToKeep();
      if (!userInfo?.pin) {
        await this.routingService.navigateTo(routeAfterLogout);
        return;
      }
      await this.syncService.pushAllUnpushedData(true, true);
      await this.updateDeviceInformationInHandwerkDb(userInfo, true);
      let storeNames = this.dataBaseService.stores;
      if (inUpdateRoutine) {
        // if you are in the update routine, the settings should not be deleted
        storeNames = storeNames.filter(
          store =>
            store !== HWGlobalSetting &&
            /* new Stores which could not be found in old idb =>*/
            store !== RoomName &&
            store !== RoomTemplate,
        );
      } else {
        await this.globalSettingService.setEntity(false, GlobalSettings.LoggedIn);
      }
      for (const storeName of storeNames) {
        await this.controllerService.clearStore(storeName.toString());
      }
      if (!inUpdateRoutine)
        // ist man in der update Routine benötigt man anschließend den pin um neu anzumelden - sonst leeren
        userInfo.pin = undefined;
      await this.setDataToKeep(
        userInfo,
        discoverLastModifiedBase,
        discoverLastModifiedPremium,
        appOnlySettings,
        multiViewSettings,
        appVersion,
      );
    } finally {
      // Damit in jedem Fall zurück geroutet werden kann
      this.stateService.loggedIn.next(false);
      await this.routingService.navigateTo(routeAfterLogout);
      this.localStorageService.clearLocalSessionData();
    }
  }

  /**@description Logt den Nutzer über seine userinfo ein */
  async loginUser(userInfo: UserInfo, withSync = true): Promise<void> {
    const isOnline = await this.connectionService.checkOnline(ConnectionDialogues.GetData);
    if (!isOnline) return;

    if (!userInfo.Device.LicenceUuid) {
      userInfo.Device.LicenceUuid = uuid.v4();
    }
    const deviceInfo = this.deviceService.getDeviceInfo();
    userInfo.assignDeviceInfo(deviceInfo);
    userInfo.updateDonePwa = false;
    userInfo = await this.restService.updateAllocationInUserInfo(userInfo);
    const allocation = userInfo.currentAllocation;
    const statusCode = allocation?.statusCode;
    if (statusCode !== 0) {
      this.errorHandling(statusCode);
      return;
    }
    void this.dialogService.openLoadingDialog(
      'Anmeldung',
      'Sie werden angemeldet und Ihre Daten werden synchronisiert.',
    );
    const errorLogSetting = await this.globalSettingService.getEntity(GlobalSettings.ErrorLoggingEnabled);
    if (errorLogSetting === null || errorLogSetting === undefined)
      await this.globalSettingService.setEntity(false, GlobalSettings.ErrorLoggingEnabled);
    await this.enterMainView(userInfo, withSync);
    this.dialogService.closeLoadingDialog();
  }

  /**@description Holt sich die Daten, die auch nach Abmelden behalten werden sollen */
  private async getDataToKeep() {
    const userInfo = await this.globalSettingService.getUserInfo();
    const discoverLastModifiedBase = await this.globalSettingService.getEntity(GlobalSettings.DiscoverLastModifiedBase);
    const discoverLastModifiedPremium = await this.globalSettingService.getEntity(
      GlobalSettings.DiscoverLastModifiedPremium,
    );
    const appOnlySettings = await this.globalSettingService.getEntity<AppOnlySettings>(GlobalSettings.AppOnlySettings);
    const multiViewSettings = await this.globalSettingService.getEntity<MultiViewSite[]>(
      GlobalSettings.MultiViewSettings,
    );
    const appVersion = await this.globalSettingService.getEntity(GlobalSettings.AppVersion);
    return {
      userInfo,
      discoverLastModifiedBase,
      discoverLastModifiedPremium,
      appOnlySettings,
      multiViewSettings,
      appVersion,
    };
  }

  /**@description Speichert nach zurücksetzen der Datenbank die Daten, die auch nach Abmelden behalten werden sollen */
  private async setDataToKeep(
    userInfo: UserInfo,
    discoverLastModifiedBase: unknown,
    discoverLastModifiedPremium: unknown,
    appOnlySettings: AppOnlySettings,
    multiViewSettings: MultiViewSite[],
    appVersion: unknown,
  ): Promise<void> {
    await this.globalSettingService.setEntity(userInfo, GlobalSettings.UserInfo);
    await this.globalSettingService.setEntity(discoverLastModifiedBase, GlobalSettings.DiscoverLastModifiedBase);
    await this.globalSettingService.setEntity(discoverLastModifiedPremium, GlobalSettings.DiscoverLastModifiedPremium);
    await this.globalSettingService.setEntity(appOnlySettings, GlobalSettings.AppOnlySettings);
    await this.globalSettingService.setEntity(multiViewSettings, GlobalSettings.MultiViewSettings);
    await this.globalSettingService.setEntity(appVersion, GlobalSettings.AppVersion);
  }

  private errorHandling(statusCode: number): void {
    if (statusCode === 404) {
      this.dialogService.openInformDialog(
        'Verbindungsfehler',
        'Es existiert keine gültige Allokation. Prüfen Sie ob der Webservice gestartet ist und Verbindung zum Internet hat.',
        'Ok',
      );
      return;
    }
    if (statusCode === 405) {
      this.dialogService.openInformDialog(
        'Verbindungsfehler!',
        'Ihre Allokation ist abgelaufen. Prüfen Sie ob der Webservice weiterhin ordnugsgemäß funktioniert. ',
        'Ok',
      );
      return;
    }
    this.dialogService.openInformDialog('Verbindungsfehler(Allokation)!', 'Statuscode: ' + statusCode, 'OK');
    return;
  }

  /**@description Holt alle holbaren Daten, startet backgroundtask und subscription und registriert das Device für Push Mainview */
  private async enterMainView(userInfo: UserInfo, withSync: boolean): Promise<void> {
    let success = true;
    if (withSync) {
      success = await this.syncService.getAllDataFromWebService(userInfo, true);
    } else {
      await this.syncService.syncDataForAppStart(userInfo, false);
    }
    if (!success) return;
    await this.updateDeviceInformationInHandwerkDb(userInfo);
    const intervalMinutes = await this.backgroundService.getCurrentSyncInterval();
    this.backgroundService.startBackgroundSyncInterval(intervalMinutes);
    this.backgroundService.doDailyCheck();
    await this.globalSettingService.setEntity(true, GlobalSettings.LoggedIn);
    this.stateService.loggedIn.next(true);
    await this.routingService.navigateTo('/startseite');
    if (!withSync) {
      this.routingService.reload();
    }
  }

  /**@description Neuer Endpunkt zum einheitlichen Verwalten der Geräteinformationen im Handwerk */
  private async updateDeviceInformationInHandwerkDb(userInfo: UserInfo, unregisterDevice = false): Promise<void> {
    if (unregisterDevice) userInfo.Device.unregister();
    userInfo.Device.assignPropertiesFromUserInfo(userInfo);
    const url = 'DeviceManagement';
    void this.dialogService.openLoadingDialog('Synchronisation', '...aktualisiere Geräteinformationen...');
    const deviceAnswer = await this.restService.returnData<Mobiledevices>(url, userInfo.Device);
    if (deviceAnswer) {
      userInfo.Device.assignId(deviceAnswer.Id);
      await this.globalSettingService.setEntity(userInfo, GlobalSettings.UserInfo);
    }
    this.dialogService.closeLoadingDialog();
  }
}
