import { Injectable } from '@angular/core';
import { GlobalHelper } from '@handwerk-pwa/shared';
import idbReady from 'safari-14-idb-fix';
import { Database } from './database';

@Injectable({
  providedIn: 'root',
})
export class ControllerService {
  database: Database;
  username: string;
  password: string;

  constructor() {
    this.database = new Database();
  }

  public async createStores(): Promise<void> {
    await idbReady().then(async () => {
      await this.database.setDbVersion();
    });
  }

  public async upgradeIndexedDB(): Promise<void> {
    await idbReady().then(async () => {
      await this.database.rebuildDB(this.database.dbVersion);
    });
  }

  // #############################	  Datenbank Zugriff	 ########################################//

  /**
   * Data aus bestimmter Tabelle holen
   * Option1: nur storeName => gibt kompletten Tabellen Inhalt zurück
   * Option2: storeName, keyValue => gibt Inhalt mit bestimmter KeyValue zurück
   * Option3: storeName, keyValue, key => gibt Inhalt mit bestimmter KeyValue zurück, wobei mit key
   * 			angegeben kann welche Spalte nun mein Index ist
   **/
  public async getData<Type>(storeName: string, keyValue?: string, key?: string): Promise<Type> {
    let outputValue: Type;
    if (key && keyValue) outputValue = await this.database.getAllSpecificData<Type>(storeName, key, keyValue);
    else if (keyValue) outputValue = await this.database.getAllSpecificDataWithKey<Type>(storeName, keyValue);
    else outputValue = await this.database.getAllData<Type>(storeName);
    return outputValue;
  }

  /**@description Löscht Daten in storeName bei denen der key Eintrag dem keyvalue entspricht */
  public async deleteData(
    storeName: string,
    key: string,
    keyValue?: string,
    key2?: string,
    keyValue2?: string | number,
  ): Promise<void> {
    if (!GlobalHelper.isNullOrUndefined(keyValue) && !GlobalHelper.isNullOrUndefined(keyValue2)) {
      await this.database.deleteSpecificData2(storeName, key, keyValue, key2, keyValue2 as string);
      return;
    }
    if (!GlobalHelper.isNullOrUndefined(keyValue)) return this.database.deleteSpecificData(storeName, key, keyValue);
    await this.database.deleteData(storeName, key);
  }

  /**
   * Data in bestimmte Tabelle schreiben
   */
  public async setData(storeName: string, data: unknown[]): Promise<void> {
    await this.database.insert(storeName, data);
  }

  /**
   * Kompletten Inhalt aus bestimmter Tabelle löschen
   */
  public async clearStore(storeName: string): Promise<void> {
    await this.database.clearStore(storeName);
  }
}
