import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MeasurementHelper, RoomEntityName } from '@handwerk-pwa/shared';
import { RoomBookPositionLevels } from '../../../config';
import { Aufmass, RoomBookPosition, RoomName } from '../../../entities';

@Component({
  selector: 'app-raumvorlage',
  templateUrl: './raumvorlage.component.html',
  styleUrls: ['./raumvorlage.component.scss'],
})
export class RaumvorlageComponent implements OnInit {
  @Input() roomNames: RoomName[];
  @Input() parentRoom: RoomBookPosition;
  @Input() parentIsRoomBook: boolean;
  @Input() roomBookPositions: RoomBookPosition[];
  @Input() measurement: Aufmass;
  @Output() selectedRoomBookPosition = new EventEmitter<RoomBookPosition>();
  roomBookPosition: RoomBookPosition;
  floors: RoomBookPosition[];
  apartments: RoomBookPosition[];
  floor: RoomBookPosition;
  apartment: RoomBookPosition;
  roomEntityType: RoomEntityName = 'Raum oder Wohnung';

  ngOnInit(): void {
    const newRoom = new RoomBookPosition(null);
    newRoom.fillNewFromRelative(this.parentRoom, this.parentIsRoomBook, this.measurement);
    this.roomBookPosition = newRoom;
    this.floors = MeasurementHelper.getFloors(this.roomBookPositions);
    this.apartments = MeasurementHelper.getApartments(this.roomBookPositions);
    this.floor = newRoom.findAccordingFloor(this.roomBookPositions);
    this.apartment = newRoom.findAccordingApartment(this.roomBookPositions);
    this.roomEntityType = this.getRoomEntityType(this.roomBookPosition);
  }

  getRoomEntityType(roomBookPosition: RoomBookPosition): RoomEntityName {
    if (roomBookPosition.isFloor()) return 'Etage';
    if (roomBookPosition.level === RoomBookPositionLevels.room) return 'Raum';
    return 'Raum oder Wohnung';
  }

  save(roomBookPosition: RoomBookPosition): void {
    this.selectedRoomBookPosition.emit(roomBookPosition);
  }
}
