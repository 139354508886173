import { Injectable } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { GlobalSettings } from '../../../../../apps/handwerkPWA/src/app/config';
import { HWEmailData } from '../../../../../apps/handwerkPWA/src/app/entities';
import { BaseAuftrag } from '../../../../../apps/handwerkPWA/src/app/interfaces';
import { GlobalSettingService } from '../../../../../apps/handwerkPWA/src/app/services';
import { EmailConnectionInfo, Setting, UserInfo } from '../entities';
import { GlobalHelper } from '../helper';
import { RestService } from '../services';
import { EncryptionService } from './encryption.service';
import { RightsService } from './rights.service';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(
    private dialogService: DialogService,
    private restService: RestService,
    private globalSettingService: GlobalSettingService,
    private rightsService: RightsService,
    private encryptionService: EncryptionService,
  ) {}

  /**@description Sendet die E-mail zu einem Reparatur oder Serviceauftrag */
  async sendOrderMail(order: BaseAuftrag, pdf: string, mailAddress: string): Promise<HWEmailData> {
    const emailConnectionInfo = await this.getEmailConnectionInfo();

    if (!mailAddress) mailAddress = await this.emptyEmailDialog(mailAddress);

    const possible = await this.checkSendPossible(mailAddress, emailConnectionInfo);
    if (!possible) return null;

    const emailData = new HWEmailData(emailConnectionInfo);
    const settings = await this.globalSettingService.getEntity<Setting>(GlobalSettings.Settings);

    emailData.assignMailData(order, settings, mailAddress, pdf);
    const response: string = await this.sendMail(emailData);
    const success = await this.handleEmailResponse(response);
    if (success) return emailData;
    return null;
  }

  async sendTestMail(settings: Setting, mailAddress: string): Promise<string> {
    const emailConnectionInfo = settings.emailConnectionInfo
      ? settings.emailConnectionInfo
      : await this.getEmailConnectionInfo();
    const possible = await this.checkSendPossible(mailAddress, emailConnectionInfo);
    if (!possible) return null;

    const emailData = new HWEmailData(emailConnectionInfo);
    emailData.assignTestMailData(settings, mailAddress);
    const response: string = await this.sendMail(emailData);
    await this.handleEmailResponse(response);
    return response;
  }

  async validateEmailAddress(emailAddress: string): Promise<boolean> {
    if (!emailAddress) {
      await this.dialogService.openErrorMessage(
        'E-Mail',
        'Die E-Mail konnte nicht versendet werden, da keine Empfängeradresse vorhanden ist.',
      );
      return false;
    }
    const atSplit = emailAddress.split('@');
    if (atSplit?.length !== 2 || !atSplit[1]?.includes('.')) {
      await this.dialogService.openErrorMessage('Fehler', 'Empfänger-Adresse ist ungültig.');
      return false;
    }
    return true;
  }

  async validateEmailConnection(
    emailConnection: EmailConnectionInfo,
    checkForSendEmail: boolean = false,
  ): Promise<boolean> {
    if (checkForSendEmail && GlobalHelper.isNullOrUndefined(emailConnection)) {
      await this.dialogService.openAwaitableInformDialog(
        'Fehlende E-Mail-Einstellungen',
        'Sie müssen die E-Mail-Einstellungen ausfüllen oder vom Server synchronisieren um E-Mails versenden zu können',
        'Ok',
      );
      return false;
    }
    if (GlobalHelper.isNullOrUndefined(emailConnection?.smtpUser)) {
      await this.dialogService.openAwaitableInformDialog(
        'Fehlender Benutzername',
        'Sie müssen einen Benutzernamen angeben um die E-Mail Zugangsdaten zu speichern',
        'Ok',
      );
      return false;
    }
    if (GlobalHelper.isNullOrUndefined(emailConnection?.smtpPassword)) {
      await this.dialogService.openAwaitableInformDialog(
        'Fehlendes Passwort',
        'Sie müssen ein Passwort angeben um die E-Mail Zugangsdaten zu speichern',
        'Ok',
      );
      return false;
    }
    if (GlobalHelper.isNullOrUndefined(emailConnection?.smtpServer)) {
      await this.dialogService.openAwaitableInformDialog(
        'Fehlender SMTP-Server',
        'Sie müssen einen SMTP-Server angeben um die E-Mail Zugangsdaten zu speichern',
        'Ok',
      );
      return false;
    }
    if (
      !GlobalHelper.isNullOrUndefinedOrEmptyString(emailConnection?.smtpUser) &&
      !GlobalHelper.isNullOrUndefinedOrEmptyString(emailConnection?.smtpPassword) &&
      !GlobalHelper.isNullOrUndefinedOrEmptyString(emailConnection?.smtpServer) &&
      GlobalHelper.isNullOrUndefined(emailConnection?.smtpPort)
    ) {
      await this.dialogService.openAwaitableInformDialog(
        'Fehlender SMTP-Port',
        'Sie müssen einen SMTP-Port angeben um die E-Mail Zugangsdaten zu speichern',
        'Ok',
      );
      return false;
    }
    return true;
  }

  async setEmailConnectionInfo(
    emailConnectionInfo: EmailConnectionInfo,
    containingPasswordAsBase64 = false,
  ): Promise<void> {
    const newMailConnectionInfo = new EmailConnectionInfo();
    Object.assign(newMailConnectionInfo, emailConnectionInfo);
    if (containingPasswordAsBase64 && newMailConnectionInfo?.smtpPassword)
      newMailConnectionInfo.smtpPassword = window.atob(newMailConnectionInfo.smtpPassword);
    const passwordEncrypted = this.encryptionService.encrypt(newMailConnectionInfo.smtpPassword);
    newMailConnectionInfo.smtpPassword = passwordEncrypted;
    const settings = await this.globalSettingService.getEntity<Setting>(GlobalSettings.Settings);
    settings.emailConnectionInfo = newMailConnectionInfo;
    await this.globalSettingService.setEntity(settings, GlobalSettings.Settings);
  }

  async getEmailConnectionInfo(): Promise<EmailConnectionInfo> {
    const settings = await this.globalSettingService.getEntity<Setting>(GlobalSettings.Settings);
    const emailConnectionInfo = settings.emailConnectionInfo;
    if (!emailConnectionInfo) return null;
    const passwordEncrypted = emailConnectionInfo.smtpPassword;
    const passwordDecrypted = this.encryptionService.decrypt(passwordEncrypted);
    emailConnectionInfo.smtpPassword = passwordDecrypted;
    return emailConnectionInfo;
  }

  public async overrideLocalWithWebService(userInfo: UserInfo, showDialog: boolean): Promise<EmailConnectionInfo> {
    const setting = await this.rightsService.getSettingFromWebService(userInfo, !showDialog);
    await this.setEmailConnectionInfo(setting.emailConnectionInfo, true);
    return setting.emailConnectionInfo;
  }

  public openInvalidEmailDialog: () => Promise<void> = async () => {
    await this.dialogService.openErrorMessage('Fehler', 'Die E-Mail Adresse ist nicht gültig');
  };

  /**@description Lets the user choose to create an email if it is missing */
  private async emptyEmailDialog(mailAddress: string): Promise<string> {
    const confirmResponse = await this.dialogService.openConfirmDialog(
      'E-Mail-Adresse erstellen?',
      'Es wurde keine E-Mail-Adresse angegeben, an welche die E-Mail versandt werden soll. Möchten Sie diese jetzt eingeben?',
      'E-Mail-Adresse eingeben',
      'keine E-Mail verschicken',
      true,
    );
    if (confirmResponse) return await this.inputEmailDialog();
    return mailAddress;
  }

  /**@description Opens the InputDialog and returns its output */
  private async inputEmailDialog(): Promise<string> {
    return await this.dialogService.openInputDialog('E-Mail', 'Folgende E-Mail-Adresse soll verwendet werden:');
  }

  /**@description Benachrichtigt den User über erfolg oder misserfolg seines versands */
  private async handleEmailResponse(response: string): Promise<boolean> {
    if (GlobalHelper.isNullOrUndefined(response)) {
      await this.dialogService.openErrorMessage('E-Mail Fehler', 'Es trat ein Fehler beim Emailversand auf.');
      return false;
    }

    if (response.includes('Emailversand erfolgt')) {
      this.dialogService.openInformDialog('Erfolg', 'E-Mail versand erfolgt.');
      return true;
    }
    if (response.includes('mailbox unavailable')) {
      await this.dialogService.openErrorMessage('Fehler', 'Das Postfach des Empfängers konnte nicht erreicht werden.');
      return false;
    }
    if (response.includes('Authentication credentials invalid')) {
      await this.dialogService.openErrorMessage('Fehler', 'E-Mail Zugangsdaten sind ungültig');
      return false;
    }

    await this.dialogService.openErrorMessage('Fehler', 'Es trat ein unbekannter Fehler auf');
    return false;
  }

  /**@description Guckt ob der Versand generell möglich ist (wenn eine Zieladresse vorhanden ist und zumindest ein smtpuser) */
  private async checkSendPossible(mailAddress: string, emailConnectionInfo: EmailConnectionInfo): Promise<boolean> {
    let isPossible = false;
    if (await this.validateEmailAddress(mailAddress)) {
      isPossible = await this.validateEmailConnection(emailConnectionInfo, true);
    }
    return isPossible;
  }

  private async sendMail(emailData: HWEmailData): Promise<string> {
    emailData.smtpPassword = window.btoa(emailData.smtpPassword);
    const response = await this.restService.returnData<string>('sendRepairOrderEmail', emailData);
    return response;
  }
}
