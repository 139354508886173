import { Component, Input, OnInit } from '@angular/core';
import { Belegpositionen } from '../../../../entities';

@Component({
  selector: 'app-supporting-document-position-card',
  templateUrl: './supporting-document-position-card.component.html',
  styleUrls: ['./supporting-document-position-card.component.scss'],
})
export class SupportingDocumentPositionCardComponent implements OnInit {
  @Input() belegposition: Belegpositionen;
  @Input() priceRight: boolean;
  menge: string;

  ngOnInit(): void {
    // Auswertung für Löhne
    const hex = parseInt(this.belegposition.XFlags, 16);
    const result = hex & 1;
    if (result === 1) this.menge = (this.belegposition.Zeit / 60).toString();
    else this.menge = this.belegposition.Menge.toString();
  }
}
