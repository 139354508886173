import { AddressLongTypes, AddressShortTypes } from '../../../config';

export class AddressTypeHelper {
  /**@description Holt den ShortType für den jeweiligen Langtypen */
  static getShortTypeFromType(adressType: AddressLongTypes): AddressShortTypes {
    switch (adressType) {
      case 'Lieferanten': {
        return 'L';
      }
      case 'Mitarbeiter': {
        return 'M';
      }
      case 'Kunden': {
        return 'K';
      }
      case 'Freie Adressen': {
        return 'F';
      }
      case 'Objektadressen': {
        return 'O';
      }
    }
  }

  /**@description Nimmt das Buchstabenkürzel der Adressen und übersetzt es in den KlarNamen */
  static getAddressesFullType(addressTypeShort: AddressShortTypes): AddressLongTypes {
    switch (addressTypeShort) {
      case 'K':
        return 'Kunden';
      case 'M':
        return 'Mitarbeiter';
      case 'L':
        return 'Lieferanten';
      case 'F':
        return 'Freie Adressen';
      case 'O':
        return 'Objektadressen';
    }
  }
}
