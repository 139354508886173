import { GlobalHelper, TimeHelper } from '@handwerk-pwa/shared';
import moment from 'moment';
import { AppointmentType, AppointmentTypeText, calendarSyncedMonths } from '../../config';
import { Datesortable } from '../../interfaces';
import { SyncObject } from '../models/SyncObject';
import { HWAddress } from './HWAddress';
import { IndexedDBTypes } from './dbType';

export enum AppointmentEventType {
  Single = '0',
  Recurring = '1',
  Occurrence = '2',
  Exception = '3',
  Change = '4',
}

export class HWTermin extends IndexedDBTypes.DbType implements Datesortable, SyncObject {
  @IndexedDBTypes.KlassenName('HWTermin') KlassenName: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: number;
  @IndexedDBTypes.IndexField('string') id: string;
  @IndexedDBTypes.IndexField('string') indexDate: string;
  @IndexedDBTypes.IndexField('string') mitarbeiter: string;
  /** adr is the respective customer number ........ */
  @IndexedDBTypes.IndexField('string') adr: string;
  /**@description Possible reference to a stored repair order */
  @IndexedDBTypes.IndexField('string') Referenz: string;
  @IndexedDBTypes.DataField('string') bss_type = AppointmentType.Event;
  @IndexedDBTypes.DataField('string') caption: string;
  @IndexedDBTypes.DataField('string') eventtype = AppointmentEventType.Single;
  @IndexedDBTypes.DataField('string') finish: string;
  @IndexedDBTypes.DataField('string') guid: string;
  @IndexedDBTypes.DataField('string') isprivate = 'False';
  @IndexedDBTypes.DataField('string') location: string;
  @IndexedDBTypes.DataField('string') message: string;
  @IndexedDBTypes.DataField('string') start: string;
  @IndexedDBTypes.DataField('string') state = '0';
  @IndexedDBTypes.DataField('string') taskcomplete = '0';
  @IndexedDBTypes.DataField('Date') startDate: Date;
  @IndexedDBTypes.DataField('Date') endDate: Date;
  @IndexedDBTypes.DataField('boolean') existing = false;
  @IndexedDBTypes.DataField('string') kundenName: string;
  @IndexedDBTypes.DataField('string') mitarbeiterName: string;
  @IndexedDBTypes.DataField('boolean') isStartOfSeries: boolean;

  // is the index of the recurring appointments that has been edited or -1 on the original appointment
  @IndexedDBTypes.DataField('number') recurrenceIndex: number;

  @IndexedDBTypes.DataField('string') startUhrzeit: string;
  @IndexedDBTypes.DataField('string') endUhrzeit: string;
  /** Indicates whether the appointment extends over several days (not to be confused with serial appointments) */
  @IndexedDBTypes.DataField('boolean') isTerminOverDays = false;
  /**@description Possible reference to the stored W&S documentID (To search substring(1)) */
  @IndexedDBTypes.IndexField('string') DokIdName: string = null;
  @IndexedDBTypes.DataField('string') objectType: 'HWRepairOrder' | 'HWTermin' | 'ServiceAuftrag' = 'HWTermin';
  @IndexedDBTypes.DataField('string') MA_ID_LIST: string = null;
  @IndexedDBTypes.IndexField('boolean', false, true) send = true;
  /**2 scheinbar "normal" , 3 scheinbar ganztägig */
  @IndexedDBTypes.IndexField('string') Options = 2;
  // parentID is the ID of the recurring appointment that it is related to
  @IndexedDBTypes.DataField('string') parentID: number;
  @IndexedDBTypes.DataField('number') idx: number;

  // Constructor sets the passed data with Object.assign during initialization
  constructor(data: object, date?: Date, customerNumber?: string) {
    super();
    Object.assign(this, data);
    this.setIsSeriesAppointment();
    if (!GlobalHelper.isNullOrUndefined(this.start)) {
      this.startDate = this.databaseDateToDate(this.start, true);
      this.indexDate = this.start.substring(0, 10);
      this.startUhrzeit = this.start.substring(11, 16);
    }
    if (!GlobalHelper.isNullOrUndefined(this.finish)) {
      this.endDate = this.databaseDateToDate(this.finish, true);
      this.endUhrzeit = this.finish.substring(11, 16);
    }
    if (GlobalHelper.isNullOrUndefined(date)) {
      return;
    }
    const dbDate = TimeHelper.dateToDatabaseDate(date, true, false);
    this.indexDate = TimeHelper.dateToDatabaseDate(date, false, false);
    this.start = dbDate;
    this.finish = dbDate;
    const dateWithoutSeconds = this.databaseDateToDate(dbDate, true);
    this.startDate = dateWithoutSeconds;
    this.endDate = dateWithoutSeconds;
    if (customerNumber) {
      this.adr = customerNumber;
    }
    this.indexDate = this.start.substring(0, 10);
    this.eventtype = this.eventtype;
    this.recurrenceIndex = this.recurrenceIndex;
  }

  static toString(): string {
    return 'HWTermin';
  }

  static fullDaysBetween(startDate: Date, endDate: Date): number {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    const daysBetween = Math.floor((utc2 - utc1) / MS_PER_DAY);
    return daysBetween - 1; // for full days between
  }

  /**
   * @description If an appointment extends over several days, it is made into individual appointments for display on the respective days
   * @param skipLastDay Do not create the last day, because it is a full day appointment, which formally goes until the next day at 00:01.
   */
  static createOverDaysAppointments(baseTermin: HWTermin, skipLastDay: boolean): HWTermin[] {
    const daysBetween = this.fullDaysBetween(baseTermin.startDate, baseTermin.endDate);
    const dayBegin = '00:01';
    const dayEnd = '23:59';
    const startDay = baseTermin.start.substring(0, 10);
    const startTime = baseTermin.startUhrzeit;
    const endDay = baseTermin.finish.substring(0, 10);
    const endTime = baseTermin.endUhrzeit;
    const appointmentsOverDays: HWTermin[] = [];

    const startDayTermin = new HWTermin(baseTermin);
    startDayTermin.setNewStart(startDay, startTime);
    startDayTermin.setNewEnd(startDay, dayEnd);
    startDayTermin.isTerminOverDays = true;
    appointmentsOverDays.push(startDayTermin);

    let beginningIndex = this.fullDaysBetween(
      startDayTermin.startDate,
      TimeHelper.addMonths(new Date(), -calendarSyncedMonths),
    );
    if (beginningIndex < 0) beginningIndex = 0;
    for (let index = beginningIndex; index < daysBetween; index++) {
      const nextDay = new Date(startDayTermin.startDate);
      const extraDay = startDayTermin.startDate.getDate() + 1 + index;
      nextDay.setDate(extraDay);
      if (nextDay > TimeHelper.addMonths(new Date(), calendarSyncedMonths)) break;
      const appointmentBetween = new HWTermin(baseTermin);
      const currentDateInLoop = TimeHelper.dateToDatabaseDate(nextDay, false, false);
      appointmentBetween.setNewStart(currentDateInLoop, dayBegin);
      appointmentBetween.setNewEnd(currentDateInLoop, dayEnd);
      appointmentBetween.isTerminOverDays = true;
      appointmentsOverDays.push(appointmentBetween);
    }

    const endDayTermin = new HWTermin(baseTermin);
    endDayTermin.setNewStart(endDay, dayBegin);
    endDayTermin.setNewEnd(endDay, endTime);
    endDayTermin.isTerminOverDays = true;
    if (!skipLastDay) appointmentsOverDays.push(endDayTermin);

    return appointmentsOverDays;
  }

  getSortDate(): Date {
    return this.startDate;
  }

  getAppointmentText(): AppointmentTypeText {
    switch (this.bss_type) {
      case AppointmentType.Unknown:
        return 'Termin';
      case AppointmentType.Event:
        return 'Termin';
      case AppointmentType.RepairOrder:
        return 'Auftrag';
      case AppointmentType.Task:
        return 'Aufgabe';
      case AppointmentType.Birthday:
        return 'Geburtstag';
      case AppointmentType.ServiceOrder:
        return 'Termin';
      case AppointmentType.MaintenanceInvoice:
        return 'Wartungsrechnung';
      case AppointmentType.MaintenanceAppointment:
        return 'Wartungstermin';
    }
  }
  setIsSeriesAppointment(): void {
    if (this.bss_type === AppointmentType.Event && this.eventtype === '1') {
      this.isStartOfSeries = true;
      return;
    }
    this.isStartOfSeries = false;
  }

  /**@description Adds the customer information to an appointment */
  addCustomerInfo(customer: HWAddress): void {
    this.adr = customer.KU_NR;
    this.location = customer.ORT;
    this.kundenName = customer.NAME;
  }

  /** Takes a formatted date in the format 31.12.2001 00:00:00 and returns a date */
  databaseDateToDate(currentDateComplete: string, withMinutes?: boolean): Date {
    const formatString = !withMinutes ? 'DD.MM.YYYY' : 'DD.MM.YYYY hh:mm:ss';
    const dateMomentObject = moment(currentDateComplete, formatString);
    const dateObject = dateMomentObject.toDate();
    return dateObject;
  }

  addNewRecurringAppointmentInformation(recurringAppointment: HWTermin): void {
    this.start = recurringAppointment.start;
    this.finish = recurringAppointment.finish;
    this.parentID = Number.parseInt(recurringAppointment.id);
    this.startDate = this.databaseDateToDate(this.start, true);
    this.indexDate = this.start.substring(0, 10);
    this.endDate = this.databaseDateToDate(this.finish, true);
    this.isStartOfSeries = false; // Important, otherwise new ones are searched recursively
    this.idx = recurringAppointment.idx;
    this.recurrenceIndex = recurringAppointment.idx;
  }

  /**@description Adds the necessary information of an order to an appointment*/
  addOrderInfo(employeeNumber: string, orderNumber: string): void {
    this.caption = 'Reparaturauftrag ' + orderNumber;
    this.bss_type = AppointmentType.RepairOrder;
    this.mitarbeiter = employeeNumber;
    this.Referenz = orderNumber;
  }

  /**@description Sets a new start time */
  setNewStart(startDateString: string, startTime: string): void {
    this.indexDate = startDateString;
    this.start = startDateString + ' ' + startTime;
    this.startUhrzeit = startTime;
    this.startDate = TimeHelper.createDateFromStrings(startDateString, startTime);
  }

  /**@description Sets a new end time */
  setNewEnd(endDateString: string, endTime: string): void {
    this.finish = endDateString + ' ' + endTime;
    this.endUhrzeit = endTime;
    this.endDate = TimeHelper.createDateFromStrings(endDateString, endTime);
  }

  isRecurring(): boolean {
    return this.eventtype === AppointmentEventType.Recurring;
  }
}
