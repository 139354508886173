import { Injectable } from '@angular/core';
import {
  AddressRights,
  AuthorizationService,
  DialogService,
  FeatureNames,
  GlobalHelper,
  RestService,
  RightsService,
  UserInfo,
} from '@handwerk-pwa/shared';
import * as uuid from 'uuid';
import { HWAddress, HWBeleg, HWContact, HWFile, HWOffeneposten, HWUmsatz, Medien, SyncObject } from '../../entities';
import { AddressRightHelper, SortHelper } from '../../helper';
import { ControllerService } from '../globalServices/controller.service';
import { GlobalSettingService } from '../globalServices/globalSetting.service';
import { BaseService } from './base.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService implements DataService {
  serviceName = 'AddressService';
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private globalSettingService: GlobalSettingService,
    private rightsService: RightsService,
    private dialogService: DialogService,
    private baseService: BaseService,
    private authorizationService: AuthorizationService,
  ) {}

  /**@description Holt alle Adressen, für die der user das Recht zur Anzeige hat */
  async getAllDisplayable(): Promise<HWAddress[]> {
    const allAddresses = await this.baseService.getAll(HWAddress);
    const addressRights = this.getAddressRights();
    const displayableAddresses = allAddresses.filter(address =>
      AddressRightHelper.hasAddressRight(addressRights, address.ADRTYP, 'show'),
    );
    return SortHelper.sortAddresses(displayableAddresses);
  }

  /**@description Holt alle Adressen aus der IDB */
  async getAll(): Promise<HWAddress[]> {
    return SortHelper.sortAddresses(await this.baseService.getAll(HWAddress));
  }

  /**
   * @description Holt alle Adressen aus der IDB mit bestimmten Suchkriterien
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async getAllBy(selector: string, value: string): Promise<HWAddress[]> {
    return SortHelper.sortAddresses(await this.baseService.getAllBy(HWAddress, selector, value));
  }

  /**
   * @description Holt eine Adressen aus der IDB mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async findOneBy(selector: string, value: string): Promise<HWAddress> {
    return await this.baseService.findOneBy(HWAddress, selector, value);
  }

  /**
   * @description Löscht Adressen mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(HWAddress, selector, value);
  }

  /**
   * @description Speichert die übergebene Adresse sowohl im Webservice, als auch in lokaler Datenbank
   * @param userInfo aktueller UserInfo
   * @param address Adresse die gespeichert werden soll
   * @param silent true/false ob eine Meldung beim Speichern angezeigt werden soll
   * @return Adresse die gespeichert wurde
   * */
  async save(address: HWAddress, silent = false): Promise<HWAddress> {
    const addressToSave = await this.pushToWebservice(address, silent);
    await this.destroy('KU_NR', address.KU_NR);
    await this.saveLocal([addressToSave]);
    return addressToSave;
  }

  /**
   * @description Synchronisiert alle Kunden-, Mitarbeiter-, Freie- und LieferantenAdressen mit dem Webservice
   * @param userInfo aktueller UserInfo
   * @param silent true/false ob eine Meldung angezeigt werden soll
   * */
  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    if (!silent) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Adressen...');
    }

    await this.pushToWebService();
    await this.getFromWebService(userInfo, silent);
  }

  /**
   * @description Kreiert eine neue Adresse und schreibt sie global wie zuvor
   * */
  async createNewAddress(): Promise<HWAddress> {
    const newAddress = new HWAddress(null);
    newAddress.KU_NR = '-1';
    newAddress.ADRTYP = 'K';
    newAddress.LAND = 'D';
    newAddress.GEBURTSTAG = '01.01.1970 00:00:00';
    newAddress.LIEFERSPERRE = false;
    const userInfo = await this.globalSettingService.getUserInfo();
    newAddress.mandant = userInfo.mandant;
    newAddress.Guid = uuid.v4();
    return newAddress;
  }

  /**
   * @description setzt lokal alle Medien von Adressen zurück
   * */
  async resetImagesInIDB(): Promise<void> {
    const allAddresses = await this.getAll();
    for (const address of allAddresses) {
      for (const file of address.Files) {
        file.Data = null;
      }
    }
    await this.destroyAllLocalAddresses();
    await this.saveLocal(allAddresses);
  }

  /**
   * TODO eventuelle Auslagerung in ein Service für Dokumente
   * TODO refactoring
   * @description Überschreibt das Dokument innerhalb einer Adresse in der IDB
   * @param bssFile neue Version vom Dokument mit dem überschrieben wird
   * */
  async updateDocumentInAddress(bssFile: HWFile): Promise<void> {
    const kundenNummer = bssFile.Kundennummer;
    const address = await this.findOneBy('KU_NR', kundenNummer);
    let index = 0;
    for (const tempFile of address.Files) {
      if (tempFile.Name === bssFile.Name) {
        address.Files[index] = bssFile;
        break;
      }
      index++;
    }
    await this.destroy('KU_NR', address.KU_NR);
    await this.saveLocal([address]);
  }

  /**
   * @description Überschreibt die jeweilige Adresse lokal in der IDB
   * */
  async updateDocumentsInAddressLocally(documents: HWFile[]): Promise<void> {
    const kundenNummernToUpdate = documents?.map(document => document.Kundennummer);
    for (const kundenNummer of kundenNummernToUpdate) {
      const address = await this.findOneBy('KU_NR', kundenNummer);
      documents.forEach(file => address.Files.unshift(file));
      await this.destroy('KU_NR', address.KU_NR);
      await this.saveLocal([address]);
    }
  }

  getEditRights(address: HWAddress, rights: AddressRights): boolean {
    switch (address.ADRTYP) {
      case 'M':
        return rights.editEmployees;
      case 'F':
        return rights.editFreeAddresses;
      case 'K':
        return rights.editCustomers;
      case 'L':
        return rights.editSuppliers;
      case 'O':
        return rights.editObjectAddresses;
    }
  }

  /**
   * @description holt alle Adressen
   * @param userInfo aktueller UserInfo
   * @param silent true/false ob eine Meldung angezeigt werden soll
   * @returns alle geholte Adressen
   */
  async getFromWebService(userInfo: UserInfo, silent = true): Promise<void> {
    const addresses: HWAddress[] = [];
    const newAddressesData = await this.restService.returnData<HWAddress[]>(
      'getAllAddresses/mandant/' +
        userInfo.mandant +
        '/GEAENDERT/' +
        userInfo.geaendert +
        '/username/' +
        userInfo.monteur,
      null,
      silent,
    );

    if (GlobalHelper.isNullOrUndefined(newAddressesData)) return;
    const hasNewDatabase = this.authorizationService.current.value.featureCheck(FeatureNames.mediaTable2).available;
    for (const addressData of newAddressesData) {
      // Old method below version 73.0 saves the files as documents
      if (!hasNewDatabase) addressData.Files = GlobalHelper.hWFileArrayGetUniques(addressData.Files);

      const leaveString = addressData.LeaveDate as unknown as string;
      if (leaveString) {
        addressData.LeaveDate = new Date(JSON.parse(leaveString) as Date);
      }

      addresses.push(new HWAddress(addressData));
    }

    await this.destroyAllLocalAddresses();
    await this.saveLocal(addresses);
  }

  /**
   * @description Pusht alle nicht gepushte Adressen zum Webservice
   * @param userInfo aktueller UserInfo
   */
  async pushToWebService(): Promise<void> {
    const allAddresses = await this.getAll();
    const unpushedElements = allAddresses.filter(object => object.KU_NR === '-1');

    const results = [];
    for (const unpushedElement of unpushedElements) results.push(this.pushToWebservice(unpushedElement, true));
    await Promise.all(results);
  }

  getRequiredObjects(): SyncObject[] {
    return [HWFile, Medien, HWBeleg, HWContact, HWUmsatz, HWOffeneposten];
  }

  /**
   * @description Pusht eine Adresse zum Webservice
   * @param address die zu pushende Adresse
   * @param silent true/false ob eine Meldung ausgegeben werden soll
   * @returns gibt bei Erfolg neue Adresse zurück, ansonsten die gleiche Adressen, die übergeben wurde
   */
  private async pushToWebservice(address: HWAddress, silent: boolean): Promise<HWAddress> {
    const newAddressData = await this.restService.returnData<HWAddress>('SetAdresse', address, silent);

    if (!GlobalHelper.isNullOrUndefined(newAddressData) && !(newAddressData.toString() === '503')) {
      const newAddress = new HWAddress(newAddressData);
      if (address.Files) newAddress.Files = address.Files;
      return newAddress;
    }

    return address;
  }

  /**
   * @description speichert übergebene Adresse lokal in die Datenbank
   * @param addresses Adressen die lokal gespeichert werden sollen
   */
  private async saveLocal(addresses: HWAddress[]): Promise<void> {
    await this.controllerService.setData('HWAddress', addresses);
  }

  /**
   * @description Holt alle Rechte die mit Adressen zu tun haben
   * @returns Adressenrechte
   */
  private getAddressRights(): AddressRights {
    const rights = this.rightsService.getCurrentRight();
    const mitarbeiterRechte = rights.employeeRights;
    const addressRights = mitarbeiterRechte.addressRights;

    return addressRights;
  }

  /**
   * @description Löscht alle Adressen lokal in der Datenbank
   */
  private async destroyAllLocalAddresses(): Promise<void> {
    await this.controllerService.clearStore('HWAddress');
  }
}
