import { GlobalHelper } from '../../../helper';
import { Textvorlage } from '../Textvorlage';

export class BlockSetting {
  labelText: string;
  startPositionX: number = null;
  startPositionY: number = null;
  show = true;
  fontsize = 10;
  fontFamily = 'times';
  fontType = 'normal';
  fontColor = '#000000';

  constructor(labelText: string, xPosition: number, yPosition: number) {
    this.labelText = labelText;
    this.startPositionX = xPosition;
    this.startPositionY = yPosition;
  }
}

/**@description Werden über die globalen Einstellungen der PWA festgesetzt */
export class PDFSettings {
  settingsNames = [
    'Firmensettings',
    'Kommunikationssettings',
    'Anschriftsettings',
    'Auftragssettings',
    'Auftragspositionssettings',
    'DatumAuftragsabschluss',
    'UnterschriftSettings',
  ];
  titel = 'Titel';
  Firmensettings: BlockSetting;
  Kommunikationssettings: BlockSetting;
  Anschriftsettings: BlockSetting;
  Auftragssettings: BlockSetting;
  Auftragspositionssettings: BlockSetting;
  DatumAuftragsabschluss: BlockSetting;
  UnterschriftSettings: BlockSetting;
  vortext: Textvorlage = null;
  nachtext: Textvorlage = null;
  showVortext = true;
  showNachtext = true;

  constructor(data?: any, assignProperties = true) {
    this.makeNew();
    if (assignProperties) GlobalHelper.assignIfPropertyExists(this, data);
    this.assignMissingLabelTexts();
    this.vortext = new Textvorlage(this.vortext);
    this.nachtext = new Textvorlage(this.nachtext);
  }

  makeNew(): void {
    this.Firmensettings = new BlockSetting('Eigene Firmenanschrift anzeigen', 10, 10);
    this.Kommunikationssettings = new BlockSetting('Telefon / E-Mail anzeigen', 140, 25);
    this.Anschriftsettings = new BlockSetting('Kundenanschrift anzeigen', 10, 25);
    this.Auftragssettings = new BlockSetting('Auftragsdaten anzeigen', 10, 55);
    this.Auftragspositionssettings = new BlockSetting('Positionen anzeigen', 10, 80);
    this.DatumAuftragsabschluss = new BlockSetting('Datum Auftragsabschluss anzeigen', 10, -1);
    this.UnterschriftSettings = new BlockSetting('Unterschrift anzeigen', 140, -1);
  }

  /**@description Früher enthielten die Objekte nicht den LabelText, dies wird hiermit nachgepflegt */
  private assignMissingLabelTexts(): void {
    this.Firmensettings.labelText = 'Eigene Firmenanschrift anzeigen';
    this.Kommunikationssettings.labelText = 'Telefon / E-Mail anzeigen';
    this.Anschriftsettings.labelText = 'Kundenanschrift anzeigen';
    this.Auftragssettings.labelText = 'Auftragsdaten anzeigen';
    this.Auftragspositionssettings.labelText = 'Positionen anzeigen';
    this.DatumAuftragsabschluss.labelText = 'Datum Auftragsabschluss anzeigen';
    this.UnterschriftSettings.labelText = 'Unterschrift anzeigen';
  }
}
