import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@handwerk-pwa/shared';
import { Anlagenstandort, Anschrift, HWAnlage } from '../../../../entities';
import { MaintenanceSystemService } from '../../../../services';

@Component({
  selector: 'app-system-location',
  templateUrl: './system-location.component.html',
  styleUrls: ['./system-location.component.scss'],
})
export class SystemLocationComponent implements OnInit {
  anlage: HWAnlage;
  anlagenStandort: Anlagenstandort;

  constructor(
    private routingService: RoutingService,
    private maintenanceSystemService: MaintenanceSystemService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAnlage();
    this.anlagenStandort = this.anlage.Anlagenstandort;
  }

  openMaps(standort: Anschrift): void {
    const ort = standort.Ort;
    const strasse = standort.Strasse;
    window.open('https://www.google.com/maps/search/?api=1&query=' + strasse + '%2C+' + ort, '_blank');
  }

  async loadAnlage(): Promise<void> {
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.maintenanceSystemService.findOneAnlageBy('UUID', anlageUUID);
  }
}
