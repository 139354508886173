import { HWMessageType, HWNachricht } from '../../entities';

export class MessageHelper {
  /**@description Sortiert die Nachrichten nach Absender */
  static sortBySender(messages: HWNachricht[]): { sendFromPWA: HWNachricht[]; sendFromHW: HWNachricht[] } {
    const sendFromPWA: HWNachricht[] = [];
    const sendFromHW: HWNachricht[] = [];

    for (const message of messages) {
      const type = message.TYP;
      if (type === HWMessageType.Incoming) {
        sendFromPWA.push(message);
        continue;
      }
      sendFromHW.push(message);
    }

    return { sendFromPWA, sendFromHW };
  }
}
