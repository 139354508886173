import { GlobalHelper, MeasurementHelper } from '@handwerk-pwa/shared';
import { MeasurementFormulaRaw } from './MeasurementFormulaRaw';
import { ValueNamePair } from './ValueNamePair';

export class MeasurementFormula {
  Id: number = null;
  Kurzname: string = null;
  Formel: string = null;
  Erkl: string = null;
  Status: string = null;
  Bildname: string = null;
  Uuid: string = null;
  ImageBase64: string = null;
  ValueNamePairs: ValueNamePair[] = [];

  constructor(formula: MeasurementFormulaRaw) {
    GlobalHelper.assignIfPropertyExists(this, formula);
    if (this.Formel) this.ValueNamePairs = MeasurementHelper.convertFormulaToValueNamePairs(this.Formel);
  }

  checkAllPairsMeasured(): boolean {
    return this.ValueNamePairs.every(pair => !GlobalHelper.isNullOrUndefined(pair.value));
  }

  generateValueFilledFormula(): string {
    let transformationFormula = this.Formel;
    const valueNamePairs = this.ValueNamePairs;
    for (const pair of valueNamePairs) {
      const decimalPlaces = pair.value.toString().includes('.') ? pair.value.toString().split('.')[1]?.length : 0;
      transformationFormula = transformationFormula.replaceAll(pair.name, `${pair.value.toFixed(decimalPlaces)}`);
    }
    transformationFormula = transformationFormula.replaceAll('.', ',');
    return transformationFormula;
  }
}
