import { Injectable } from '@angular/core';
import {
  AuthorizationService,
  DialogService,
  GlobalHelper,
  RestService,
  Right,
  RightsService,
  UserInfo,
  discoverBasicUrl,
  discoverPremiumUrl,
} from '@handwerk-pwa/shared';
import { BehaviorSubject } from 'rxjs';
import { GlobalSettings } from '../../config';
import {
  Aufmass,
  HWAddress,
  HWAnlage,
  HWBeleg,
  HWContact,
  HWFile,
  HWMonteur,
  HWNachricht,
  HWObjectAddress,
  HWOffeneposten,
  HWRepairOrder,
  HWTermin,
  HWUmsatz,
  Medien,
  Projekt,
  ServiceAuftrag,
  SyncObject,
  TimeStampTableRequestNew,
  TimestampTable,
} from '../../entities';
import { AddressService } from '../dataServices/address.service';
import { AppointmentService } from '../dataServices/appointment.service';
import { BelegService } from '../dataServices/beleg.service';
import { ContactsService } from '../dataServices/contacts.service';
import { DataService } from '../dataServices/data.service';
import { MaintenanceSystemService } from '../dataServices/maintenanceSystem.service';
import { MeasurementService } from '../dataServices/measurement.service';
import { MonteurService } from '../dataServices/monteur.service';
import { NachrichtenService } from '../dataServices/nachrichten.service';
import { ObjectaddressService } from '../dataServices/objectAddress.service';
import { OffenepostenService } from '../dataServices/offeneposten.service';
import { ProjectService } from '../dataServices/project.service';
import { RepairOrderService } from '../dataServices/repairOrder.service';
import { ServiceOrderService } from '../dataServices/serviceOrder.service';
import { UmsatzService } from '../dataServices/umsatz.service';
import { ConnectionDialogues, ConnectionService } from './connection.service';
import { DocumentService } from './document.service';
import { GlobalSettingService } from './globalSetting.service';
import { MediaService } from './media.service';
import { SyncObjectService } from './syncObject.service';

class DataServiceOrder {
  dataService: DataService;
  syncNumber: number;
  constructor(dataService: DataService, syncNumber: number) {
    this.dataService = dataService;
    this.syncNumber = syncNumber;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  /**Der Boolean gibt an, ob es sich um etwas neues handelt (=true) oder ob etwas neues angeklickt wurde (=false) */
  newThingsDiscovered = new BehaviorSubject<boolean>(false);

  constructor(
    private addressService: AddressService,
    private objectAddressService: ObjectaddressService,
    private contactsService: ContactsService,
    private maintenanceSystemService: MaintenanceSystemService,
    private belegService: BelegService,
    private offenePostenService: OffenepostenService,
    private umsatzService: UmsatzService,
    private appointmentService: AppointmentService,
    private employeeService: MonteurService,
    private repairOrderService: RepairOrderService,
    private nachrichtenService: NachrichtenService,
    private rightsService: RightsService,
    private globalSettingService: GlobalSettingService,
    private dialogService: DialogService,
    private documentService: DocumentService,
    private authorizationService: AuthorizationService,
    private restService: RestService,
    private serviceOrderService: ServiceOrderService,
    private measurementService: MeasurementService,
    private projectService: ProjectService,
    private mediaService: MediaService,
    private connectionService: ConnectionService,
    private syncObjectService: SyncObjectService,
  ) {}

  /**
   * @description Holt alles, was man holen kann
   * @param loginProcess Guckt nur ob Verbindung okay wäre
   */
  async getAllDataFromWebService(userInfo: UserInfo, loginProcess: boolean): Promise<boolean> {
    const silent = false;
    const success = await this.syncDataForAppStart(userInfo, loginProcess);
    if (!success) return false;
    const right = this.rightsService.getCurrentRight();
    const syncTypes = this.getAllDataTypes();
    await this.syncObjects(userInfo, syncTypes, silent);

    await this.getChanges(right, false);
    await this.addressService.resetImagesInIDB();
    await this.objectAddressService.resetImagesInIDB();
    this.dialogService.closeLoadingDialog();
    return true;
  }

  async syncDataForAppStart(userInfo: UserInfo, loginProcess: boolean): Promise<boolean> {
    const checkLogin = await this.authorizationService.checkLogin(userInfo);
    if (!checkLogin?.isOkay) return false;
    await this.employeeService.getFromWebService(userInfo);
    await this.rightsService.getSettingsAndRightsFromWebService(userInfo, true, !loginProcess);
    return true;
  }

  /**
   * @description Neue Methode, prüft ob die Timestamps der Tables sich unterscheiden(übertragen wird immer der aktuellste timestamp der jeweiligen
   * Tabelle)
   * @returns Array von Datensätzen in denen es Änderungen gab*/
  async getChanges(
    right: Right,
    silent = true,
  ): Promise<{
    syncObjects: SyncObject[];
    ordersAlreadyInApp: string[];
    serviceOrderNumbersAlreadyInApp: string[];
  }> {
    const userInfo = await this.globalSettingService.getUserInfo();
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...prüfe auf neue/geänderte Daten...');
    const repairOrders = await this.repairOrderService.getAllRepairOrdersFromIDB(true);
    const repairOrderNumbersAlreadyInApp = repairOrders?.map(order => order.Nummer);
    const serviceOrderNumbersAlreadyInApp = await this.serviceOrderService.getAllServiceOrderNumbersFromIDB();
    const showAllAppointments = right.employeeRights.appointmentRights.showAll;
    const timeStampTableRequest = new TimeStampTableRequestNew(
      userInfo.mandant,
      userInfo.monteur,
      repairOrderNumbersAlreadyInApp,
      serviceOrderNumbersAlreadyInApp,
      showAllAppointments,
    );
    const tableResponse = await this.restService.returnData<TimestampTable>(
      'GetGeaendertTableNew',
      timeStampTableRequest,
      true,
    );
    const timeStampTable = new TimestampTable(tableResponse);

    const lastTimestampTable: TimestampTable = new TimestampTable(
      await this.globalSettingService.getEntity(GlobalSettings.TimestampTable),
    );

    await this.globalSettingService.setEntity(timeStampTable, GlobalSettings.TimestampTable);
    const changesIn = timeStampTable.checkForUpdates(lastTimestampTable, right);
    return {
      syncObjects: changesIn,
      ordersAlreadyInApp: repairOrderNumbersAlreadyInApp,
      serviceOrderNumbersAlreadyInApp,
    };
  }

  /**@description Schickt alle Daten an den Webservice, die bisher noch nicht übertragen wurden */
  async pushAllUnpushedData(bypassCheck: boolean, silent: boolean): Promise<void> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const allTypes = this.getAllDataTypes();
    if (GlobalHelper.isNullOrUndefined(bypassCheck)) {
      const checkLogin = await this.authorizationService.checkLogin(userInfo, silent);
      if (!checkLogin?.isOkay) return;
    }
    if (!silent)
      void this.dialogService.openLoadingDialog(
        'Synchronisation',
        '...sende alle nicht synchronisierten Daten an den Webservice...',
      );
    await this.pushObjects(userInfo, allTypes, silent);
    const addresses = await this.addressService.getAll();
    const objectAddresses = await this.objectAddressService.getAll();

    const unpushedDocuments = this.documentService.getUnpushedHWFiles(addresses, objectAddresses);
    const allImagesPushed = GlobalHelper.isNullOrUndefinedOrEmptyArray(unpushedDocuments) ? true : false;
    if (this.globalSettingService.askForFileSync && !allImagesPushed) {
      await this.pushUnpushedFiles(userInfo, unpushedDocuments);
      return;
    }
    this.dialogService.closeLoadingDialog();
  }

  async pushUnpushedFiles(userInfo: UserInfo, unpushedDocuments: HWFile[]): Promise<void> {
    this.dialogService.closeLoadingDialog();
    const silent = false;
    const syncData = await this.dialogService.openConfirmDialog(
      'Nicht synchronisierte Dateien!',
      'Sie haben Dateien hinzugefügt, diese aber noch nicht synchronisiert. Möchten Sie diese nun synchronisieren?',
      'Synchronisieren',
      'Später',
      false,
    );
    if (syncData) {
      await this.documentService.synchronize(userInfo);
      await this.mediaService.synchronize(userInfo, silent);
      this.globalSettingService.askForFileSync = unpushedDocuments?.length !== 0;
      return;
    }
    this.dialogService.closeLoadingDialog();
    const notAsk = await this.dialogService.openConfirmDialog(
      'System',
      'Möchten Sie die Synchronisationsanfrage temporär bis zu einem Appneustart ausblenden?',
      'Ausblenden',
      'Beibehalten',
      false,
    );
    if (notAsk) {
      this.globalSettingService.askForFileSync = false;
      return;
    }
    this.globalSettingService.askForFileSync = true;
  }

  /**
   * @description Prüft ob neue Daten für den "Discover" Bereich bzw den Premium discover Bereich existieren
   * - prüft dann ob neue Daten für den Bereich,den man hat existieren,ist dies der Fall,leitet die Info weiter an das Discover-Flag
   */
  async getNewThingsToDiscover(): Promise<void> {
    const lastTimestampBase: Date = await this.globalSettingService.getEntity(GlobalSettings.DiscoverLastModifiedBase);
    const lastTimestampPremium: Date = await this.globalSettingService.getEntity(
      GlobalSettings.DiscoverLastModifiedPremium,
    );
    const currentTimeStampBase = await this.restService.httpLastModified(discoverBasicUrl);
    const currentTimeStampPremium = await this.restService.httpLastModified(discoverPremiumUrl);
    let newDiscoverContentBase = false;
    if (!GlobalHelper.isNullOrUndefined(lastTimestampBase)) {
      newDiscoverContentBase = currentTimeStampBase > lastTimestampBase;
    }
    let newDiscoverContentPremium = false;
    if (!GlobalHelper.isNullOrUndefined(lastTimestampPremium)) {
      newDiscoverContentPremium = currentTimeStampPremium > lastTimestampPremium;
    }
    const newThingsBase = GlobalHelper.isNullOrUndefined(lastTimestampBase) || newDiscoverContentBase;
    if (newThingsBase)
      await this.globalSettingService.setEntity(currentTimeStampBase, GlobalSettings.DiscoverLastModifiedBase);

    const newThingsPremium = GlobalHelper.isNullOrUndefined(lastTimestampPremium) || newDiscoverContentPremium;
    if (newThingsPremium)
      await this.globalSettingService.setEntity(currentTimeStampPremium, GlobalSettings.DiscoverLastModifiedPremium);

    const isPremium = this.rightsService.isPremium();
    if ((isPremium && newThingsPremium) || (!isPremium && newThingsBase)) this.newThingsDiscovered.next(true);
  }

  /**
   * Called when using the sync buttons in the components
   * Syncs the Objects that are in the currentSyncObjects
   */
  public async syncButtonCall(): Promise<void> {
    const types = this.syncObjectService.currentSyncObjects.value;
    if (!types) return;

    const isOnline = this.connectionService.checkOnline(ConnectionDialogues.GetData, false);
    if (!isOnline) return;

    const userInfo = await this.globalSettingService.getUserInfo();
    const checkLogin = await this.authorizationService.checkLogin(userInfo, false);
    if (!checkLogin?.isOkay) return;

    await this.rightsService.getSettingsAndRightsFromWebService(userInfo, !false, true);
    await this.syncObjects(userInfo, types, false);
  }

  /**
   * Synchronizes all the requirements and the input types
   * @param types category types that need to be synced. More types will be added based on the requirements of each type
   * @param silent show a message on the push / get function if they take it
   */
  async syncObjects(userInfo: UserInfo, types: SyncObject[], silent: boolean): Promise<void> {
    const services = this.getRequiredServices(types);
    for (const service of services) {
      await service.synchronize(userInfo, silent);
    }
    this.dialogService.closeLoadingDialog();
  }

  /**
   * Pushes all the requirements and the input types
   * @param types category types that need to be synced. More types will be added based on the requirements of each type
   * @param silent show a message on the push / get function if they take it
   */
  private async pushObjects(userInfo: UserInfo, types: SyncObject[], silent: boolean): Promise<void> {
    const services = this.getRequiredServices(types);
    const promises = [];
    for (const service of services) {
      promises.push(service.pushToWebService(userInfo, silent));
    }
    await Promise.all(promises);

    this.dialogService.closeLoadingDialog();
  }

  /**
   * Gets all the required Services for the synchronization of parameters types recursively
   * @param types the input types that need to be synchronized
   * @returns all the dataServices sorted from least to most dependent dataService
   */
  private getRequiredServices(types: SyncObject[]): DataService[] {
    let services: DataServiceOrder[] = [];
    // for of loop does not iterate over newly added entries
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < types.length; i++) {
      types = GlobalHelper.addIfNotIncluded(types, this.getServiceFromType(types[i]).dataService.getRequiredObjects());
      services = GlobalHelper.addIfNotIncluded(services, this.getServiceFromType(types[i]));
    }
    return services.sort((a, b) => a.syncNumber - b.syncNumber).map(e => e.dataService);
  }

  /**
   * Gets the service based of the type. To check the dependencies go into the service and check the getRequiredObjects function
   * @param type the input types that need to be synchronized
   * @returns the dataService and the order in which they need to be synchronized based on their dependencies to other objects
   */
  private getServiceFromType(type: SyncObject): DataServiceOrder {
    switch (type) {
      case HWAddress:
        return new DataServiceOrder(this.addressService, 0);
      case HWObjectAddress:
        return new DataServiceOrder(this.objectAddressService, 0);
      case Medien:
        return new DataServiceOrder(this.mediaService, 0);
      case Projekt:
        return new DataServiceOrder(this.projectService, 0);
      case HWMonteur:
        return new DataServiceOrder(this.employeeService, 0);
      case HWBeleg:
        return new DataServiceOrder(this.belegService, 1);
      case HWTermin:
        return new DataServiceOrder(this.appointmentService, 1);
      case HWFile:
        return new DataServiceOrder(this.documentService, 1);
      case HWAnlage:
        return new DataServiceOrder(this.maintenanceSystemService, 1);
      case HWContact:
        return new DataServiceOrder(this.contactsService, 1);
      case HWUmsatz:
        return new DataServiceOrder(this.umsatzService, 1);
      case HWOffeneposten:
        return new DataServiceOrder(this.offenePostenService, 1);
      case HWRepairOrder:
        return new DataServiceOrder(this.repairOrderService, 2);
      case ServiceAuftrag:
        return new DataServiceOrder(this.serviceOrderService, 2);
      case Aufmass:
        return new DataServiceOrder(this.measurementService, 2);
      case HWNachricht:
        return new DataServiceOrder(this.nachrichtenService, 3);

      default:
        throw new Error('type: ' + type + ' not part of DataServices');
    }
  }

  /**
   * gets all dataTypes for the syncAll and pushAll function
   * @returns the types of all Objects with a dataService
   */
  private getAllDataTypes(): SyncObject[] {
    const dataTypes: SyncObject[] = [
      HWAddress,
      HWTermin,
      HWObjectAddress,
      Medien,
      HWFile,
      HWBeleg,
      HWContact,
      HWUmsatz,
      HWOffeneposten,
      HWMonteur,
    ];
    const license = this.rightsService.getCurrentRight()?.employeeRights?.showObjektadressen;
    if (license) dataTypes.push(HWRepairOrder, ServiceAuftrag, HWAnlage, HWNachricht, Aufmass, Projekt);
    return dataTypes;
  }
}
