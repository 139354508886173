import { Injectable } from '@angular/core';
import { DialogService, GlobalHelper, RestService, RightsService, TimeHelper, UserInfo } from '@handwerk-pwa/shared';
import moment from 'moment';
import { calendarSyncedMonths, deDateFormat, timeFormat } from '../../config';
import { AppointmentEventType, HWAddress, HWRepairOrder, HWTermin, ServiceAuftrag, SyncObject } from '../../entities';
import { AppointmentHelper } from '../../helper';
import { BaseAuftrag } from '../../interfaces';
import { ControllerService } from '../globalServices/controller.service';
import { AddressService } from './address.service';
import { BaseService } from './base.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService implements DataService {
  serviceName = 'AppointmentService';
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private addressService: AddressService,
    private dialogService: DialogService,
    private baseService: BaseService,
    private rightsService: RightsService,
  ) {}

  async findOneBy(selector: string, value: string): Promise<HWTermin> {
    return await this.baseService.findOneBy(HWTermin, selector, value);
  }
  async addAppointment(userInfo: UserInfo, uploadAppointment: HWTermin, silent = false): Promise<HWTermin> {
    uploadAppointment.mitarbeiter = userInfo.monteur;
    const responseAppointmentData: HWTermin = await this.restService.returnData<HWTermin>(
      'AddTermin',
      uploadAppointment,
      silent,
    );
    await this.addAddressReferences(uploadAppointment);
    this.addAdditionalUploadInfo(responseAppointmentData, uploadAppointment);
    uploadAppointment.id = responseAppointmentData.id;
    await this.updateInIDB(uploadAppointment);
    return uploadAppointment;
  }

  async updateAppointment(uploadAppointment: HWTermin, silent = false): Promise<HWTermin> {
    const responseAppointmentData: HWTermin = await this.restService.returnData<HWTermin>(
      'UpdateTermin',
      uploadAppointment,
      silent,
    );
    this.addAdditionalUploadInfo(responseAppointmentData, uploadAppointment);
    await this.updateInIDB(uploadAppointment);
    return uploadAppointment;
  }

  /**@description Gets all appointments for with correct rights */
  async getAllAppointmentsFromIDB(
    userInfo: UserInfo,
    customerNr?: string,
    withOverDaysBase = false,
    withOverDaysFromNotSeries = true,
    repairOrders?: HWRepairOrder[],
    filterOutChangedStart = true,
  ): Promise<HWTermin[]> {
    let appointments: HWTermin[] = [];
    const allOverDaysAppointments: HWTermin[] = [];
    const overDaysBaseAppointments: HWTermin[] = [];
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(customerNr)) appointments = await this.baseService.getAll(HWTermin);
    else appointments = await this.baseService.getAllBy(HWTermin, 'adr', customerNr);
    for (const appointment of appointments) {
      const appointmentOverDays = AppointmentHelper.isTerminOverDays(appointment);
      if (appointmentOverDays && withOverDaysFromNotSeries) {
        const skipLastDay = appointment.Options === 3; // whole day appointment => skip next day
        allOverDaysAppointments.push(...HWTermin.createOverDaysAppointments(appointment, skipLastDay));
        overDaysBaseAppointments.push(appointment);
      }
    }
    appointments.push(...allOverDaysAppointments);
    if (!withOverDaysBase)
      appointments = appointments.filter(appointment => !overDaysBaseAppointments.includes(appointment));

    // Filters out the start of the series if it does have a changed appointment
    // Needs to be false if you need access to both the main recurring Appointment AND the Changed
    if (filterOutChangedStart) {
      appointments = appointments.filter(
        filterAppointment =>
          !(
            filterAppointment.recurrenceIndex === -1 &&
            appointments.some(
              includeAppointment =>
                filterAppointment.id === includeAppointment.id && includeAppointment.recurrenceIndex > -1,
            )
          ),
      );
    }

    const rights = this.rightsService.getCurrentRight();
    const hasShowAllAppointmentsRight = rights?.employeeRights?.appointmentRights?.showAll ?? false;

    if (!hasShowAllAppointmentsRight) {
      appointments = appointments.filter(appointment => appointment.MA_ID_LIST?.includes(userInfo.monteur));
    }

    const repairOrderEmployee2: HWTermin[] = [];
    if (repairOrders) {
      if (customerNr) repairOrders = repairOrders.filter(order => order.Monteur2 === customerNr);
      const repairOrderIds = repairOrders.map(order => order.Nummer);
      for (const id of repairOrderIds) {
        const appointment = await this.baseService.findOneBy(HWTermin, 'Referenz', id);
        if (!appointments.find(e => e.id === appointment.id)) repairOrderEmployee2.push(appointment);
      }
    }
    appointments = appointments.concat(repairOrderEmployee2);

    const appointmentsSorted = AppointmentHelper.sortAppointmentsByDate(appointments);
    return appointmentsSorted;
  }

  /**@description Gets all the appointments with corresponding rights from WebService */
  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Termine...');
    await this.pushToWebService(userInfo);
    await this.getFromWebService(userInfo, silent);
  }

  /**@description Updates start and end of an appointment and writes it to the idb */
  async updateOrderAppointment(order: BaseAuftrag): Promise<void> {
    const appointmentId = order?.getTerminId();
    const appointmentObject = await this?.getTerminById(appointmentId);
    if (GlobalHelper.isNullOrUndefined(appointmentObject)) return;
    const newAppointmentDate = order.getSortDate();
    const appointmentMoment = moment(newAppointmentDate);
    const datePart = appointmentMoment.format(deDateFormat);
    const timePart = appointmentMoment.format(timeFormat);
    appointmentObject.setNewStart(datePart, timePart);
    appointmentMoment.add(1, 'h');
    const datePartEnd = appointmentMoment.format(deDateFormat);
    const timePartEnd = appointmentMoment.format(timeFormat);
    appointmentObject.setNewEnd(datePartEnd, timePartEnd);
    await this.updateInIDB(appointmentObject);
  }

  /**@description Writes an order appointment locally to the IDB until the next sync */
  async createLocalAppointmentForRepairOrder(order: HWRepairOrder, customer: HWAddress): Promise<void> {
    const start = order.Termin;
    const endTimeSplit = order?.Endzeit?.split(':');
    const finishDate = moment(start, 'dd.MM.yyyy HH:mm').toDate();
    finishDate.setHours(finishDate.getHours() + 1);
    if (endTimeSplit?.length === 2) {
      // add regular endTime
      const endHours = parseInt(endTimeSplit[0], 10);
      const endMinutes = parseInt(endTimeSplit[1], 10);
      finishDate.setHours(endHours);
      finishDate.setMinutes(endMinutes);
    }
    const finish = moment(finishDate).format('dd.MM.yyyy HH:mm');
    const appointment = new HWTermin({ start, finish });
    appointment.addOrderInfo(order.Monteur1, order.Nummer);
    appointment.addCustomerInfo(customer);
    await this.writeAppointmentsToIDB([appointment], false);
  }

  async updateInIDB(appointment: HWTermin): Promise<void> {
    await this.controllerService.deleteData(HWTermin.toString(), 'AutoKey', '' + appointment.AutoKey);
    await this.writeAppointmentsToIDB([appointment], false);
  }

  /**@description sends untransferred Appointments to webService */
  async pushToWebService(userInfo: UserInfo): Promise<void> {
    const all = await this.baseService.getAll(HWTermin);
    const untransferred = all.filter(appointment => appointment.send === false);
    const promises = [];
    for (const appointment of untransferred) {
      if (!appointment.id) promises.push(this.addAppointment(userInfo, appointment, true));
      else promises.push(this.updateAppointment(appointment, true));
    }
    await Promise.all(promises);
  }

  async getFromWebService(userInfo: UserInfo, silent: boolean): Promise<void> {
    await this.getRegularAppointments(userInfo, silent);
    await this.getRecurringAppointmentsWebService(userInfo, silent);
  }

  getRequiredObjects(): SyncObject[] {
    return [HWAddress, ServiceAuftrag, HWRepairOrder];
  }

  private async addAddressReferences(uploadAppointment: HWTermin): Promise<void> {
    const allAddresses = (await this.addressService.getAll()).filter(
      address => address.ADRTYP === 'K' || address.ADRTYP === 'M',
    );
    const customerAddresses = allAddresses.filter(address => address.ADRTYP === 'K');
    const employeeAddresses = allAddresses.filter(address => address.ADRTYP === 'M');
    AppointmentHelper.putEmployeeNameAndCustomerName(uploadAppointment, customerAddresses, employeeAddresses);
  }

  private addAdditionalUploadInfo(responseAppointmentData: HWTermin, uploadAppointment: HWTermin): void {
    if (!responseAppointmentData) {
      uploadAppointment.send = false;
    } else {
      uploadAppointment.send = true;
    }
  }

  /**@description Writes the appointments to the IDB */
  private async writeAppointmentsToIDB(appointment: HWTermin[], clear: boolean): Promise<void> {
    if (clear) await this.controllerService.clearStore('HWTermin');
    await this.controllerService.setData('HWTermin', appointment);
  }

  /**@description Returns an appointment by its ID */
  private async getTerminById(id: string): Promise<HWTermin> {
    return await this.baseService.findOneBy(HWTermin, 'id', id);
  }

  private async getRegularAppointments(userInfo: UserInfo, silent: boolean): Promise<HWTermin[]> {
    const targetUrl = `termine/mandant/${userInfo.mandant}/KU_NR/${0}/username/${userInfo.monteur}`;
    const allAppointmentData = await this.restService.returnData<HWTermin[]>(targetUrl, null, silent);
    if (GlobalHelper.isNullOrUndefined(allAppointmentData)) {
      return null;
    }
    const allAddresses = await this.addressService.getAll();
    const customerAddresses = allAddresses.filter(address => address.ADRTYP === 'K');
    const employeeAddresses = allAddresses.filter(address => address.ADRTYP === 'M');
    const allAppointments: HWTermin[] = [];
    for (const appointmentData of allAppointmentData) {
      const appointment = new HWTermin(appointmentData);
      AppointmentHelper.putEmployeeNameAndCustomerName(appointment, customerAddresses, employeeAddresses);
      allAppointments.push(appointment);
    }
    await this.writeAppointmentsToIDB(allAppointments, true);
    return allAppointments;
  }

  /**@description Gets all the recurringAppointments with corresponding rights from WebService  */
  private async getRecurringAppointmentsWebService(userInfo: UserInfo, silent: boolean): Promise<HWTermin[]> {
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Serientermine...');
    const start = TimeHelper.dateToDatabaseDate(TimeHelper.addMonths(new Date(), -calendarSyncedMonths));
    const finish = TimeHelper.dateToDatabaseDate(TimeHelper.addMonths(new Date(), calendarSyncedMonths));
    const allLocalAppointments = await this.getAllAppointmentsFromIDB(userInfo, null, false, false);
    const recurringAppointmentStart = allLocalAppointments.filter(appointment => appointment.isStartOfSeries);
    const ids = AppointmentHelper.extractIdsStringFromAppointments(recurringAppointmentStart);
    const targetUrl = `SerienTermine/start/${start}/finish/${finish}`;
    // Gives back all "relevant" recurringAppointments as a JSON String created by the dll
    const recurringAppointmentData = await this.restService.returnData<string>(targetUrl, ids);
    if (GlobalHelper.isNullOrUndefined(recurringAppointmentData)) return null;
    let parsedData: HWTermin[];
    try {
      parsedData = JSON.parse(recurringAppointmentData).Termine as HWTermin[];
    } catch (e) {
      return null;
    }
    const recurringAppointments = [];
    for (let index = 0; index < parsedData.length; index++) {
      const appointment = parsedData[index];
      let recurringAppointment = recurringAppointmentStart.find(
        startAppointment => startAppointment.id === appointment.id,
      );
      delete recurringAppointment.AutoKey;
      recurringAppointment = new HWTermin(recurringAppointment);
      recurringAppointment.addNewRecurringAppointmentInformation(appointment);
      const changedAppointment = allLocalAppointments.some(
        localAppointment =>
          localAppointment.eventtype === AppointmentEventType.Change &&
          localAppointment.parentID?.toString() === recurringAppointment.id &&
          localAppointment.recurrenceIndex?.toString() === recurringAppointment.idx?.toString(),
      );

      const deletedAppointment = allLocalAppointments.some(
        localAppointment =>
          localAppointment.eventtype === AppointmentEventType.Exception &&
          localAppointment.parentID?.toString() === recurringAppointment.id &&
          localAppointment.recurrenceIndex?.toString() === recurringAppointment.idx?.toString(),
      );

      if (changedAppointment || deletedAppointment) continue;
      recurringAppointments.push(recurringAppointment);
    }

    const allAppointmentsWithRecurring = allLocalAppointments.concat(recurringAppointments);
    await this.writeAppointmentsToIDB(allAppointmentsWithRecurring, true);
    return parsedData;
  }
}
