import { GlobalHelper } from '@handwerk-pwa/shared';
import * as uuid from 'uuid';
import { noPreviewImage, noPreviewPdf } from '../../../assets/images/noPreviewImage';
import { SourceOfFileList, allowedPictureTypes } from '../../config';
import { DocumentHelper } from '../../helper';
import { SyncObject } from '../models/SyncObject';
import { HWAddress } from './HWAddress';
import { HWObjectAddress } from './HWObjectAddress';
import { IndexedDBTypes } from './dbType';

/** Media like in the 'Bilder' view of HW in addresses accepts the following datatypes:
 *
 * .jpg, .png, .bmp, .pdf, .csv (does not convert picture files to .jpg)
 */
export class HWFile extends IndexedDBTypes.DbType implements SyncObject {
  @IndexedDBTypes.KlassenName('HWFile') KlassenName: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: number;
  @IndexedDBTypes.IndexField('string') Kundennummer: string;
  @IndexedDBTypes.IndexField('string') LfdNr: string;
  @IndexedDBTypes.DataField('string') Name: string;
  @IndexedDBTypes.DataField('string') Data: string;
  @IndexedDBTypes.DataField('boolean') Send = true;
  @IndexedDBTypes.DataField('string') PreviewPictureData: string;
  @IndexedDBTypes.DataField('string') NameWithoutEnding: string;
  @IndexedDBTypes.DataField('string') Extension: string;
  @IndexedDBTypes.IndexField('string') UUID: string;

  constructor(data: HWFile) {
    super();
    this.UUID = uuid.v4();
    Object.assign(this, data);
    if (!GlobalHelper.isNullOrUndefinedOrEmptyString(this.Name)) {
      this.setFilenameAndExtension(this.Name);
    }
    this.addMissingPreviewIcon();
  }

  static toString(): string {
    return 'HWFile';
  }
  static async createHWFile(file: File, fileName: string): Promise<HWFile> {
    const previewPicture = noPreviewPdf;
    const bssFile = new HWFile(null);
    bssFile.Send = false;
    bssFile.setOriginalName(fileName);
    if (file.type === 'application/pdf') {
      const dataUrlPdf = await DocumentHelper.readBlobAsDataUrlAsync(file);
      bssFile.Data = DocumentHelper.dataUrlToBase64(dataUrlPdf);
      bssFile.PreviewPictureData = previewPicture;
    }
    // Media
    const cleanFileType = file.type.replace('image/', '');
    if (allowedPictureTypes.includes(cleanFileType)) {
      const compressedFile = await DocumentHelper.customCompress(file);
      const dataUrlPicture = await DocumentHelper.readBlobAsDataUrlAsync(compressedFile);
      bssFile.PreviewPictureData = dataUrlPicture;
      bssFile.Data = dataUrlPicture;
    }
    return bssFile;
  }

  /**
   * @description os has the filename "image.jpeg" for newly recorded media - therefore give new name otherwise you could only insert one image
   * because otherwise the next one would have the same name
   */
  setRandomIosFilenameIfNeeded(name: string): void {
    if (name === 'image.jpeg' || name === 'image.jpg') {
      const guid = uuid.v4();
      const uuidSplit = guid.split('-');
      const newFileName = uuidSplit[4] + '.jpeg';
      this.setFilenameAndExtension(newFileName);
    }
  }

  setDataUrl(documentUrl: string): void {
    this.Data = documentUrl;
    if (this.Extension !== 'pdf') this.PreviewPictureData = documentUrl;
  }

  addRelation(sourceOfFile: SourceOfFileList): void {
    if (sourceOfFile instanceof HWAddress || sourceOfFile instanceof HWObjectAddress) {
      sourceOfFile = sourceOfFile as HWAddress;
      this.Kundennummer = sourceOfFile.getCustomerNumber();
      this.LfdNr = sourceOfFile.getLfdNr();
    }
  }

  getOriginalName(): string {
    return this.Name;
  }

  setOriginalName(inputName: string): void {
    this.setFilenameAndExtension(inputName);
  }

  addMissingPreviewIcon(): void {
    // Default is the click picture
    if (!this.PreviewPictureData) this.PreviewPictureData = noPreviewImage;

    const fileName = this.getOriginalName();
    if (!fileName) return;
    const lowerCaseFileEnding = GlobalHelper.splitFilenameAndExtension(fileName).extension;

    if (lowerCaseFileEnding === 'pdf') {
      this.PreviewPictureData = noPreviewPdf;
    }
  }

  private setFilenameAndExtension(nameWithExtension: string): void {
    this.Name = nameWithExtension;
    if (!this.Name) return;
    const nameSplit = nameWithExtension.split('.');
    this.NameWithoutEnding = nameSplit[0];
    const extension = nameSplit[1];
    this.Extension = !GlobalHelper.isNullOrUndefined(extension) ? extension.toLowerCase() : '';
  }
}
