import { EmployeeSettings } from '../setting/EmployeeSettings';
import { PositionSettings } from '../setting/PositionSettings';
import { EmployeeRights } from './rights/EmployeeRights';
import { InAppChangeRights } from './rights/InAppChangeRights';

export class Right {
  inAppChangeRights: InAppChangeRights;
  employeeRights: EmployeeRights;
  employeeSettings: EmployeeSettings;
  positionSettings: PositionSettings;

  constructor(data: Right) {
    this.employeeRights = new EmployeeRights(data?.employeeRights);
    this.inAppChangeRights = new InAppChangeRights(data?.inAppChangeRights);
    this.employeeSettings = new EmployeeSettings(data?.employeeSettings);
    this.positionSettings = new PositionSettings(data?.positionSettings);
  }

  createRestrictedRight(): void {
    this.employeeRights.restrictEverything();
    this.inAppChangeRights.restrictEverything();
  }

  createSuperRight(): void {
    this.employeeRights.allowEverything();
    this.inAppChangeRights.allowEverything();
  }
}
