import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bss-search-popup',
  templateUrl: './bss-search-popup.component.html',
  styleUrls: ['./bss-search-popup.component.scss'],
})
export class BssSearchPopupComponent<Type> implements OnInit {
  @Input({ required: true }) inputDataSource: Type[];

  @Input() customDisplayExpressions: (keyof Type & string)[] = [];
  @Input() additionalSearchExpression: (keyof Type & string)[] = [];
  @Input() headline: string;
  @Input() buttonText: string;
  @Input() buttonStyle = '';
  @Input() icon: string;
  @Input() dataSourceIconPath: string;
  @Input() disabled = false;
  @Input() firstLineLabel: string;
  @Output() selectedValue: EventEmitter<Type> = new EventEmitter<Type>();
  customSearchExpression: string[];
  showPopup = false;

  ngOnInit(): void {
    this.customSearchExpression = this.customDisplayExpressions.concat(this.additionalSearchExpression);
  }

  onItemClick(item: Type): void {
    this.selectedValue.emit(item);
    this.showPopup = false;
  }

  showPopupClicked(disabled: boolean): void {
    if (disabled) return;
    this.showPopup = !this.showPopup;
  }
}
