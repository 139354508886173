import { Component, OnInit } from '@angular/core';
import { RoutingService, UserInfo } from '@handwerk-pwa/shared';
import { messageSearch } from '../../../config';
import { HWNachricht } from '../../../entities';
import { MessageHelper } from '../../../helper';
import { GlobalSettingService, NachrichtenService, SyncObjectService } from '../../../services';

@Component({
  selector: 'app-messages-index',
  templateUrl: './messages-index.component.html',
  styleUrls: ['./messages-index.component.scss'],
})
export class MessagesIndexComponent implements OnInit {
  searchExpressions = messageSearch;
  userInfo: UserInfo;

  messages = [
    {
      messagetype: 'Eingehende Nachrichten',
      icon: 'arrowdown',
      items: [],
      list_id: 'nachrichten',
    },
    {
      messagetype: 'Ausgehende Nachrichten',
      icon: 'arrowup',
      items: [],
      list_id: 'nachrichten',
    },
    {
      messagetype: 'Ausgehende Mails',
      icon: 'email',
      items: [],
      list_id: 'nachrichtenEmail',
    },
  ];

  constructor(
    private nachrichtenService: NachrichtenService,
    private globalSettingService: GlobalSettingService,
    private routingService: RoutingService,
    private syncObjectService: SyncObjectService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.userInfo = await this.globalSettingService.getUserInfo();
    await this.nachrichtenService.getFromWebService(this.userInfo, true);
    const messages = await this.nachrichtenService.getAll();
    this.messages[2].items = await this.nachrichtenService.getAllEmailsSend();
    const sortedNachrichten = MessageHelper.sortBySender(messages);
    this.messages[0].items = sortedNachrichten.sendFromHW;
    this.messages[1].items = sortedNachrichten.sendFromPWA;
    this.syncObjectService.currentSyncObjects.next([HWNachricht]);
  }

  async newMessage(): Promise<void> {
    await this.routingService.navigateTo('nachricht/neu');
  }
}
