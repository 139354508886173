import { Injectable } from '@angular/core';
import { DialogService, RestService, RightsService, UserInfo } from '@handwerk-pwa/shared';
import { Projekt, SyncObject } from '../../entities';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService implements DataService {
  serviceName = 'ProjectService';
  constructor(
    private baseService: BaseService,
    private controllerService: ControllerService,
    private dialogService: DialogService,
    private restService: RestService,
    private rightsService: RightsService,
  ) {}

  async getAll(): Promise<Projekt[]> {
    return await this.baseService.getAll(Projekt);
  }

  async getAllBy(selector: string, value: string): Promise<Projekt[]> {
    return await this.baseService.getAllBy(Projekt, selector, value);
  }

  async findOneBy(selector: string, value: string): Promise<Projekt> {
    return await this.baseService.findOneBy(Projekt, selector, value);
  }

  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(Projekt, selector, value);
  }

  async overrideLocal(projects: Projekt[]): Promise<void> {
    await this.controllerService.clearStore('Projekt');
    await this.controllerService.setData('Projekt', projects);
  }

  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Projekte...');
    await this.pushToWebService();
    await this.getFromWebService(userInfo, silent);
  }

  // Currently no edit or create functionality so pushing them is not needed
  async pushToWebService(): Promise<void> {}

  async getFromWebService(userInfo: UserInfo, silent: boolean): Promise<void> {
    const right = this.rightsService.getCurrentRight();
    if (!right.employeeRights.showObjektadressen) return;
    const projects: Projekt[] = [];
    const targetUrl = 'ProjektePwa';
    const projectsData = await this.restService.returnData<Projekt[]>(targetUrl, null, silent);
    if (!projectsData) return;
    for (const projectData of projectsData) {
      const project = new Projekt(projectData);
      projects.push(project);
    }
    await this.overrideLocal(projects);
  }

  getRequiredObjects(): SyncObject[] {
    return [];
  }
}
