import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DialogService, GlobalHelper } from '@handwerk-pwa/shared';

@Component({
  selector: 'app-bss-number-popup',
  templateUrl: './bss-number-popup.component.html',
  styleUrls: ['./bss-number-popup.component.scss'],
})
export class BssNumberPopupComponent implements OnInit, OnChanges {
  @Input() showTextBox = true;
  @Input() title: string;
  @Input() description: string;
  @Input() labelText: string;
  @Input() mengenTyp: string;
  @Input() placeholder: string;
  @Input() ignoreLabelStyles = false;
  @Output() outputValue = new EventEmitter<number>();
  @Output() cancelOutput = new EventEmitter<boolean>();
  @Output() autolohnButtonClicked = new EventEmitter<boolean>();
  @Input() showArrows = true;
  @Input() min: number;
  @Input() max: number;
  @Input() rtlEnabled = false;
  @Input() minusAllowed = false;
  @Input() showAutoLohnButton = false;
  @Input() distanceMeterMode = false;
  @Input() disabled = false;
  @Input() position = 'center';
  @Input() inputValue: number;
  @Input() DecimalPlaces = 2;
  @Input() DecimalPlacesInputEnabled = true;
  @Input() OpenOnInit = false;
  @Input() ValidateInputDecimals = false;
  //Wird benötigt, um nach dem Abbrechen den vorherigen wert anzuzeigen
  lastSavedString: string;
  currentValue: string;
  isPopupOpen = this.OpenOnInit;
  inputFieldClass = 'inputField backgroundMarked';
  inputMarked = true;
  distanceMeterSequence = '';

  constructor(private dialogService: DialogService) {}

  /**@description Hört auf Keypress Events für distanceMeter */
  @HostListener('window:keyup', ['$event'])
  onKeyPressForDistanceMeter(event: KeyboardEvent): void {
    if (!this.distanceMeterMode || !this.isPopupOpen) return;
    const key = event.key;
    if (key === 'Enter') {
      this.currentValue = GlobalHelper.parseDistanceMeterInput(this.distanceMeterSequence);
      this.distanceMeterSequence = '';
      if (this.currentValue !== '') this.save();
      return;
    }
    this.distanceMeterSequence += key;
  }

  ngOnInit(): void {
    if (this.OpenOnInit) this.openDialog();
    this.setInitialValues();
  }

  ngOnChanges(): void {
    this.setInitialValues();
  }

  setInitialValues(): void {
    if (!this.inputValue) this.inputValue = 0;
    this.lastSavedString = this.inputValue.toFixed(this.DecimalPlaces);
    this.currentValue = this.lastSavedString;
    if (!this.min && !this.minusAllowed) this.min = 0;
  }

  NumberButton(inputNumber: string): void {
    if (this.inputMarked || this.currentValue === '0') {
      this.currentValue = '';
    }
    this.currentValue += inputNumber;
    this.unMark();
  }

  DeleteButton(): void {
    if (this.inputMarked) {
      this.currentValue = '0';
    }
    //Deletes last digit of the value
    const stringLength = this.currentValue.length;
    this.currentValue = this.currentValue.substring(0, stringLength - 1);
    this.unMark();
  }

  CommaButton(): void {
    if (this.inputMarked || this.currentValue === '') {
      this.currentValue = '0.';
    }
    if (this.currentValue.includes('.')) {
      return;
    }
    this.currentValue = this.currentValue + '.';
    this.unMark();
  }

  negativeValueButton(): void {
    let currentNumberValue = parseFloat(this.currentValue);
    currentNumberValue *= -1;
    this.currentValue = currentNumberValue.toString();
    this.unMark();
  }

  /**@description Markiert die Eingabe optisch - hiernach überschreibt der nächste Key die aktuelle Eingabe. Für ein natürliches Eingabegefühl */
  toggleInputMark(): void {
    this.inputFieldClass = this.inputMarked ? 'inputField backgroundTransparent' : 'inputField backgroundMarked';
    this.inputMarked = !this.inputMarked;
  }

  unMark(): void {
    this.inputFieldClass = 'inputField backgroundTransparent';
    this.inputMarked = false;
    return;
  }

  emitOutputValue(currentValue: number): void {
    if (isNaN(currentValue)) return;
    this.outputValue.emit(currentValue);
  }

  plusOne(): void {
    let currentValue = parseFloat(this.currentValue);
    currentValue++;
    this.currentValue = currentValue.toString();
    this.unMark();
  }

  minusOne(): void {
    let currentValue = parseFloat(this.currentValue);
    currentValue--;
    this.currentValue = currentValue.toString();
    this.unMark();
  }

  autolohnClicked(): void {
    this.autolohnButtonClicked.emit(true);
  }

  save(): void {
    this.isPopupOpen = false;

    const numberValue = parseFloat(this.currentValue);
    let decimalplaces = 0;
    if (this.currentValue.includes('.')) {
      decimalplaces = this.currentValue.split('.')[1].length;
    }
    const stringValue = numberValue.toFixed(decimalplaces);
    let isValidOutput = false;
    if (this.ValidateInputDecimals) {
      isValidOutput = this.validateOutput(stringValue);
    } else {
      isValidOutput = true;
    }
    if (isValidOutput) {
      this.currentValue = stringValue;
      this.lastSavedString = this.currentValue;
      this.emitOutputValue(parseFloat(stringValue));
    } else {
      this.cancel();
    }
  }

  cancel(): void {
    this.isPopupOpen = false;
    this.currentValue = this.lastSavedString;
    this.cancelOutput.emit(true);
  }

  openDialog(): void {
    this.isPopupOpen = true;
  }

  validateOutput(output: string): boolean {
    const numberValue = parseFloat(output);
    if (this.DecimalPlaces)
      if (this.DecimalPlaces !== 0 && output.split('.')[1]?.length > this.DecimalPlaces) {
        this.dialogService.openInformDialog(
          'Fehler',
          'Die Nachkommastellen stimmen nicht mit der Vorgabe überein.',
          'Verstanden',
        );
        return false;
      }
    if (this.DecimalPlaces === 0 && output.split('.')[1]?.length > 0) {
      this.dialogService.openInformDialog(
        'Fehler',
        'Eingabe von Nachkommastellen ist hier nicht erlaubt.',
        'Verstanden',
      );
      return false;
    }
    if (numberValue < 0 && !this.minusAllowed) {
      this.dialogService.openInformDialog('Fehler', 'Die Eingabe muss größer als 0 sein.', 'Verstanden');
      return false;
    }
    if (this.min && numberValue < this.min) {
      this.dialogService.openInformDialog('Fehler', 'Die Eingabe unterschreitet den Mindestwert.', 'Verstanden');
      return false;
    }
    if (this.max && numberValue > this.max) {
      this.dialogService.openInformDialog('Fehler', 'Die Eingabe überschreitet den Höchstwert.', 'Verstanden');
      return false;
    }
    if (isNaN(numberValue)) {
      this.dialogService.openInformDialog('Fehler', 'Hat das falsche Format.', 'Verstanden');
      return false;
    }
    return true;
  }
}
