import { GlobalHelper, TimeHelper } from '@handwerk-pwa/shared';
import * as uuid from 'uuid';
import { Anlagendaten } from '../../models/wartung-und-service/Anlagendaten';

export class MessungenFromHandwerk {
  Anlage: string;
  Anlagentyp: string;
  Beschreibung: string;
  Messdatum: string;
  Uuid: string;
  messwerte: string[];
  constructor(data: MessungenFromHandwerk) {
    Object.assign(this, data);
    this.Messdatum = this.Messdatum.substring(0, 10);
  }
}
export class MesswertTabellenEintrag {
  Beschreibung: string;
  Messwert: string;
  Einheit: string;

  constructor(beschreibung: string, messwert: string, einheit: string) {
    this.Beschreibung = beschreibung;
    this.Messwert = messwert;
    this.Einheit = einheit;
  }
}

export class Messwerttabelle {
  Anlagentyp: string; // Nummer des Anlagentyps
  Anlage: string; // anlagen Nummer
  MesswertTabellenEintraege: MesswertTabellenEintrag[] = [];
  Beschreibung = '';
  UUID = uuid.v4();
  Messdatum = TimeHelper.dateToDatabaseDate(new Date(), false, false);
  notTransferred: boolean;
  mandant: string;
  monteur: string;
  messwerte: string[];

  constructor(anlagendaten: Anlagendaten, vorlage?: Messwerttabelle, clean?: boolean) {
    this.Anlagentyp = anlagendaten.DATENBLNR;
    this.Anlage = anlagendaten.ANLAGE;
    if (GlobalHelper.isNullOrUndefined(vorlage)) {
      return;
    }
    for (const eintrag of vorlage.MesswertTabellenEintraege) {
      let value = eintrag.Messwert;
      if (clean) {
        value = '';
      }
      const newEintrag = new MesswertTabellenEintrag(eintrag.Beschreibung, value, eintrag.Einheit);
      this.MesswertTabellenEintraege.push(newEintrag);
    }
  }
}

export class Messwertsatz {
  Sollwerttabelle: Messwerttabelle;
  Istwerttabellen: Messwerttabelle[] = [];

  constructor(anlagendaten: Anlagendaten) {
    this.Sollwerttabelle = new Messwerttabelle(anlagendaten);
  }
}
