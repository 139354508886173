import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Setting } from '@handwerk-pwa/shared';
import { GlobalSettings } from '../../../config';
import { HWRepairOrderItem, ManuellePosition } from '../../../entities';
import { GlobalSettingService } from '../../../services';

@Component({
  selector: 'app-km-allowance',
  templateUrl: './km-allowance.component.html',
  styleUrls: ['./km-allowance.component.scss'],
})
export class KmAllowanceComponent implements OnInit {
  @Input() amount: string;
  @Output() position = new EventEmitter<HWRepairOrderItem>();
  manuellePosition = new ManuellePosition();

  constructor(private globalSettingService: GlobalSettingService) {
    this.manuellePosition.Nummer = '{\\rtf1\\ansi\\deff0\\';
  }

  /**@description Sets initial values */
  async ngOnInit(): Promise<void> {
    const settings = await this.globalSettingService.getEntity<Setting>(GlobalSettings.Settings);
    const kmSettings = settings.kmAllowanceSettings;
    this.manuellePosition.Bezeichnung = kmSettings?.POSTEXT ?? 'An/Abfahrt';
    this.manuellePosition.PriceVK = kmSettings?.PREIS_KM ?? 0.3;
    this.manuellePosition.MengenEinheit = kmSettings?.EINHEIT ?? 'Km';
    this.manuellePosition.type = kmSettings?.POSTYP === 0 ? 'Artikel' : 'Leistung';
    this.manuellePosition.Menge = (this.amount as unknown as number) ?? 0;
  }

  /**@description creates a manual Position */
  createCustom(manuellePosition: ManuellePosition): void {
    const newItem = ManuellePosition.parseRepairOrderItem(manuellePosition);
    delete newItem['PriceEK'];
    delete newItem['PriceVK'];
    this.position.emit(newItem);
  }
}
