import { RoomName } from '../entities';

// Addresses and objectAddresses
export const homeScreenAddressSearch = ['SUCH', 'NAME', 'STRASSE', 'PLZ', 'ORT', 'Name', 'Such'];

export const repairOrderSearch = ['Nummer', 'KundenName', 'Termin', 'ArbeitsOrt', 'Betreff'];

export const addressContactsSearch = ['ArtReadable', 'BETREFF', 'HERRFRAU', 'ANSPRECH', 'BEMERKUNG', 'NAME', 'VON'];

export const addressContactPersonSearch = ['Anrede', 'Name', 'Abteilung', 'Position'];

export const addressSalesSearch = ['jahr', 'monat', 'netto'];

export const vacantPositionsSearch = ['betreff', 'datum', 'brutto', 'netto', 'mahnst', 'bezahlt'];

export const addressSearch = ['FA_TITLE', 'SUCH', 'Name', 'NAME', 'NAME2', 'STRASSE', 'PLZ', 'ORT', 'KU_NR'];

export const objectAddressSearch = ['Name', 'STRASSE', 'PLZ', 'ORT'];

export const appointmentSearch = ['start', 'finish', 'adr', 'caption', 'location'];

export const serviceOrderSearch = [
  'Auftragsnummer',
  'Projektname',
  'AnlageObject.Anlagenstandort.NAME',
  'AnlageObject.Anlagenstandort.ORT',
  'AnlageObject.Anlagenstandort.PLZ',
  'AnlageObject.Anlagenstandort.STRASSE',
  'AnlageObject.Anlagendaten.Anlagentyp',
  'TerminObject.start',
];

export const homeScreenServiceOrderSearch = [
  'Auftragsnummer',
  'Projektname',
  'AnlageObject.Anlagenstandort.NAME',
  'AnlageObject.Anlagendaten.Anlagentyp',
  'TerminObject.start',
];

export const maintenanceSystemSearch = [
  'ANLAGE',
  'SUCH',
  'Anlagendaten.Anlagentyp',
  'Anlagendaten.Kundenobjekt.SUCH',
  'Anlagenstandort.NAME',
  'Anlagenstandort.STRASSE',
  'Anlagenstandort.PLZ',
  'Anlagenstandort.ORT',
];

export const measurementSearch = ['AufName', 'AufmId', 'Beschr', 'Betreff', 'Datum', 'Such'];

export const messageSearch = ['Betreff', 'Auftrag.Nummer', 'Auftrag.KundenName', 'Text'];

export const positionsSearch = ['Bezeichnung', 'AuftrNR', 'Nummer', 'MengenEinheit', 'Menge', 'VK'];

export const supportingDocumentSearch = [
  'Artikelnummer',
  'Gesamtpreis',
  'ID',
  'Kurztext',
  'Menge',
  'Positionsnummer',
  'Rabatt',
  'Belegnummer',
];

export const supportingDocumentsIndexSearch = ['typundnr', 'datum', 'kundenname', 'betreff', 'statusReadable'];

export const roomSearch: (keyof RoomName)[] = ['Bezeichng'];
