import { Injectable } from '@angular/core';
import { DialogService, GlobalHelper, RestService, RightsService, UserInfo } from '@handwerk-pwa/shared';
import { HWAddress, HWUmsatz, SyncObject } from '../../entities';
import { ConnectionDialogues, ConnectionService } from '../globalServices/connection.service';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class UmsatzService implements DataService {
  serviceName = 'UmsatzService';
  constructor(
    private baseService: BaseService,
    private controllerService: ControllerService,
    private restService: RestService,
    private dialogService: DialogService,
    private connectionService: ConnectionService,
    private rightsService: RightsService,
  ) {}

  /**
   * @description Holt alle Umsätze aus der IDB mit bestimmten Suchkriterien
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async getAllBy(selector: string, value: string): Promise<HWUmsatz[]> {
    return await this.baseService.getAllBy(HWUmsatz, selector, value);
  }

  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    await this.pushToWebService();
    await this.getFromWebService(userInfo, silent);
  }

  async getUmsatzByKundennummerByChoice(userInfo: UserInfo, kundenNummer: string): Promise<HWUmsatz[]> {
    const currentUmsatz = await this.getAllBy('Kundennummer', kundenNummer);

    const isOnline = await this.connectionService.checkOnline(ConnectionDialogues.GetData);
    if (!isOnline) return currentUmsatz;

    const liveUmsatz = await this.getUmsatzByKuNrFromWebservice(userInfo, kundenNummer);
    return liveUmsatz;
  }

  // currently not able to edit or create turnovers in app
  async pushToWebService(): Promise<void> {}

  /**@description Holt die jeweiligen Umsaetze.
   *  Schreibt sie in die IDB, löscht zuvor die jeweilige Tabelle in die geschrieben wird */
  async getFromWebService(userInfo: UserInfo, silent: boolean): Promise<void> {
    const right = this.rightsService.getCurrentRight();
    if (!right.employeeRights.addressRights.showTurnover) return;
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Umsätze...');
    const umsaetze = await this.restService.returnData<HWUmsatz[]>(
      'allUmsaetze/mandant/' + userInfo.mandant + '/username/' + userInfo.monteur,
    );
    if (GlobalHelper.isNullOrUndefined(umsaetze)) {
      return;
    }
    await this.writeUmsatzToIDB(umsaetze, true);
  }

  async getUmsatzByKuNrFromWebservice(userInfo: UserInfo, KuNr: string): Promise<HWUmsatz[]> {
    void this.dialogService.openLoadingDialog('Synchronisation', '...hole Umsätze...');
    const umsatzData = await this.restService.returnData<HWUmsatz[]>(
      'umsaetze/mandant/' + userInfo.mandant + '/KU_NR/' + KuNr + '/username/' + userInfo.monteur,
    );
    if (GlobalHelper.isNullOrUndefined(umsatzData)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    const umsaetzeClean: HWUmsatz[] = [];
    for (const data of umsatzData) {
      const umsatz = new HWUmsatz(data);
      umsatz.Kundennummer = KuNr;
      umsaetzeClean.push(umsatz);
    }
    await this.updateUmsatzInIdb(umsaetzeClean);
    this.dialogService.closeLoadingDialog();
    return umsaetzeClean;
  }
  getRequiredObjects(): SyncObject[] {
    return [HWAddress];
  }

  /*******************************************************************************
   *
   *
   *
   * PRIVATE FUNCTIONS
   *
   *
   *
   ******************************************************************************** */

  /**@description Schreibt die Umsaetze in die IDB */
  private async writeUmsatzToIDB(umsaetze: HWUmsatz[], clear: boolean): Promise<void> {
    if (clear) {
      await this.controllerService.clearStore('HWUmsatz');
    }
    await this.controllerService.setData('HWUmsatz', umsaetze);
  }

  private async updateUmsatzInIdb(umsaetze: HWUmsatz[]): Promise<void> {
    if (GlobalHelper.isNullOrUndefinedOrEmptyArray(umsaetze)) return;
    const kundenNummer = umsaetze[0].Kundennummer;
    await this.controllerService.deleteData('HWUmsatz', 'Kundennummer', kundenNummer);
    await this.controllerService.setData('HWUmsatz', umsaetze);
  }
}
