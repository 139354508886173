import { GlobalHelper } from '@handwerk-pwa/shared';
import moment from 'moment';
import * as uuid from 'uuid';
import { MeasurementTypes, deDateTimeFormatWithSeconds } from '../../config';
import { UuidEntity } from '../../interfaces';
import { SyncObject } from '../models/SyncObject';
import { MeasurementConstruct } from '../models/aufmass/MeasurementConstruct';
import { MeasurementDrawStack } from '../models/aufmass/MeasurementDrawStack';
import { MeasurementDrawStackElement } from '../models/aufmass/MeasurementDrawStackElement';
import { RoomBookPosition } from '../models/aufmass/RoomBookPosition';
import { RoomBook } from './RoomBook';
import { RoomTemplate } from './RoomTemplate';
import { IndexedDBTypes } from './dbType';

export enum MeasurementVariant {
  individual = 'E',
  partial = 'T',
  closing = 'S',
}

export class Aufmass extends IndexedDBTypes.DbType implements UuidEntity, SyncObject {
  @IndexedDBTypes.KlassenName('Aufmass') KlassenName: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: number;
  @IndexedDBTypes.IndexField('string') Uuid: string = null;
  @IndexedDBTypes.IndexField('string') AufmId: string = null; // ID
  @IndexedDBTypes.IndexField('string') Type: MeasurementTypes;
  @IndexedDBTypes.DataField('string') Such: string = null;
  @IndexedDBTypes.DataField('string') AufName: string = null;
  @IndexedDBTypes.DataField('string') Variant: MeasurementVariant = null;
  @IndexedDBTypes.DataField('string') Beschr: string = null;
  @IndexedDBTypes.DataField('string') ProjName: string = null;
  @IndexedDBTypes.DataField('string') Kunde: string = null;
  @IndexedDBTypes.DataField('string') RBezeich: string = null;
  @IndexedDBTypes.DataField('string') Datum: string = null;
  @IndexedDBTypes.DataField('number') AppStatus: number = null;
  @IndexedDBTypes.DataField('string') Betreff: string = null;
  /**Eintrag "HAUSTYP" in der Tabelle RVorlage - konkrete Vorlage ist dann mit Haustyp = Rvorlage und Etage,Wohnung,Raum = 0 */
  @IndexedDBTypes.DataField('number') RVorlage: number = null;
  /**@description Nächste Line Id - wird durch jede neue RoomBookposition erhöht */
  @IndexedDBTypes.DataField('number') NPosLineId = 0;
  @IndexedDBTypes.DataField('number') TSTELLEN = 1;
  @IndexedDBTypes.DataField('number') TSCHRITT = 1;
  @IndexedDBTypes.DataField('number') PSTELLEN = 1;
  @IndexedDBTypes.DataField('number') PSCHRITT = 1;
  @IndexedDBTypes.DataField('number') ANZZEILEN = 0;
  @IndexedDBTypes.DataField('string') RAUMBUCH: RoomBook[] = null;
  // PWAONLY:
  @IndexedDBTypes.DataField('number') drawStack: MeasurementDrawStack[] = [];
  @IndexedDBTypes.DataField('number') usedGridFactor: number = null;
  @IndexedDBTypes.DataField('boolean') fullyTransferred: boolean = null;
  @IndexedDBTypes.DataField('string') private RaumbuchPositionen: RoomBookPosition[] = null;

  constructor(measurement: Aufmass) {
    super();
    GlobalHelper.assignIfPropertyExists(this, measurement);
    if (measurement?.Type) this.Type = measurement.Type;
  }

  static toString(): string {
    return 'Aufmass';
  }

  getRoomBookPosition(): RoomBookPosition[] {
    return this.RaumbuchPositionen?.map(position => new RoomBookPosition(position));
  }

  getRoomBook(): RoomBook[] {
    return this.RAUMBUCH?.map(roomBook => new RoomBook(roomBook));
  }

  addRoomToRoomBookPositions(roomBookPosition: RoomBookPosition): void {
    roomBookPosition.deleteNodeInformation();
    roomBookPosition.assignNextRposid(this.NPosLineId);
    this.NPosLineId++;
    this.RaumbuchPositionen?.push(roomBookPosition);
  }

  /**
   * @description Removes the item and all subitems from the measurement
   * @return An array of all removed nodes
   */
  removeRoomFromRoomBookPositions(rootDeleteNode: RoomBookPosition): RoomBookPosition[] {
    if (rootDeleteNode.isFloor()) {
      const allEntitiesInFloor = this.RaumbuchPositionen.filter(pos => pos.Stw_ID === rootDeleteNode.Stw_ID);
      for (const floorMember of allEntitiesInFloor)
        this.RaumbuchPositionen = GlobalHelper.removeFromArray(this.RaumbuchPositionen, floorMember);
      return allEntitiesInFloor;
    } else if (rootDeleteNode.isApartment()) {
      const allEntitiesInApartment = this.RaumbuchPositionen.filter(
        pos => pos.Wng_ID === rootDeleteNode.Wng_ID && pos.Stw_ID === rootDeleteNode.Stw_ID,
      );
      for (const apartmentMember of allEntitiesInApartment)
        this.RaumbuchPositionen = GlobalHelper.removeFromArray(this.RaumbuchPositionen, apartmentMember);
      return allEntitiesInApartment;
    } else {
      const roomToRemove = this.RaumbuchPositionen.filter(pos => pos.Uuid === rootDeleteNode.Uuid)[0];
      this.RaumbuchPositionen = GlobalHelper.removeFromArray(this.RaumbuchPositionen, roomToRemove);
      return [rootDeleteNode];
    }
  }

  /**@description Creates a new measurement based on data entered by the user */
  createNew(type: MeasurementTypes): void {
    this.Uuid = uuid.v4();
    this.NPosLineId = 0;
    const today = new Date();
    this.Datum = moment(today).format(deDateTimeFormatWithSeconds).toString();
    this.Variant = MeasurementVariant.individual;
    this.AppStatus = 0;
    this.setTypeVariables(type);
    this.RaumbuchPositionen = [];
    this.RVorlage = 0;
    this.RAUMBUCH = [];
  }

  setTypeVariables(type: MeasurementTypes): void {
    let measurementName = '';
    switch (type) {
      case MeasurementTypes.roomMeasurement:
        measurementName = 'Raumaufmaß mobil';
        break;
      case MeasurementTypes.freeMeasurement:
        measurementName = 'Freies Aufmaß mobil';
        break;
      case MeasurementTypes.ColumMeasurement:
        measurementName = 'Spaltenaufmaß mobil';
        break;
    }
    this.AufName = measurementName;
    this.Type = type;
  }

  /**@description Adds all room book items of a room template to the measurement */
  addRoomBookPositionsFromRoomBookTemplate(roomBook: number, roomTemplates: RoomTemplate[]): void {
    const correctRoomTemplates = roomTemplates.filter(template => template.Haustyp === roomBook);
    const indexRoomTemplate = correctRoomTemplates.findIndex(rVorlage => rVorlage.isRoomBookTemplate());
    if (indexRoomTemplate > -1) correctRoomTemplates.splice(indexRoomTemplate, 1);
    const roomBookPositions: RoomBookPosition[] = correctRoomTemplates.map(template =>
      template.toRoomBookPosition(this),
    );
    this.NPosLineId = roomBookPositions?.length;
    let index = 0;
    for (const roomBookPosition of roomBookPositions) {
      roomBookPosition.assignNextRposid(index);
      index++;
    }
    this.RaumbuchPositionen = roomBookPositions;
  }
  assignMeasurementConstructToCorrectRoom(room: RoomBookPosition, measurementConstruct: MeasurementConstruct): void {
    const correctRoom = this.RaumbuchPositionen.find(position => position.Uuid === room.Uuid);
    correctRoom.MeasurementConstruct = measurementConstruct;
  }

  /**@description  By now the AufmassID is obtained from the HW and must therefore be carried to the positions after success */
  assignAufmassIdToRoomBookPositions(): void {
    for (const position of this.RaumbuchPositionen) position.AufmId = this.AufmId;
  }

  assignDrawStackElements(uUID: string, drawStackElements: MeasurementDrawStackElement[], gridEnabled: boolean): void {
    const existingElement = this.drawStack.find(element => element.roomUuid === uUID);
    if (existingElement) existingElement.MeasurementDrawStackElements = drawStackElements;
    else {
      const newElement = new MeasurementDrawStack(uUID, drawStackElements, gridEnabled);
      this.drawStack.push(newElement);
    }
  }

  getUuid(): string {
    return this.Uuid;
  }
}
