import { SliderNames } from '../../config';

export class MultiViewSite {
  KlassenName: string;
  title: string;
  dataArray: any[];
  path: string;
  cardName: SliderNames;
  index: number;
  emptyInputDataMessage: string;
  searchExpression: string[];
  userDisabled: boolean;

  // dataArray is set in the home.component instead of constructor to save it better in IDB
  constructor(
    title: string,
    path: string,
    cardName: SliderNames,
    index: number,
    emptyInputDataMessage: string,
    searchExpression: string[],
    userDisabled: boolean,
  ) {
    this.title = title;
    this.path = path;
    this.cardName = cardName;
    this.index = index;
    this.emptyInputDataMessage = emptyInputDataMessage;
    this.searchExpression = searchExpression;
    this.userDisabled = userDisabled;
  }
}
