import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { DialogInformation } from '../entities';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private updateDialogOpen = false;

  async openErrorMessage(dialogTitle: string, message: string): Promise<void> {
    if (this.updateDialogOpen) return null;
    await this.openConfirmDialog(dialogTitle, message, 'Ok', null, false);
  }

  async openLoadingDialog(dialogTitle: string, message: string): Promise<boolean> {
    if (this.updateDialogOpen) return null;
    const windowWidth = window.innerWidth;
    let responseValue: boolean;
    await Swal.fire({
      title: dialogTitle,
      text: message,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Abbrechen',
      allowOutsideClick: false,
      imageUrl: 'assets/icons/bssspinner.gif',
      imageWidth: 0.3 * windowWidth,
      heightAuto: false,
    }).then(result => {
      if (result.value) {
        responseValue = true;
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        responseValue = false;
      }
    });
    return responseValue;
  }

  closeLoadingDialog(): void {
    if (!this.updateDialogOpen) Swal.close();
  }

  openInformDialog(dialogTitle: string, message: string, confirmText = 'Ok', htmlString?: string): void {
    if (this.updateDialogOpen) return null;
    void Swal.fire({
      title: dialogTitle,
      text: message,
      icon: 'info',
      html: htmlString,
      confirmButtonText: confirmText,
      heightAuto: false,
    });
  }

  async openAwaitableInformDialog(
    dialogTitle: string,
    message: string,
    confirmText = 'Ok',
    htmlString?: string,
  ): Promise<void> {
    if (this.updateDialogOpen) return null;
    void (await Swal.fire({
      title: dialogTitle,
      text: message,
      icon: 'info',
      html: htmlString,
      confirmButtonText: confirmText,
      heightAuto: false,
    }));
  }

  /**@description Gibt den Dialog zur Auswahl von Telefonnummern an */
  async openPhoneChoiceDialog(number1: string, number2: string): Promise<string> {
    if (this.updateDialogOpen) return null;
    let phoneNumber: string;
    await Swal.fire({
      title: 'Mehrere Nummern zur Auswahl',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: number1,
      cancelButtonText: number2,
      confirmButtonColor: '#00314C',
      cancelButtonColor: '#00314C',
      heightAuto: false,
    }).then(result => {
      if (result.value) {
        phoneNumber = number1;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        phoneNumber = number2;
      }
    });
    return phoneNumber;
  }

  async openConfirmDialog(
    dialogTitle: string,
    message: string,
    confirmText: string,
    cancelText: string,
    cancelOnClickOutside = false,
    htmlString?: string,
    isUpdateDialog?: boolean,
  ): Promise<boolean> {
    if (this.updateDialogOpen && !isUpdateDialog) return null;
    this.updateDialogOpen = isUpdateDialog;
    let responseValue: boolean;
    await Swal.fire({
      title: dialogTitle,
      text: message,
      icon: 'question',
      html: htmlString,
      showCancelButton: !!cancelText,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      allowOutsideClick: cancelOnClickOutside,
      heightAuto: false,
    }).then(result => {
      if (result.value) {
        responseValue = true;
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        this.updateDialogOpen = false;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        responseValue = false;
        this.updateDialogOpen = false;
      }
    });
    return responseValue;
  }

  async openWaitingConfirmDialog(dialogTitle: string, message: string, confirmText: string): Promise<boolean> {
    if (this.updateDialogOpen) return null;
    let responseValue: boolean;
    await Swal.fire({
      title: dialogTitle,
      text: message,
      icon: 'question',
      showCancelButton: false,
      confirmButtonText: confirmText,
      allowOutsideClick: false,
      heightAuto: false,
    }).then(result => {
      if (result.value) {
        responseValue = true;
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      }
    });
    return responseValue;
  }

  async openThreeOptionDialog(
    title: string,
    confirmButtonText: string,
    denyButtonText: string,
    cancelButtonText: string,
  ): Promise<SweetAlertResult> {
    if (this.updateDialogOpen) return null;
    return Swal.fire({
      title,
      showConfirmButton: true,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText,
      denyButtonText,
      cancelButtonText,
      heightAuto: false,
      allowOutsideClick: false,
    }).then(result => {
      return result;
    });
  }

  async openInputDialog(title: string, text: string): Promise<string> {
    if (this.updateDialogOpen) return null;
    return await Swal.fire({
      title,
      text,
      input: 'text',
      heightAuto: false,
      allowOutsideClick: false,
      inputAttributes: {
        autocapitalize: 'off',
      },
    }).then(result => {
      return result.value as string;
    });
  }

  /**@description Öffnet einen jeweiligen Dialog anhand eines DialogInformation Objekts */
  async openDialogFromDialogInformation(dialogInformation: DialogInformation): Promise<boolean | null> {
    if (this.updateDialogOpen) return null;
    const dialogType = dialogInformation.dialogType;
    const headline = dialogInformation.headline;
    const message = dialogInformation.message;
    const confirmText = dialogInformation.confirmButtonText;
    const declineButtonText = dialogInformation.confirmButtonText;
    const cancelOnClickOutside = dialogInformation.cancelOnClickOutside;
    const htmlString = dialogInformation.htmlString;
    switch (dialogType) {
      case 'confirm':
        return await this.openConfirmDialog(
          headline,
          message,
          confirmText,
          declineButtonText,
          cancelOnClickOutside,
          htmlString,
        );
      case 'inform': {
        this.openInformDialog(headline, message, confirmText, htmlString);
        return null;
      }
      case 'error': {
        await this.openErrorMessage(headline, message);
        return null;
      }
      case 'loading': {
        await this.openLoadingDialog(headline, message);
        return null;
      }
    }
  }
}
