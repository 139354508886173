import { GlobalSettings } from '../../config';
import { IndexedDBTypes } from './dbType';

export class HWGlobalSetting extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWGlobalSetting') KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') key: GlobalSettings;
  @IndexedDBTypes.DataField('object') value: any;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: HWGlobalSetting) {
    super();
    Object.assign(this, data);
  }

  static toString(): string {
    return 'HWGlobalSetting';
  }
}
