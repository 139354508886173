<div *ngIf="showTextBox" (click)="disabled ? '' : openDialog()" class="flexContainer popup-field">
  <div class="flexContainerItem">
    <app-bss-textbox
      [rtlEnabled]="rtlEnabled"
      [placeholder]="placeholder"
      [labelText]="labelText"
      [value]="currentValue.replace('.', ',')"
      [disabled]="true"
    ></app-bss-textbox>
  </div>
  <div style="padding-left: 10px; position: relative; width: 30%">
    <div style="position: absolute; bottom: 0">
      {{ mengenTyp }}
    </div>
  </div>
</div>

<dx-popup
  [width]="'100%'"
  [height]="'auto'"
  [maxWidth]="'500px'"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="false"
  [showTitle]="false"
  container=".dx-viewport"
  [(visible)]="isPopupOpen"
  [shading]="position === undefined"
  (onHidden)="cancel()"
>
  <dxo-position [my]="position">
  </dxo-position>
  <div *dxTemplate="let data of 'content'">
    <p class="title" *ngIf="title">
      {{ title }}
    </p>

    <p class="description" *ngIf="description">
      {{ description }}
    </p>

    <div class="flexContainer" style="flex-direction: column; margin-bottom: 20px">
      <div *ngIf="showArrows" style="text-align: center">
        <button class="arrowButton" (click)="plusOne()" type="button">
          <img src="/assets/icons/dreieckOben.svg" alt="" width="40" />
        </button>
      </div>
      <div class="flexContainer">
        <div class="flexContainerItem">
          <div id="inputField" class="input-field" style="display: flex; flex-direction: row">
            <div class="no-select" [ngClass]="inputFieldClass" (click)="toggleInputMark()">
              {{ currentValue.replace('.', ',') }}
            </div>
            <img class="cursorGif" src="assets\icons\cursor.gif" />
          </div>
        </div>
        <div style="padding-left: 10px; position: relative; width: 30px">
          <div style="line-height: 40px">
            {{ mengenTyp }}
          </div>
        </div>
      </div>
      <div *ngIf="showArrows" style="text-align: center">
        <button class="arrowButton" (click)="minusOne()" type="button">
          <img src="/assets/icons/dreieck.svg" alt="" width="40" />
        </button>
      </div>
    </div>

    <button
      *ngIf="showAutoLohnButton"
      class="bss-button primary-button"
      (click)="autolohnClicked()"
      style="width: 100%; margin-bottom: 10px"
    >
      Automatische Erfassung
    </button>
    <div class="flexContainer" style="margin-bottom: 20px">
      <div class="flexContainerItem" style="padding-right: 5px">
        <button class="bss-button primary-button" (click)="save()" style="width: 100%">Übernehmen</button>
      </div>
      <div class="flexContainerItem" style="padding-left: 5px">
        <button class="bss-button primary-button" (click)="cancel()" style="width: 100%">Abbrechen</button>
      </div>
    </div>

    <div style="display: flex; flex-direction: column">
      <div style="display: flex">
        <div class="numpadButton no-select" (click)="NumberButton('1')">1</div>
        <div class="numpadButton no-select" (click)="NumberButton('2')">2</div>
        <div class="numpadButton no-select" (click)="NumberButton('3')">3</div>
      </div>
      <div style="display: flex">
        <div class="numpadButton no-select" (click)="NumberButton('4')">4</div>
        <div class="numpadButton no-select" (click)="NumberButton('5')">5</div>
        <div class="numpadButton no-select" (click)="NumberButton('6')">6</div>
      </div>
      <div style="display: flex">
        <div class="numpadButton no-select" (click)="NumberButton('7')">7</div>
        <div class="numpadButton no-select" (click)="NumberButton('8')">8</div>
        <div class="numpadButton no-select" (click)="NumberButton('9')">9</div>
      </div>
      <div style="display: flex">
        <div class="numpadButton no-select" (click)="DeleteButton()">
          <img src="/assets/icons/deleteChar.png" alt="" width="25" />
        </div>
        <div class="numpadButton no-select" (click)="NumberButton('0')">0</div>
        <div
          *ngIf="DecimalPlaces > 0 || minusAllowed || DecimalPlacesInputEnabled"
          style="padding-top: 0; padding-bottom: 0"
          class="numpadButton no-select"
        >
          <div class="flexContainer" style="height: 100%">
            <div
              *ngIf="DecimalPlacesInputEnabled"
              class="flexContainerItem"
              style="padding: 10px 10px 0 0"
              (click)="CommaButton()"
            >
              ,
            </div>
            <div *ngIf="DecimalPlaces > 0 && minusAllowed" style="border-left: 1px solid #ccc"></div>
            <div
              *ngIf="minusAllowed"
              class="flexContainerItem"
              style="padding: 10px 0 0 10px"
              (click)="negativeValueButton()"
            >
              -
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>
