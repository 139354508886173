import { GlobalHelper } from '@handwerk-pwa/shared';
import { Aufmass } from '../../repository/Aufmass';
import { RoomBook } from '../../repository/RoomBook';
import { RoomName } from '../../repository/RoomName';
import { RoomTemplate } from '../../repository/RoomTemplate';

export class MeasurementBaseData {
  Raumbuecher: RoomBook[] = null;
  Raumnamen: RoomName[] = null;
  Raumvorlagen: RoomTemplate[] = null;
  Aufmasse: Aufmass[] = null;

  constructor(measurementBaseData: MeasurementBaseData) {
    GlobalHelper.assignIfPropertyExists(this, measurementBaseData);
  }
}
