import { GlobalHelper } from '@handwerk-pwa/shared';

export class AppOnlySettings {
  /**Intervall des Background-Syncs in Minuten, 0 = aus */
  backgroundSyncInterval = 5;
  aufmassRasterFactor = 50;
  aufmassAutoFillRectangleRooms = true;
  aufmassGridOnStart = true;
  constructor(previousData: AppOnlySettings) {
    GlobalHelper.assignIfPropertyExists(this, previousData);
  }
}
