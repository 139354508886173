import { FeatureNames } from '@handwerk-pwa/shared';

export class PageSetting {
  constructor(
    public path: string,
    public hasRight = true,
    public featureName: FeatureNames,
    public licenseNeeded: boolean,
  ) {}
}
