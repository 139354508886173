import { GlobalHelper } from '@handwerk-pwa/shared';
import { PositionType } from '../../config';
import { Positionrequest } from '../../entities';

export class RepairOrderItemHelper {
  /**@description Prüft ob die nötigen Einstellungen vorhanden sind um eine Entscheidung zu treffen */
  static cantUseFilterSettings(positionRequest: Positionrequest, type: PositionType): boolean {
    if (GlobalHelper.isNullOrUndefined(positionRequest)) {
      return true;
    }
    if (type !== 'Artikel' && type !== 'Leistung') {
      return true;
    }
    if (type === 'Artikel' && GlobalHelper.isNullOrUndefined(positionRequest.Lieferanten)) {
      return true;
    }
    if (type === 'Artikel' && !positionRequest.useFilterLieferanten) {
      return true;
    }
    if (type === 'Leistung' && GlobalHelper.isNullOrUndefined(positionRequest.Gewerk)) {
      return true;
    }
    if (type === 'Leistung' && !positionRequest.useFilterGewerk) {
      return true;
    }
    return false;
  }
}
