import { Injectable } from '@angular/core';
import {
  AuthorizationService,
  DialogService,
  FeatureNames,
  GlobalHelper,
  RestService,
  UserInfo,
} from '@handwerk-pwa/shared';
import { MediumDataTypes, newPageCloseButton } from '../../config';
import { HWAddress, HWFile, HWObjectAddress, Medien, SyncObject } from '../../entities';
import { DocumentHelper } from '../../helper';
import { AddressService } from '../dataServices/address.service';
import { DataService } from '../dataServices/data.service';
import { ObjectaddressService } from '../dataServices/objectAddress.service';
import { GlobalSettingService } from './globalSetting.service';
@Injectable({
  providedIn: 'root',
})
export class DocumentService implements DataService {
  serviceName = 'DocumentService';
  constructor(
    private globalSettingService: GlobalSettingService,
    private restService: RestService,
    private dialogService: DialogService,
    private addressService: AddressService,
    private objectAddressService: ObjectaddressService,
    private authorizationService: AuthorizationService,
  ) {}

  getUnpushedHWFiles(allAddresses: HWAddress[], allObjectAddresses: HWObjectAddress[]): HWFile[] {
    const filesObjectAddresses = allObjectAddresses?.flatMap(address => address.Files);
    const fileAddresses = allAddresses?.flatMap(address => address.Files);
    const files = fileAddresses.concat(filesObjectAddresses);
    const unpushedDocuments = files?.filter(hwFile => hwFile.Send === false);
    return unpushedDocuments;
  }

  checkFileExists(fileArray: Array<Medien | HWFile>, parsedFile: HWFile | Medien): boolean {
    if (fileArray?.some(currentObject => currentObject.getOriginalName() === parsedFile.getOriginalName())) return true;
    return false;
  }

  isDocumentPdf(inputFile: HWFile | Medien): boolean {
    if (inputFile instanceof Medien) return inputFile.Datatype === MediumDataTypes.PDF;
    const documentName = inputFile.Name;
    const nameLength = documentName.length;
    if (documentName.substring(nameLength - 3, nameLength).toLowerCase() === 'pdf') return true;
    return false;
  }

  openText(documentUrl: string): void {
    let base64Text = documentUrl.replace('data:text/plain;base64,', '');
    base64Text = base64Text.replace('data:text/csv;base64,', '');

    // Get the text in multiple lines
    const splitText = GlobalHelper.convertFromBase64(base64Text).split('\n');
    let htmlContent = '';
    for (const text of splitText) htmlContent += `<div >${text}</div>`;

    //Open the text in a new Tab
    const tab = window.open('about:blank', '_blank');
    tab.document.write(newPageCloseButton + htmlContent);
    tab.document.close();
  }

  isDocumentText(inputFile: HWFile | Medien): boolean {
    if (inputFile instanceof Medien) return inputFile.Datatype === MediumDataTypes.Text;

    const documentName = inputFile.Name;
    const nameLength = documentName.length;
    if (documentName.substring(nameLength - 3, nameLength).toLowerCase() === 'txt') return true;
    return false;
  }

  async getHWFileDataUrlFromWebService(file: HWFile): Promise<string> {
    const userInfo = await this.globalSettingService.getUserInfo();
    void this.dialogService.openLoadingDialog('Hole Daten', 'Hole Datei vom Server...');
    const targetUrl = `addressImages/mandant/${userInfo.mandant}/username/${userInfo.user}/KU_NR/${userInfo.monteur}/bildname/${file.Name}`;
    const returnValue = await this.restService.returnData<string | Medien>(targetUrl, file.Kundennummer);
    const base64String = returnValue as string;
    const documentUrl = await DocumentHelper.readBlobAsDataUrlAsync(
      DocumentHelper.base64toBlob(base64String, DocumentHelper.getContentType(base64String)),
    );
    this.dialogService.closeLoadingDialog();
    return documentUrl;
  }

  /**
   * @description Sends media and documents to the web service
   * @returns FileArray with the correctly states
   */
  async sendDocumentsToWebService(userInfo: UserInfo, documents: HWFile[]): Promise<HWFile[]> {
    const filteredDocuments = documents.filter(doc => doc.Send === false && doc.Data);
    let silent = false;
    for (const file of filteredDocuments) {
      const oldTransfer = await this.transferDocuments(userInfo, file, silent);
      file.Send = oldTransfer?.Success === true;
      silent = true;
    }
    return filteredDocuments;
  }

  async pushToWebService(userInfo: UserInfo): Promise<void> {
    const allAddresses = await this.addressService.getAll();
    const allObjectAddresses = await this.objectAddressService.getAll();
    const documents = this.getUnpushedHWFiles(allAddresses, allObjectAddresses);
    await this.sendDocumentsToWebService(userInfo, documents);
    if (!this.authorizationService.current.getValue().featureCheck(FeatureNames.mediaTable2).available) {
      await this.addressService.updateDocumentsInAddressLocally(documents);
      await this.objectAddressService.updateDocumentsInAddressLocally(documents);
    }
  }

  async getFromWebService(): Promise<void> {}

  async synchronize(userInfo: UserInfo): Promise<void> {
    await this.pushToWebService(userInfo);
  }

  getRequiredObjects(): SyncObject[] {
    return [];
  }

  private removeHeaderFromDataUrl(pictureData: string): string {
    if (pictureData.startsWith('data:image/jpeg;base64,')) return pictureData.replace('data:image/jpeg;base64,', '');
    if (pictureData.startsWith('data:image/png;base64,')) return pictureData.replace('data:image/png;base64,', '');
    if (pictureData.startsWith('data:image/bmp;base64,')) return pictureData.replace('data:image/bmp;base64,', '');
    if (pictureData.startsWith('data:application/pdf,')) return pictureData.replace('data:application/pdf,', '');
    return pictureData;
  }

  /**
   * @description Sends image or file to the web service
   */
  private async transferDocuments(
    userInfo: UserInfo,
    file: HWFile,
    silent: boolean,
  ): Promise<{ Message: string; Success: boolean }> {
    const dataWithoutHeader = this.removeHeaderFromDataUrl(file.Data);
    const consecutiveNumber = file.LfdNr;
    const customerNumber = file.Kundennummer;
    const fileName = file.Name;
    const employee = userInfo.mandant;
    const username = userInfo.monteur;
    // Laufende Nummer -> Objekt Adresse
    const targetUrl = consecutiveNumber ? 'setObjAddressPic' : 'setAddressImage';
    return await this.restService.returnData<{ Message: string; Success: boolean }>(
      targetUrl,
      {
        Mandant: employee,
        User: username,
        KU_NR: consecutiveNumber ? customerNumber + ';' + consecutiveNumber : customerNumber,
        Filename: fileName,
        Filedata: dataWithoutHeader,
      },
      silent,
    );
  }
}
