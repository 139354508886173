import { HWRepairOrder } from '../../../../../../../apps/handwerkPWA/src/app/entities';
import { BaseAuftrag } from '../../../../../../../apps/handwerkPWA/src/app/interfaces';
import { GlobalHelper } from '../../../helper';
import { Briefpapier } from '../Briefpapier';
import { EmailConnectionInfo } from '../EmailConnectionInfo';
import { Textvorlage } from '../Textvorlage';
import { KmAllowanceSettings } from './KmAllowanceSettings';
import { PDFSettings } from './PDFSettings';
import { PDFSettingsServiceAuftrag } from './PDFSettingsServiceAuftrag';

export class Setting {
  PDFSettings: PDFSettings;
  /**Ist aktuell in der Antwort des Webservice ein String der Nutzdaten, wird aber als Objekt des Briefpapiers verwendet */
  briefPapier: Briefpapier;
  briefPapiername: string;
  textVorlagen: Textvorlage[] = [];
  emailTextvorlage: Textvorlage = null;
  emailConnectionInfo: EmailConnectionInfo;
  PDFSettingsServiceAuftrag: PDFSettingsServiceAuftrag;
  kmAllowanceSettings: KmAllowanceSettings;

  constructor(data: Setting) {
    const pdfSettingData = !GlobalHelper.isEmptyGUIData(data?.PDFSettings)
      ? (JSON.parse(this.toString(data.PDFSettings)) as PDFSettings)
      : null;
    this.PDFSettings = new PDFSettings(pdfSettingData);

    const pdfSettingDataServiceAuftrag = !GlobalHelper.isEmptyGUIData(data?.PDFSettingsServiceAuftrag)
      ? (JSON.parse(this.toString(data.PDFSettingsServiceAuftrag)) as PDFSettingsServiceAuftrag)
      : null;
    this.PDFSettingsServiceAuftrag = new PDFSettingsServiceAuftrag(pdfSettingDataServiceAuftrag);

    const briefPapier = !GlobalHelper.isEmptyGUIData(data?.briefPapier)
      ? new Briefpapier(data.briefPapiername, this.toString(data.briefPapier))
      : null;
    this.briefPapier = briefPapier;

    const textVorlagen = !GlobalHelper.isEmptyGUIData(data?.textVorlagen)
      ? (JSON.parse(this.toString(data.textVorlagen)) as Textvorlage[])
      : [];
    this.textVorlagen = textVorlagen;

    const emailTextVorlage = !GlobalHelper.isEmptyGUIData(data?.emailTextvorlage)
      ? new Textvorlage(JSON.parse(this.toString(data.emailTextvorlage)) as Textvorlage)
      : null;
    this.emailTextvorlage = emailTextVorlage;

    const emailConnectionInfo = !GlobalHelper.isEmptyGUIData(data?.emailConnectionInfo)
      ? (JSON.parse(this.toString(data.emailConnectionInfo)) as EmailConnectionInfo)
      : null;
    this.emailConnectionInfo = emailConnectionInfo;

    const kmAllowanceSettings = !GlobalHelper.isEmptyGUIData(data?.kmAllowanceSettings)
      ? (JSON.parse(this.toString(data.kmAllowanceSettings)) as KmAllowanceSettings)
      : null;
    this.kmAllowanceSettings = kmAllowanceSettings;
  }

  static getSettingFromOrderType(order: BaseAuftrag, setting: Setting): PDFSettings {
    const orderType = order instanceof HWRepairOrder ? 'HWRepairOrder' : 'ServiceAuftrag';
    switch (orderType) {
      case 'HWRepairOrder':
        return setting.PDFSettings;
      case 'ServiceAuftrag':
        return setting.PDFSettingsServiceAuftrag;
    }
  }

  private toString(obj: any): string {
    return obj as unknown as string;
  }
}
