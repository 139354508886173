import { Injectable } from '@angular/core';
import { RestService } from '@handwerk-pwa/shared';
import { PWAErrorMessageAndStackTrace } from '../../entities';

@Injectable({
  providedIn: 'root',
})
export class LogErrorToWebserviceService {
  constructor(private restService: RestService) {}

  public async logErrorToWebService(error: PWAErrorMessageAndStackTrace): Promise<void> {
    await this.restService.returnData('LogPWAError', error, false);
  }
}
