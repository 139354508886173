import { GlobalHelper } from '@handwerk-pwa/shared';
import { SyncObject } from '../models/SyncObject';
import { IndexedDBTypes } from './dbType';

export class HWUmsatz extends IndexedDBTypes.DbType implements SyncObject {
  @IndexedDBTypes.KlassenName('HWUmsatz') KlassenName: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: number;
  @IndexedDBTypes.IndexField('string') Kundennummer: string;
  @IndexedDBTypes.DataField('string') jahr: string;
  @IndexedDBTypes.DataField('string') monat: string;
  @IndexedDBTypes.DataField('string') netto: string;
  @IndexedDBTypes.DataField('Data') addedToIdb: Date;

  //Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: HWUmsatz) {
    super();
    Object.assign(this, data);
    this.addTimeStampIfNotExisting();
  }

  static toString(): string {
    return 'HWUmsatz';
  }

  /**@description Wenn noch keiner vorhanden ist, wird der timestamp hinzugefügt */
  addTimeStampIfNotExisting(): void {
    if (!GlobalHelper.isNullOrUndefined(this.addedToIdb)) {
      return;
    }
    const now = new Date();
    this.addedToIdb = now;
  }
}
