import { Injectable } from '@angular/core';
import { DialogService, GlobalHelper, RestService } from '@handwerk-pwa/shared';
import { HWContactPerson } from '../../entities';
import { ControllerService, GlobalSettingService } from '../../services';
import { ConnectionDialogues, ConnectionService } from '../globalServices/connection.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ContactPersonService {
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private dialogService: DialogService,
    private baseService: BaseService,
    private globalSettingService: GlobalSettingService,
    private connectionService: ConnectionService,
  ) {}

  /**
   * @description Holt alle Adressen aus der IDB mit bestimmten Suchkriterien
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async getAllBy(selector: string, value: string, trySync: boolean = false): Promise<HWContactPerson[]> {
    const currentContactPersons = await this.baseService.getAllBy(HWContactPerson, selector, value);

    if (trySync) {
      const isOnline = await this.connectionService.checkOnline(ConnectionDialogues.GetData);
      if (isOnline) {
        await this.sync();
        return await this.baseService.getAllBy(HWContactPerson, selector, value);
      }
    }

    return currentContactPersons;
  }

  /*******************************************************************************
   *
   *
   *
   * PRIVATE FUNCTIONS
   *
   *
   *
   ******************************************************************************** */

  /**@description Schreibt den Ansprechpartner in die IDB */
  private async writeContactPersonToIDB(contactPerson: HWContactPerson[]): Promise<void> {
    await this.controllerService.clearStore('HWContactPerson');
    await this.controllerService.setData('HWContactPerson', contactPerson);
  }

  private async sync(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const geaendert = '01.01.1900';
    void this.dialogService.openLoadingDialog('Synchronisation', '...hole Ansprechpartner...');
    const contactPersonData = await this.restService.returnData<HWContactPerson[]>(
      'Ansprech/mandant/' + userInfo.mandant + '/username/' + userInfo.monteur + '/GEAENDERT/' + geaendert,
    );
    if (GlobalHelper.isNullOrUndefinedOrEmptyArray(contactPersonData)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    const contactPersons: HWContactPerson[] = this.baseService.rawDataArrayToObjectsArray(
      HWContactPerson,
      contactPersonData,
    );
    await this.writeContactPersonToIDB(contactPersons);
    this.dialogService.closeLoadingDialog();
  }
}
