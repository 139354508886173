import { Component, Input, OnInit } from '@angular/core';
import { RoutingService } from '@handwerk-pwa/shared';
import { Aufmass, HWAddress, Projekt } from '../../../../entities';
import { AddressService, ProjectService } from '../../../../services';

@Component({
  selector: 'app-measurement-card',
  templateUrl: './measurement-card.component.html',
  styleUrls: ['./measurement-card.component.scss'],
})
export class MeasurementCardComponent implements OnInit {
  @Input() measurement: Aufmass;
  customer: HWAddress;
  project: Projekt;

  constructor(
    private routingService: RoutingService,
    private addressService: AddressService,
    private projectService: ProjectService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.customer = await this.addressService.findOneBy('KU_NR', this.measurement.Kunde);
    if (this.measurement?.ProjName)
      this.project = await this.projectService.findOneBy('Name', this.measurement.ProjName);
  }

  async goToMeasurementDetail(): Promise<void> {
    await this.routingService.navigateTo(`aufmass/${this.measurement.Uuid}/raumbuch`);
  }
}
