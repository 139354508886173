import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Format } from 'devextreme/common';
import { RoutingService } from '../../../services';

@Component({
  selector: 'app-bss-numberbox',
  templateUrl: './bss-numberbox.component.html',
  styleUrls: ['./bss-numberbox.component.scss'],
})
export class BssNumberboxComponent {
  @Input() format: string | { type: Format; precision: number };
  @Input() max: number;
  @Input() min: number;
  @Input() labelText;
  @Input() buttonIcon;
  @Input() value: number;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Output() valueChange = new EventEmitter<number>();
  @Output() buttonClick = new EventEmitter<boolean>();

  constructor(private routingService: RoutingService) {}

  valueChanged(value: number): void {
    this.routingService.dataChanged.next(true);
    this.valueChange.emit(value);
  }

  btnClicked(): void {
    this.buttonClick.emit(true);
  }
}
