import { GlobalHelper, TimeHelper, UserInfo } from '@handwerk-pwa/shared';
import * as uuid from 'uuid';
import { SyncObject } from '../models/SyncObject';
import { HWRepairOrder } from './HWRepairOrder';
import { IndexedDBTypes } from './dbType';

export enum HWMessageType {
  Incoming = 'E',
  Outgoing = 'A',
}

export class HWNachricht extends IndexedDBTypes.DbType implements SyncObject {
  @IndexedDBTypes.KlassenName('HWNachricht') KlassenName: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: number;
  /**leerer String signalisiert Webservice eine neue Nachricht */
  @IndexedDBTypes.IndexField('string') ID = '';
  @IndexedDBTypes.DataField('string') AuftragsNr: string;
  @IndexedDBTypes.DataField('string') Betreff: string;
  @IndexedDBTypes.DataField('string') MonteurNr: string;
  @IndexedDBTypes.DataField('string') Text: string;
  @IndexedDBTypes.DataField('string') SendDatum: string;
  @IndexedDBTypes.DataField('string') SendZeit: string;
  @IndexedDBTypes.DataField('string') SendZeitDate;
  @IndexedDBTypes.DataField('string') Referenz: string;
  @IndexedDBTypes.DataField('boolean') Gelesen: boolean;
  /**TYP E=Eingehend im Handwerk, A=Ausgehend aus Handwerk */
  @IndexedDBTypes.DataField('string') TYP = HWMessageType.Incoming;
  @IndexedDBTypes.DataField('string') Guid: string = uuid.v4();
  @IndexedDBTypes.DataField('boolean') offenInView = false;
  @IndexedDBTypes.DataField('HWRepairOrder') Auftrag: HWRepairOrder;
  GeraeteID: string;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: HWNachricht) {
    super();
    Object.assign(this, data);
    if (GlobalHelper.isNullOrUndefined(this.SendZeit)) {
      this.setDatumUhrzeit();
    }
  }
  static toString(): string {
    return 'HWNachricht';
  }

  public setGeraeteId(userInfo: UserInfo): void {
    const device = userInfo.Device;
    this.GeraeteID = device?.Id?.toString();
  }

  /**@description Setzt SendeDatum und Uhrzeit wenn keines Vorhanden ist */
  setDatumUhrzeit(): void {
    const date = new Date();
    const dbDate = TimeHelper.dateToDatabaseDate(date, true, true);

    const dbDateSplit = dbDate.split(' ');
    this.SendDatum = dbDateSplit[0];
    this.SendZeit = dbDateSplit[1];
  }
}
