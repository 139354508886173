import { GlobalHelper } from '../../../../helper';
import { RightCategory } from './RightCategory';

export class InAppChangeRights extends RightCategory {
  autoWage = true;
  rounding = true;
  pdfPreview = true;
  mailFromOrder = true;
  showPrices = true;
  printPrices = true;

  constructor(inAppChangeRights: InAppChangeRights) {
    super();
    GlobalHelper.assignIfPropertyExists(this, inAppChangeRights);
  }
}
