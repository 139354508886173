import { Shape } from '../entities';

export const Shapes: Shape[] = [
  {
    name: 'Quadrat',
    iconPath: 'assets/icons/square.jpg',
    coordinatePointRoute: [
      { xCoordinate: 1, yCoordinate: 1 },
      { xCoordinate: 1, yCoordinate: 3 },
      { xCoordinate: 3, yCoordinate: 3 },
      { xCoordinate: 3, yCoordinate: 1 },
      { xCoordinate: 1, yCoordinate: 1 },
    ],
  },
  {
    name: 'Rechteck hoch',
    iconPath: 'assets/icons/rectangleHigh.png',
    coordinatePointRoute: [
      { xCoordinate: 1, yCoordinate: 1 },
      { xCoordinate: 1, yCoordinate: 4 },
      { xCoordinate: 3, yCoordinate: 4 },
      { xCoordinate: 3, yCoordinate: 1 },
      { xCoordinate: 1, yCoordinate: 1 },
    ],
  },
  {
    name: 'Rechteck breit',
    iconPath: 'assets/icons/rectangle.png',
    coordinatePointRoute: [
      { xCoordinate: 0.5, yCoordinate: 1 },
      { xCoordinate: 0.5, yCoordinate: 2 },
      { xCoordinate: 3.5, yCoordinate: 2 },
      { xCoordinate: 3.5, yCoordinate: 1 },
      { xCoordinate: 0.5, yCoordinate: 1 },
    ],
  },
  {
    name: 'L-Form',
    iconPath: 'assets/icons/lIcon.png',
    coordinatePointRoute: [
      { xCoordinate: 1, yCoordinate: 1 },
      { xCoordinate: 1, yCoordinate: 4.5 },
      { xCoordinate: 3, yCoordinate: 4.5 },
      { xCoordinate: 3, yCoordinate: 3.5 },
      { xCoordinate: 2, yCoordinate: 3.5 },
      { xCoordinate: 2, yCoordinate: 1 },
      { xCoordinate: 1, yCoordinate: 1 },
    ],
  },
  {
    name: 'L-Form gespiegelt',
    iconPath: 'assets/icons/lIconMirrored.png',
    coordinatePointRoute: [
      { xCoordinate: 2, yCoordinate: 1 },
      { xCoordinate: 2, yCoordinate: 3.5 },
      { xCoordinate: 1, yCoordinate: 3.5 },
      { xCoordinate: 1, yCoordinate: 4.5 },
      { xCoordinate: 3, yCoordinate: 4.5 },
      { xCoordinate: 3, yCoordinate: 1 },
      { xCoordinate: 2, yCoordinate: 1 },
    ],
  },
  {
    name: 'L-Form oben',
    iconPath: 'assets/icons/lIconTop.png',
    coordinatePointRoute: [
      { xCoordinate: 1, yCoordinate: 1 },
      { xCoordinate: 1, yCoordinate: 4.5 },
      { xCoordinate: 2, yCoordinate: 4.5 },
      { xCoordinate: 2, yCoordinate: 2 },
      { xCoordinate: 3, yCoordinate: 2 },
      { xCoordinate: 3, yCoordinate: 1 },
      { xCoordinate: 1, yCoordinate: 1 },
    ],
  },
  {
    name: 'L-Form oben gespiegelt',
    iconPath: 'assets/icons/lIconTopMirrored.png',
    coordinatePointRoute: [
      { xCoordinate: 1, yCoordinate: 1 },
      { xCoordinate: 1, yCoordinate: 2 },
      { xCoordinate: 2, yCoordinate: 2 },
      { xCoordinate: 2, yCoordinate: 4.5 },
      { xCoordinate: 3, yCoordinate: 4.5 },
      { xCoordinate: 3, yCoordinate: 1 },
      { xCoordinate: 1, yCoordinate: 1 },
    ],
  },
];
