import { Component, Input } from '@angular/core';
import { RoutingService } from '@handwerk-pwa/shared';
import { HWBeleg } from '../../../../entities';

@Component({
  selector: 'app-supporting-document-card',
  templateUrl: './supporting-document-card.component.html',
  styleUrls: ['./supporting-document-card.component.scss'],
})
export class SupportingDocumentCardComponent {
  @Input() supportingDocument: HWBeleg;

  constructor(private routingService: RoutingService) {}

  async goToSupportingDocsPositions(): Promise<void> {
    await this.routingService.navigateTo('beleg/' + this.supportingDocument.name);
  }
}
