import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DeviceHelper,
  DialogService,
  EmailConnectionInfo,
  MailService,
  PositionSettings,
  Right,
  RightsService,
  RoutingService,
  Setting,
  UserInfo,
  secureSocketOptionDropdown,
} from '@handwerk-pwa/shared';
import version from '../../../../../../deployment/version/version.json';
import { GlobalSettings, SliderNames } from '../../config';
import { AppOnlySettings, HWMonteur, MultiViewSite, Positionrequest } from '../../entities';
import {
  BackgroundService,
  GlobalSettingService,
  MeasurementService,
  MonteurService,
  RepairOrderItemService,
  SliderService,
  UpdatepwaService,
} from '../../services';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  version = version;
  settings: Setting;
  rights: Right;
  mandant: string;
  emailConnectionInfo = new EmailConnectionInfo();
  year = new Date().getFullYear().toString();
  showPricesChangeRight: boolean;
  pwaInstalled = false;
  userInfo: UserInfo;
  positionSettings: PositionSettings;
  canAskOfflineArtikel: boolean;
  canAskOfflineLeistung: boolean;
  hiddenCounter = 0;
  viewFinished = false;
  currentMonteur: HWMonteur;
  appOnlySettings: AppOnlySettings;
  showCheckBox = false;
  possibleSliderSettings: Array<{ name: SliderNames; value: boolean }> = [];
  hasMeasurement: boolean;
  errorDialogsEnabled = false;
  multiViewSites: MultiViewSite[];
  secureSocketOptionDropdown = secureSocketOptionDropdown;
  selectedSocketOption: { name: string; value: number };
  openInvalidEmailDialog: () => Promise<void>;

  constructor(
    private rightsService: RightsService,
    private globalSettingService: GlobalSettingService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private updatePwaService: UpdatepwaService,
    private repairOrderItemService: RepairOrderItemService,
    private monteurService: MonteurService,
    private backgroundService: BackgroundService,
    private mailService: MailService,
    private sliderService: SliderService,
    private measurementService: MeasurementService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.openInvalidEmailDialog = this.mailService.openInvalidEmailDialog;
    this.emailConnectionInfo = (await this.mailService.getEmailConnectionInfo()) || this.emailConnectionInfo;
    this.settings = await this.globalSettingService.getEntity<Setting>(GlobalSettings.Settings);

    if (this.settings?.emailConnectionInfo) this.settings.emailConnectionInfo = this.emailConnectionInfo;

    this.selectedSocketOption = this.secureSocketOptionDropdown.find(
      dropDownOptions => dropDownOptions.value === this.emailConnectionInfo.smtpSecurity,
    );

    this.rights = this.rightsService.getCurrentRight();
    this.appOnlySettings = await this.globalSettingService.getEntity<AppOnlySettings>(GlobalSettings.AppOnlySettings);
    this.positionSettings = this.rights.positionSettings;
    this.showPricesChangeRight = this.rights.inAppChangeRights.showPrices;
    this.userInfo = await this.globalSettingService.getUserInfo();
    const positionRequest = new Positionrequest(this.userInfo.mandant, this.userInfo.monteur);
    positionRequest.addPositionSettingData(this.positionSettings);
    this.canAskOfflineArtikel = this.repairOrderItemService.canAskOffline(positionRequest, 'Artikel');
    this.canAskOfflineLeistung = this.repairOrderItemService.canAskOffline(positionRequest, 'Leistung');
    this.mandant = this.userInfo.mandant;
    this.pwaInstalled =
      this.updatePwaService.isInstalled ||
      DeviceHelper.isUserAgentIOS() ||
      window.matchMedia('(display-mode: standalone)').matches;
    await this.loadCurrentSignedInMonteur();
    this.errorDialogsEnabled = await this.globalSettingService.getEntity<boolean>(GlobalSettings.ErrorLoggingEnabled);
    this.multiViewSites = await this.sliderService.getDataFromIndexDB();
    this.possibleSliderSettings = this.multiViewSites.map(e => {
      return { name: e.cardName, value: !e.userDisabled };
    });
    this.hasMeasurement =
      this.rights.employeeRights.measurementRights.enableModule && this.rights.employeeRights.showObjektadressen;
    this.viewFinished = true;
  }

  async dragEnd(): Promise<void> {
    // Change index to the new SliderOrder
    for (let i = 0; i < this.multiViewSites.length; i++) {
      this.multiViewSites[i].index = i + 1;
    }
    await this.sliderService.saveToIndexDB(this.multiViewSites);
  }

  async installPwa(): Promise<void> {
    await this.updatePwaService.triggerInstall();
  }

  async getOfflinePosition(art: 'Lohn' | 'Leistung' | 'Artikel'): Promise<void> {
    await this.repairOrderItemService.getOfflinePositionsFromWebService(this.rights, this.userInfo, art);
  }

  async getFormulasFromWebService(): Promise<void> {
    await this.measurementService.getFormulasFromWebService(this.userInfo, false);
  }

  async ngOnDestroy(): Promise<void> {
    const nextRoute = this.routingService.getNextPlannedRoute();
    const isSyncing = nextRoute.startsWith('/reload/') === true;
    await this.globalSettingService.setEntity(this.errorDialogsEnabled, GlobalSettings.ErrorLoggingEnabled);
    await this.globalSettingService.setEntity(this.appOnlySettings, GlobalSettings.AppOnlySettings);
    if (isSyncing) return;
    await this.rightsService.saveRightLocally(this.rights);
    await this.globalSettingService.setEntity(this.settings, GlobalSettings.Settings);
    await this.mailService.setEmailConnectionInfo(this.settings.emailConnectionInfo, false);
  }

  async saveEmailConnectionInfo(): Promise<void> {
    // Validation an dieser Stelle führt dazu, dass man die Daten nicht löschen kann. Laut CS keine Validation nötig...
    await this.mailService.setEmailConnectionInfo(this.emailConnectionInfo);
    this.emailConnectionInfo = await this.mailService.getEmailConnectionInfo();
    this.settings.emailConnectionInfo = this.emailConnectionInfo;
    this.dialogService.openInformDialog('Erfolg', 'Ihre Zugangsdaten wurden erfolgreich gespeichert', 'Ok');
  }

  async getEmailConnectionInfoFromWebService(): Promise<void> {
    await this.mailService.overrideLocalWithWebService(this.userInfo, true);
    this.emailConnectionInfo = (await this.mailService.getEmailConnectionInfo()) || this.emailConnectionInfo;
  }

  async manualUpdateTrigger(): Promise<void> {
    this.hiddenCounter++;
    if (this.hiddenCounter === 10) {
      this.hiddenCounter = 0;
      await this.updatePwaService.checkManuallyForUpdates();
    }
  }

  /**@description Nach eingabe eines neuen Wertes des Zeitintervalls wird dieses mit dem neuen Wert neugestartet */
  public restartBackgroundSyncTimer(newInterval: number): void {
    this.backgroundService.stopBackgroundSyncInterval();
    this.backgroundService.startBackgroundSyncInterval(newInterval);
  }

  async confirmSliders(output: { value: boolean; name: string }[]): Promise<void> {
    for (const outputValue of output) {
      const view = this.multiViewSites.find(filterView => filterView.cardName === outputValue.name);
      view.userDisabled = !outputValue.value;
    }
    await this.sliderService.saveToIndexDB(this.multiViewSites);
    this.showCheckBox = false;
  }

  /**@description Lädt den aktuell eingeloggten Monteur */
  private async loadCurrentSignedInMonteur(): Promise<void> {
    const signedIn = await this.monteurService.userSignedIn();

    if (signedIn) this.currentMonteur = await this.monteurService.getMonteurFromIDB();
  }
}
