<div class="content-block bottom-padding" *ngIf="viewFinished">
  <div class="form-block">
    <h2>Adresse bearbeiten</h2>

    <div *ngIf="address?.LIEFERSPERRE == true" class="bss-alert bss-alert-danger">Liefersperre hinterlegt</div>
    <div *ngIf="!addressEditRight" class="bss-alert bss-alert-warning">Editierrecht nicht vorhanden</div>

    <div *ngIf="address" class="dx-card responsive-paddings">
      <fieldset [disabled]="!addressEditRight">
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">Persönliche Daten</div>
          <div class="fieldbox">
            <app-bss-plain-text-box
              [labelText]="'Kundennummer'"
              [value]="address.KU_NR | replace: '-1' : 'Neu'"
            ></app-bss-plain-text-box>
            <app-bss-select-box
              *ngIf="isNewAddress"
              [labelText]="'Adressart'"
              [items]="addressTypes"
              [disabled]=""
              [placeholder]="'Adressart'"
              [value]="selectedType"
              (selectedValue)="selectedNextType($event, address)"
            ></app-bss-select-box>
            <app-bss-plain-text-box
              *ngIf="!isNewAddress"
              [labelText]="'Adressart'"
              [value]="addressFullType"
            ></app-bss-plain-text-box>
            <app-bss-textbox
              [labelText]="'Suchbegriff'"
              [value]="address.SUCH"
              (valueChange)="address.SUCH = $event.toUpperCase()"
            ></app-bss-textbox>
            <app-bss-textbox [labelText]="'Firma / Titel'" [(value)]="address.FA_TITEL"></app-bss-textbox>
            <app-bss-textbox [labelText]="'Name'" [(value)]="address.NAME"></app-bss-textbox>
            <app-bss-textbox [labelText]="'Namenszusatz'" [(value)]="address.NAME2"></app-bss-textbox>
            <app-bss-datetime-box
              [labelText]="'Geburtstag'"
              [value]="address.GEBURTSTAG"
              [disabled]="!addressEditRight"
              (outStringValue)="address.GEBURTSTAG = $event"
            ></app-bss-datetime-box>
          </div>
        </div>
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">Kontaktdaten</div>
          <div class="fieldbox">
            <app-bss-textbox
              [labelText]="'Telefon'"
              [buttonIcon]="'tel'"
              [(value)]="address.TEL"
              (buttonClick)="callPhone(address.TEL)"
              [buttonEnabled]="address?.TEL !== ''"
            ></app-bss-textbox>
            <app-bss-textbox
              [labelText]="'Mobil'"
              [buttonIcon]="'tel'"
              [(value)]="address.FUNK_PRIV"
              (buttonClick)="callPhone(address.FUNK_PRIV)"
              [buttonEnabled]="address?.FUNK_PRIV !== ''"
            ></app-bss-textbox>
            <app-bss-textbox
              [labelText]="'Homepage'"
              [buttonIcon]="'globe'"
              (buttonClick)="goToHomepage(address.WWW)"
              [(value)]="address.WWW"
              [buttonEnabled]="address?.WWW !== ''"
            ></app-bss-textbox>
            <app-bss-textbox
              [labelText]="'E-Mail'"
              [buttonIcon]="'message'"
              [(value)]="address.GLOBEMAIL"
              (buttonClick)="mailTo(address.GLOBEMAIL)"
              [buttonEnabled]="address?.GLOBEMAIL !== ''"
            ></app-bss-textbox>
          </div>
        </div>
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">Adresse</div>
          <div class="fieldbox">
            <app-bss-textbox [labelText]="'Ansprechpartner'" [(value)]="address.ANSPRECH"></app-bss-textbox>
            <app-bss-textbox
              [labelText]="'Straße'"
              [buttonIcon]="'map'"
              [(value)]="address.STRASSE"
              [buttonEnabled]="address?.STRASSE !== '' && address?.ORT !== ''"
              (buttonClick)="openMaps(address)"
            ></app-bss-textbox>
            <app-bss-textbox [labelText]="'Postleitzahl'" [(value)]="address.PLZ" [maxLength]="5"></app-bss-textbox>
            <app-bss-textbox [labelText]="'Ort'" [(value)]="address.ORT"></app-bss-textbox>
            <app-bss-select-box
              [labelText]="'Land'"
              [items]="countryCodes"
              [value]="address.LAND"
              [disabled]="!addressEditRight"
              (selectedValue)="address.LAND = $event"
            ></app-bss-select-box>
          </div>
        </div>
        <div class="dx-fieldset" *ngIf="address && address.KU_NR != '-1'">
          <div class="dx-fieldset-header">Weitere Informationen</div>

          <app-bss-toggle-textarea [buttonText]="'Bemerkungen'" [(value)]="address.BEMERKUNG"></app-bss-toggle-textarea>
          <app-bss-button-link
            [showHasContent]="address.Zusatz?.length != 0"
            [title]="'Zusatz'"
            [customIcon]="'assets/icons/dreieck.svg'"
            (click)="showAdditionalInfo = !showAdditionalInfo"
            [customIconClass]="showAdditionalInfo ? 'rotated180 dreieck' : ''"
          ></app-bss-button-link>
          <div *ngIf="showAdditionalInfo" style="margin-bottom: 10px">
            <div *ngFor="let zusatz of address.Zusatz; let i = index">
              <app-bss-plain-text-box
                *ngIf="zusatz != ''"
                [labelText]="additionalDesignations.AdressenZusatzBezeichnung[i]"
                [value]="address.Zusatz[i]"
              ></app-bss-plain-text-box>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="linkBox" *ngIf="!isNewAddress">
        <app-bss-button-link
          *ngIf="mediaButtonEnabled"
          [title]="'Medien'"
          [path]="'medien/Address/' + address.Guid"
          [displayCount]="currentFilesAmount"
        ></app-bss-button-link>
        <app-bss-button-link
          [title]="'Termine / Aufgaben'"
          [path]="'adresse/' + address.Guid + '/termine'"
          [displayCount]="appointmentCount"
        ></app-bss-button-link>
        <app-bss-button-link
          *ngIf="(rights | async).employeeRights.addressRights.showObjectAddresses && address.ADRTYP === 'K'"
          [title]="'Objektadressen'"
          [path]="'adresse/' + address.Guid + '/objektadressen'"
          [displayCount]="objectAddressesCount"
        ></app-bss-button-link>
        <app-bss-button-link
          *ngIf="vacantPositionsRight"
          [title]="'Offene Posten'"
          [path]="'adresse/' + address.Guid + '/offene-posten'"
          [displayCount]="currentOpenItemsStartAmount"
        ></app-bss-button-link>
        <app-bss-button-link
          *ngIf="salesRight"
          [title]="'Umsatz'"
          [path]="'adresse/' + address.Guid + '/umsatz'"
          [displayCount]="currentTurnoverStartAmount"
        ></app-bss-button-link>
        <app-bss-button-link
          *ngIf="documentRight"
          [title]="'Belege'"
          [path]="'adresse/' + address.Guid + '/belege'"
          [displayCount]="currentReceiptsStartAmount"
        ></app-bss-button-link>
        <app-bss-button-link
          *ngIf="address.ADRTYP !== 'M'"
          [title]="'Ansprechpartner'"
          [path]="'adresse/' + address.Guid + '/ansprechpartner'"
          [displayCount]="currentContactPeopleStartAmount"
        ></app-bss-button-link>
        <app-bss-button-link
          [title]="'Kontakte'"
          [path]="'adresse/' + address.Guid + '/kontakte'"
          [displayCount]="currentContactsStartAmount"
        ></app-bss-button-link>
      </div>
    </div>
  </div>
</div>

<app-bss-speed-dial
  *ngIf="dataChanged | async"
  id="saveButton"
  icon="check"
  class="add-button"
  label="speichern"
  [index]="1"
  (Click)="save(createdForRepairOrder)"
></app-bss-speed-dial>

<app-bss-speed-dial
  *ngIf="
    isPremium &&
    !(dataChanged | async) &&
    !isNewAddress &&
    (rights | async).employeeRights.measurementRights.enableModule
  "
  icon="home"
  label="Neues Raumaufmaß"
  [index]="5"
  (Click)="navigateTo('aufmass/neu/' + address.Guid)"
></app-bss-speed-dial>
<app-bss-speed-dial
  *ngIf="
    isPremium &&
    !(dataChanged | async) &&
    !isNewAddress &&
    (rights | async).employeeRights.repairOrderRights.createRepairOrder
  "
  icon="columnproperties"
  class="add-button"
  label="Neuer Reparaturauftrag"
  [index]="4"
  (Click)="navigateTo('reparaturauftrag/neu/adresse/' + address.Guid)"
></app-bss-speed-dial>
<app-bss-speed-dial
  *ngIf="!(dataChanged | async) && !isNewAddress && (rights | async).employeeRights.appointmentRights.allowCreation"
  icon="clock"
  class="add-button"
  label="Neuer Termin"
  [index]="3"
  (Click)="newAppointment()"
></app-bss-speed-dial>
<app-bss-speed-dial
  *ngIf="
    (rights | async).employeeRights.addressRights.editObjectAddresses &&
    !(dataChanged | async) &&
    !isNewAddress &&
    address?.ADRTYP === 'K' &&
    (rights | async).employeeRights.addressRights.editObjectAddresses
  "
  icon="add"
  class="add-button"
  label="Neue Objektadresse"
  [index]="2"
  (Click)="navigateTo('objektadresse/neu/' + this.address.KU_NR)"
></app-bss-speed-dial>
<app-bss-speed-dial
  *ngIf="!(dataChanged | async) && !isNewAddress && mediaButtonEnabled && addressEditRight"
  icon="photo"
  class="add-button"
  label="Neues Medium"
  [index]="1"
  (Click)="newImages()"
></app-bss-speed-dial>
