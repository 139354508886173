import { Injectable } from '@angular/core';
import { GlobalHelper, RightsService } from '@handwerk-pwa/shared';
import {
  GlobalSettings,
  SliderNames,
  appointmentSearch,
  homeScreenAddressSearch,
  homeScreenServiceOrderSearch,
  maintenanceSystemSearch,
  measurementSearch,
  repairOrderSearch,
} from '../../config';
import { MultiViewSite } from '../../entities';
import { GlobalSettingService } from '../globalServices/globalSetting.service';

@Injectable({
  providedIn: 'root',
})
export class SliderService {
  constructor(
    private rightsService: RightsService,
    private globalSettingsService: GlobalSettingService,
  ) {}

  async saveToIndexDB(multiViewSites: MultiViewSite[]): Promise<void> {
    // delete the data in the multiViewSites so they don't take up space in IDB
    for (const multiViewSite of multiViewSites) multiViewSite.dataArray = undefined;
    await this.globalSettingsService.setEntity(multiViewSites, GlobalSettings.MultiViewSettings);
  }

  async getDataFromIndexDB(): Promise<MultiViewSite[]> {
    const dBSites = await this.globalSettingsService.getEntity<MultiViewSite[]>(GlobalSettings.MultiViewSettings);
    // If its not saved in IDB get the default value
    const multiViewSites = dBSites?.length > 0 ? dBSites : this.getDefaultSliders();
    return multiViewSites.sort((a, b) => GlobalHelper.compareFunction(a.index, b.index));
  }

  filterByRight(multiViewSite: MultiViewSite): boolean {
    const right = this.rightsService.getCurrentRight().employeeRights;
    const hasLicense = right.showObjektadressen;
    switch (multiViewSite.cardName) {
      case SliderNames.addresses:
        return true;
      case SliderNames.repairOrders:
        return hasLicense && right.repairOrderRights.enableModule;
      case SliderNames.appointments:
        return right.appointmentRights.enableModule;
      case SliderNames.maintenanceSystems:
        return hasLicense && right.maintenanceRights.enableMaintenanceSystems;
      case SliderNames.serviceOrders:
        return hasLicense && right.maintenanceRights.handleOrder;
      case SliderNames.roomMeasurements:
        return hasLicense && right.measurementRights.enableModule;
      case SliderNames.freeMeasurement:
        return hasLicense && right.measurementRights.enableModule;
    }
  }

  private getDefaultSliders(): MultiViewSite[] {
    /** Settings and rights */

    const multiViewItems: MultiViewSite[] = [];
    const addressView = new MultiViewSite(
      'Adressen',
      '/adressen',
      SliderNames.addresses,
      1,
      'Es sind keine Adressen vorhanden',
      // addresses and objectAddresses
      homeScreenAddressSearch,
      false,
    );
    multiViewItems.push(addressView);

    /** RepairOrders */
    const repairOrderView = new MultiViewSite(
      'Reparaturaufträge',
      '/reparaturauftraege',
      SliderNames.repairOrders,
      2,
      'Es sind keine Reparaturaufträge vorhanden',
      repairOrderSearch,
      false,
    );
    multiViewItems.push(repairOrderView);

    /** Appointments */
    const appointmentView = new MultiViewSite(
      // Title should be set in component using the site to avoid old date
      undefined,
      '/kalender',
      SliderNames.appointments,
      3,
      'Es sind keine Termine für heute vorhanden',
      appointmentSearch,
      false,
    );
    multiViewItems.push(appointmentView);

    /** Maintenance Systems */
    const maintenanceSystemView = new MultiViewSite(
      'W&S Anlagen',
      '/wartungsanlagen',
      SliderNames.maintenanceSystems,
      4,
      'Es sind keine W&S Anlagen vorhanden',
      maintenanceSystemSearch,
      false,
    );
    multiViewItems.push(maintenanceSystemView);

    /** ServiceOrders */
    const serviceOrderView = new MultiViewSite(
      'W&S Aufträge',
      '/wartungsauftraege',
      SliderNames.serviceOrders,
      5,
      'Es sind keine W&S Aufträge vorhanden',
      homeScreenServiceOrderSearch,
      false,
    );
    multiViewItems.push(serviceOrderView);

    /** Measurement */
    const roomMeasurementView = new MultiViewSite(
      'Raumaufmaße',
      '/aufmasse',
      SliderNames.roomMeasurements,
      6,
      'Es sind keine Raumaufmaße vorhanden',
      measurementSearch,
      false,
    );
    multiViewItems.push(roomMeasurementView);

    if (!this.globalSettingsService.isInProdEnvironment() && !this.globalSettingsService.isInQsEnvironment()) {
      const freeMeasurementView = new MultiViewSite(
        SliderNames.freeMeasurement,
        '/freeMeasurement',
        SliderNames.freeMeasurement,
        7,
        'Es sind keine freien Aufmaße vorhanden',
        measurementSearch,
        false,
      );
      multiViewItems.push(freeMeasurementView);
    }

    return multiViewItems;
  }
}
