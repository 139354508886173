import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService, GlobalHelper, UserInfo } from '@handwerk-pwa/shared';
import { TextBoxType } from 'devextreme/ui/text_box';
import { LoginService } from '../../../services';

@Component({
  selector: 'app-manuell-login',
  templateUrl: './manuell-login.component.html',
  styleUrls: ['./manuell-login.component.scss'],
})
export class ManuellLoginComponent {
  @Input() userInfo: UserInfo;
  @Input() showPopup = false;
  @Output() cancled = new EventEmitter<boolean>();
  passwordMode: TextBoxType = 'password';
  inputUrl = '';
  constructor(
    private loginService: LoginService,
    private dialogService: DialogService,
  ) {}

  async loginLastUser(userInfoData: UserInfo): Promise<void> {
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(userInfoData.pin)) {
      await this.dialogService.openWaitingConfirmDialog(
        'Authentifizierungsproblem!',
        'Das mobile Kennwort ist leer.',
        'OK',
      );
      return;
    }
    const userInfo = new UserInfo(userInfoData);
    await this.loginService.loginUser(userInfo);
  }

  togglePasswordShow(): void {
    if (this.passwordMode === 'password') {
      this.passwordMode = 'text';
    } else {
      this.passwordMode = 'password';
    }
  }

  closeMask(): void {
    this.cancled.emit(true);
  }

  /**
   * @description Baut einen Loginlink in die userInfo um
   * @param inputUrl Valide in der Form https://handwerk.myblueapp.de/Login/UUID/52c68246-deea-42ed-8b93-117378349c1f/Mandant/00900/Employeenumber/90001/Branding/tkbranding
   */
  urlToUserInfo(inputUrl: string): void {
    const loginSplit = inputUrl?.split('Login/UUID/');
    const usePart = loginSplit[1]; // 8efa5c0c-2611-450d-b4c9-06e26e0b2c90/Mandant/00900/Employeenumber/90001/Branding/tkbranding
    const useSplit = usePart?.split('/Mandant/'); // 8efa5c0c-2611-450d-b4c9-06e26e0b2c90 , 00900/Employeenumber/90001/Branding/tkbranding
    if (GlobalHelper.isNullOrUndefined(useSplit) || useSplit.length !== 2) return;
    const uuidValue = useSplit[0];
    const useSplit2 = useSplit[1]?.split('/Employeenumber/'); // 00900 , 90001/Branding/tkbranding
    const mandantValue = useSplit2[0];
    const useSplit3 = useSplit2[1]?.split('/Branding/'); // 90001 , tkbranding
    const employeeNumberValue = useSplit3[0];
    const brandingValue = useSplit3[1];
    if (
      GlobalHelper.isNullOrUndefinedOrEmptyString(employeeNumberValue) ||
      GlobalHelper.isNullOrUndefinedOrEmptyString(uuidValue) ||
      GlobalHelper.isNullOrUndefinedOrEmptyString(mandantValue)
    )
      return;
    const userInfo = new UserInfo(null);
    userInfo.monteur = employeeNumberValue;
    userInfo.mandant = mandantValue;
    userInfo.branding = brandingValue;
    userInfo.uuid = uuidValue;

    this.userInfo = userInfo;
  }
}
