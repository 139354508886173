<div class="messungenBox" [ngClass]="{ 'dx-field': useDxTemplate }">
  <div
    style="flex: 0.9; margin: 10px 10px 0px 10px; font-size: 17px; overflow-y: scroll"
    [ngClass]="{ 'dx-field-label': useDxTemplate }"
  >
    {{ label }}
  </div>
  <div style="flex: 0.9; margin-top: 10px; font-size: 17px" *ngIf="!noSollwert">{{ sollwert }}</div>
  <div
    style="flex: 2"
    [ngClass]="{ 'dx-field-value': useDxTemplate }"
    *ngIf="measuringFieldClass !== 'measuringFieldDisplayonly'"
  >
    <app-bss-number-popup
      style="margin-right: 0px"
      [position]="keyboardPosition"
      [distanceMeterMode]="true"
      [disabled]="disabled"
      [minusAllowed]="minusAllowed"
      [id]="id + fieldIndex"
      #mengenInput
      class="customInputStyle"
      [ignoreLabelStyles]="true"
      [title]="title"
      [description]="label"
      [inputValue]="measurementFunc()"
      [ignoreLabelStyles]="true"
      (outputValue)="valueChanged($event)"
      [mengenTyp]="unit"
      [placeholder]="placeholder"
      [rtlEnabled]="rtlEnabled"
      [showArrows]="false"
      [DecimalPlaces]="getDecimalPlacesLength()"
    >
    </app-bss-number-popup>
  </div>
</div>
