import { Injectable } from '@angular/core';
import jsPDF, { Font } from 'jspdf';
import autoTable, { CellDef, CellHookData, Table } from 'jspdf-autotable';
import moment from 'moment';
import { deDateFormat } from '../../../../../apps/handwerkPWA/src/app/config';
import {
  Anlagenstandort,
  Base64Pdf,
  HWAddress,
  HWAnlage,
  HWMonteur,
  HWRepairOrder,
  PrintPosition,
  ServiceAuftrag,
} from '../../../../../apps/handwerkPWA/src/app/entities';
import { BaseAuftrag, BaseDocumentPosition } from '../../../../../apps/handwerkPWA/src/app/interfaces';
import {
  BlockSetting,
  Briefpapier,
  EmployeeSettings,
  PDFSettings,
  PDFSettingsServiceAuftrag,
  Right,
  Setting,
} from '../entities';
import { ExampleHelper, GlobalHelper, TimeHelper } from '../helper';

const newline = 5;
const neutralBlockSetting: BlockSetting = {
  labelText: 'NeutralSetting',
  startPositionX: null,
  startPositionY: null,
  show: true,
  fontsize: 10,
  fontFamily: 'times',
  fontType: 'normal',
  fontColor: 'black',
};

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  /**
   * @descriptionMethod to manage order completion on PDf
   */
  printOrderCompletion(
    order: BaseAuftrag,
    base64Signature: Base64Pdf,
    printPrices: boolean,
    employee: HWMonteur,
    settings: Setting,
    rights: Right,
    customer: HWAddress,
    allEmployees: HWAddress[],
  ): { DataUri: string; Doc: jsPDF } {
    const employeeSettings = rights.employeeSettings;
    const pdfSettings = Setting.getSettingFromOrderType(order, settings);
    const letterPaper = settings?.briefPapier;
    const doc = this.createNewPDF(letterPaper);
    const endOfLetterHead = this.createLetterHead(
      order,
      pdfSettings,
      employeeSettings,
      doc,
      employee,
      customer,
      allEmployees,
    );
    const printPositions = order.getPrintPositions();
    const positionBlockEnd = this.writeInformationBlockListToPDF(
      printPositions,
      doc,
      printPrices,
      pdfSettings,
      order,
      employeeSettings,
      employee,
      customer,
      letterPaper,
      allEmployees,
      endOfLetterHead,
    );
    const postTextEnd = this.printFormatted(
      doc,
      'text',
      pdfSettings.nachtext.Text,
      pdfSettings.Auftragspositionssettings,
      null,
      positionBlockEnd,
      pdfSettings?.showNachtext,
    );
    if (base64Signature) this.addSignatureToPDF(base64Signature, doc, pdfSettings, postTextEnd);
    let datUri: string = doc.output('datauristring');
    // Backend apparently expects pure user data, therefore remove it
    datUri = datUri.replace('data:application/pdf;filename=generated.pdf;base64,', '');
    return { DataUri: datUri, Doc: doc };
  }

  /**@description Saves a PDF (displays it as well) */
  public savePDF(doc: jsPDF, contractNumber: string): void {
    doc.save(contractNumber + '.pdf');
  }
  public printExampleServiceOrder(setting: Setting): void {
    const { pdf, employee, right, customer, allEmployees } = ExampleHelper.createBaseMusterPDF();
    const serviceOrder = ExampleHelper.createExampleServiceOrder(allEmployees);
    const examplePositions = ExampleHelper.createExampleDocumentPositions();
    const exampleDate = ExampleHelper.createExampleDate();
    serviceOrder.setPositions(examplePositions);
    serviceOrder.updateAdditionalEmployeeList(allEmployees);
    serviceOrder.TerminObject = exampleDate;
    serviceOrder.AnlageObject = new HWAnlage(null, [customer], []);
    const print = this.printOrderCompletion(serviceOrder, pdf, true, employee, setting, right, customer, allEmployees);
    this.savePDF(print.Doc, serviceOrder.getOrderNumber());
  }

  public printExampleRepairOrder(setting: Setting): void {
    const { pdf, employee, right, customer, allEmployees } = ExampleHelper.createBaseMusterPDF();
    const repairOrder = ExampleHelper.createExampleRepairOrder();
    const print = this.printOrderCompletion(repairOrder, pdf, true, employee, setting, right, customer, allEmployees);
    this.savePDF(print.Doc, repairOrder.Nummer);
  }

  /**
   * @description creates the header of a document (Company address, Customer address, Communication information and if necessary Order information) */
  private createLetterHead(
    order: BaseAuftrag,
    pdfSettings: PDFSettings,
    employeeSettings: EmployeeSettings,
    doc: jsPDF,
    employee: HWMonteur,
    customer: HWAddress,
    allEmployees: HWAddress[],
  ): number {
    this.printFormatted(doc, 'text', employee.FirmenAnschrift, pdfSettings.Firmensettings);
    const customerFields = [
      customer.FA_TITEL,
      customer.NAME,
      customer.NAME2,
      customer.STRASSE,
      `${customer.PLZ} ${customer.ORT}`.trim(),
    ];
    this.printFormatted(doc, 'text', customerFields, pdfSettings.Anschriftsettings);
    const communicationFields = [
      `Telefon: ${employee.Telefon}`,
      `Fax: ${employee.Fax}`,
      `E-Mail: ${employee.Email}`,
      `Web: ${employee.Web}`,
    ];
    this.printFormatted(doc, 'text', communicationFields, pdfSettings.Kommunikationssettings);

    let endOfText = -1;
    const endTitle = this.printOrderTitle(pdfSettings, order, doc);
    if (order instanceof HWRepairOrder) {
      endOfText = this.printRepairOrderData(doc, pdfSettings, allEmployees, order, employeeSettings, endTitle);
    } else if (order instanceof ServiceAuftrag) {
      // Does not need endTitle, since each of the maintenance-order specific information is fixed via the global settings
      endOfText = this.printServiceOrderData(doc, order, pdfSettings as PDFSettingsServiceAuftrag, allEmployees);
    }
    return endOfText;
  }

  /**@description Writes the information about the job in the PDF ( employee, contact person, work location, work hours) */
  private printRepairOrderData(
    doc: jsPDF,
    settings: PDFSettings,
    allEmployees: HWAddress[],
    order: HWRepairOrder,
    employeeSettings: EmployeeSettings,
    endTitle: number,
  ): number {
    const orderFields: string[] = [];
    const employee = order.getAllEmployeeNamesInOrder(allEmployees);
    const printInformation = order.getPrintInformation();
    const additionalOrderFields = [
      `Auftragsdatum: ${printInformation.Datum?.substring(0, 10)}`,
      `Arbeitsort: ${printInformation.Kundenbezug} , ${printInformation.Ort}`,
      `Mitarbeiter: ${employee}`,
    ];
    orderFields.push(...additionalOrderFields);

    if (employeeSettings.addWorkingHoursToPDF) {
      this.addWorkingHours(order, orderFields);
    }

    // Make display below title and then set start height back to title height
    const initialHeight = settings.Auftragssettings.startPositionY;
    settings.Auftragssettings.startPositionY = endTitle;
    const endOfLetterHead = this.printFormatted(doc, 'text', orderFields, settings.Auftragssettings);
    settings.Auftragssettings.startPositionY = initialHeight;
    return endOfLetterHead;
  }

  private addWorkingHours(order: HWRepairOrder, orderFields: string[]): void {
    const workingTimesArray = order.Arbeitszeiten?.map(date => TimeHelper.dateToDateTimeString(date));
    const missingEventually = workingTimesArray?.length === 1 ? ' -fehlt- - ' : '';
    const workingTimesString = 'Arbeitszeiten: ' + missingEventually;
    let workTimeSet = [];
    workTimeSet.push(workingTimesString);
    for (const workTime of workingTimesArray) {
      workTimeSet.push(workTime);
      if (workTimeSet.length === 6) {
        orderFields.push(workTimeSet.join(' - '));
        workTimeSet = [];
      }
    }
    if (workTimeSet.length !== 0) orderFields.push(workTimeSet.join(' - '));
  }

  private printOrderTitle(settings: PDFSettings, order: BaseAuftrag, doc: jsPDF): number {
    const orderFields = [`${settings?.titel} ${order.getOrderNumber()}`];
    settings.Auftragssettings.fontType = 'bold';
    const lineEnd = this.printFormatted(doc, 'text', orderFields, settings.Auftragssettings);
    settings.Auftragssettings.fontType = 'normal';
    return lineEnd;
  }

  /**@description  Writes the pre-text, work report, positions, description, quantity and price into a table listing - followed by net price, sales tax and total price.
   * Returns then the final position to continue writing cleanly - if available also pre and postText
   */
  private writeInformationBlockListToPDF(
    items: BaseDocumentPosition[],
    doc: jsPDF,
    showPrices: boolean,
    pdfSettings: PDFSettings,
    order: BaseAuftrag,
    employeeSettings: EmployeeSettings,
    employee: HWMonteur,
    customer: HWAddress,
    letterPaper: Briefpapier,
    allEmployees: HWAddress[],
    endOfLetterHead: number,
  ): number {
    const blockSetting = pdfSettings.Auftragspositionssettings;
    let nextYPosition = blockSetting.startPositionY;
    nextYPosition = this.printFormatted(
      doc,
      'text',
      pdfSettings?.vortext?.Text,
      blockSetting,
      null,
      null,
      pdfSettings?.showVortext,
    );
    nextYPosition = this.printFormatted(doc, 'text', order.getSpecificPreText(), blockSetting, null, nextYPosition);

    const priceGroup = customer.priceGroup;
    let printItems = items.map(item => new PrintPosition(item, true, priceGroup));
    printItems = PrintPosition.addSubPositionLines(printItems, items, order, priceGroup);

    const notPrintSumOnServiceOrder =
      order.auftragsArt === 'Serviceauftrag' && (pdfSettings as PDFSettingsServiceAuftrag).SumBlock.show === false;

    let sumBlock: PrintPosition[];
    if (showPrices && !notPrintSumOnServiceOrder) {
      sumBlock = PrintPosition.createSumBlockAsPrintPositions(employee, order, items, priceGroup);
      printItems = printItems.concat(sumBlock);
    }

    const tableEnd = this.printPositionsAsTable(
      showPrices,
      printItems,
      doc,
      nextYPosition,
      letterPaper,
      order,
      pdfSettings,
      employeeSettings,
      employee,
      customer,
      allEmployees,
      endOfLetterHead,
    );
    return tableEnd;
  }

  /**@description Prints an array of PrintPositions and returns the table end*/
  private printPositionsAsTable(
    showPrices: boolean,
    printItems: PrintPosition[],
    doc: jsPDF,
    nextYPosition: number,
    writingPaper: Briefpapier,
    order: BaseAuftrag,
    pdfSettings: PDFSettings,
    employeeSettings: EmployeeSettings,
    employee: HWMonteur,
    customer: HWAddress,
    allEmployees: HWAddress[],
    endOfLetterHead: number,
  ): number {
    const setting = pdfSettings.Auftragspositionssettings;
    if (!setting.show) return nextYPosition;

    const positionWithPrices = printItems.map(item => [
      item.posNr,
      item.menge,
      item.einheit,
      item.bezeichnung?.replaceAll('\t', '    '),
      item.epreis,
      item.gpreis,
    ]);
    const positionWithoutPrices = printItems.map(item => [item.posNr, item.menge, item.einheit, item.bezeichnung]);
    const printItemStringArray = showPrices ? positionWithPrices : positionWithoutPrices;

    const tableHeaderDescription: CellDef = { content: 'Bezeichnung', styles: { halign: 'left' } };
    const tableHeaders = ['Pos.', 'Menge', 'ME', tableHeaderDescription, 'E-Preis', 'G-Preis'];
    const tableHeaderBase = ['Pos.', 'Menge', 'ME', tableHeaderDescription];
    const headline = showPrices ? [tableHeaders] : [tableHeaderBase];

    let tableMeta: CellHookData = null;
    const priceCellLength = 21;
    let newPageAt = 2;
    autoTable(doc, {
      headStyles: { halign: 'right', fillColor: 'white', textColor: setting.fontColor, fontStyle: 'bold' },
      bodyStyles: { halign: 'right', textColor: setting.fontColor },
      columnStyles: {
        0: { cellWidth: 11 },
        1: { cellWidth: 15 },
        2: { cellWidth: 18 },
        3: { cellWidth: 'auto', halign: 'left' },
        4: { cellWidth: showPrices ? priceCellLength : 0 },
        5: { cellWidth: showPrices ? priceCellLength : 0 },
      }, // names as only left-aligned
      head: headline,
      body: printItemStringArray,
      startY: nextYPosition,
      margin: { top: endOfLetterHead, left: setting.startPositionX, bottom: 60 },
      rowPageBreak: 'avoid',
      willDrawCell: data => {
        if (doc.internal.pages?.length > newPageAt) {
          newPageAt++;
          const { font, fontSize, textColor, fillColor } = this.saveFontsBeforeBraking(doc);
          this.printLetterHeadAndLetterPaper(
            doc,
            writingPaper,
            order,
            pdfSettings,
            employeeSettings,
            employee,
            customer,
            allEmployees,
          );
          this.restoreBrokenFont(doc, font, fontSize, textColor, fillColor);
        }
        tableMeta = data;
      },
      didDrawCell: data => {
        this.underlineTableRow(data.table, 1, data.doc, setting);
      },
    });
    const tableEndY = tableMeta.cell.y + 3 * newline;
    return tableEndY;
  }

  private underlineTableRow(table: Table, rowNumber: number, doc: jsPDF, setting: BlockSetting): void {
    let tableWidth = 0;
    table.columns.forEach(column => {
      tableWidth += column.width;
    });
    const rows = table.allRows();
    doc.setDrawColor(0, 0, 0);
    let height = 0;
    for (let index = 0; index < rowNumber; index++) {
      height += rows[index].height;
    }
    const startingYPosition = table.allRows()[0]?.cells[0]?.y;
    doc.line(
      setting.startPositionX,
      startingYPosition + height,
      setting.startPositionX + tableWidth,
      startingYPosition + height,
    );
  }

  /** Library loses font style and would otherwise print a black box on multiple pages */
  private saveFontsBeforeBraking(doc: jsPDF): { font: Font; fontSize: number; textColor: string; fillColor: string } {
    const fillColor = doc.getFillColor();
    const textColor = doc.getTextColor();
    const font = doc.getFont();
    const fontSize = doc.getFontSize();
    return { font, fontSize, textColor, fillColor };
  }

  private restoreBrokenFont(doc: jsPDF, font: Font, fontSize: number, textColor: string, fillColor: string): void {
    doc.setFont(font.fontName, font.fontStyle);
    doc.setFontSize(fontSize);
    doc.setTextColor(fontSize);
    doc.setTextColor(textColor);
    doc.setFillColor(fillColor);
  }

  private printLetterHeadAndLetterPaper(
    doc: jsPDF,
    letterPaper: Briefpapier,
    order: BaseAuftrag,
    pdfSettings: PDFSettings,
    employeeSettings: EmployeeSettings,
    employee: HWMonteur,
    customer: HWAddress,
    allEmployees: HWAddress[],
  ): void {
    this.addWritingPaper(letterPaper, doc);
    this.createLetterHead(order, pdfSettings, employeeSettings, doc, employee, customer, allEmployees);
  }

  /**
   * @description
   *  Prints formatted text, if no formatting is present, a default one is used
   * @returns New Y position of the document pointer */
  private printFormatted(
    doc: jsPDF,
    mode: 'text',
    text: string | string[],
    setting = neutralBlockSetting,
    customX?: number,
    customY?: number,
    customShow?: boolean,
    alignment: 'right' | 'left' = 'left',
  ): number {
    const startX = !customX ? setting.startPositionX : customX;
    const startY = !customY ? setting.startPositionY : customY;
    if (!text) return startY;
    let textAsArray = Array.isArray(text) ? text : (doc.splitTextToSize(text, 210 - startX - 10) as string[]);
    textAsArray = textAsArray?.filter(line => line);
    const show = GlobalHelper.isNullOrUndefined(customShow) ? setting.show : customShow;
    if (!show || !textAsArray) return startY;
    this.setTextStyle(doc, setting);
    switch (mode) {
      case 'text': {
        doc.text(textAsArray, startX, startY, { align: alignment });
        break;
      }
    }
    const currentLinePositionY = startY + textAsArray.length * newline;
    return currentLinePositionY;
  }

  private setTextStyle(doc: jsPDF, styleSetting: BlockSetting): void {
    const fontSize = styleSetting.fontsize;
    const fontStyle = styleSetting.fontFamily;
    const fontType = styleSetting.fontType;
    const fontColor = styleSetting.fontColor;
    doc.setFontSize(fontSize);
    doc.setFont(fontStyle, fontType);
    doc.setTextColor(fontColor);
    doc.setDrawColor(fontColor);
  }

  private printServiceOrderData(
    doc: jsPDF,
    order: ServiceAuftrag,
    pdfSettings: PDFSettingsServiceAuftrag,
    allEmployees: HWAddress[],
  ): number {
    const location = new Anlagenstandort(order.AnlageObject.Anlagenstandort);
    const maintenanceSystemPrintInformation = location.getMaintenanceSystemInformationForPDF();
    this.printFormatted(doc, 'text', maintenanceSystemPrintInformation, pdfSettings.AddressAndContact);
    this.printFormatted(
      doc,
      'text',
      ['Monteure: ' + order.getPdfPrintEmployeeNames(allEmployees)],
      pdfSettings.Monteure,
    );
    this.printFormatted(
      doc,
      'text',
      ['Beginn: ' + (order?.TerminObject?.start?.substring(0, 10) || '')],
      pdfSettings.DateBeginning,
    );
    this.printFormatted(doc, 'text', [order?.TerminObject?.start?.substring(10) || ''], pdfSettings.TimeBeginning);
    this.printFormatted(
      doc,
      'text',
      ['Ende: ' + (order?.TerminObject?.finish?.substring(0, 10) || '')],
      pdfSettings.DateEnd,
    );
    const endOfBlock = this.printFormatted(
      doc,
      'text',
      [order?.TerminObject?.finish?.substring(10) || ''],
      pdfSettings.TimeEnd,
    );
    return endOfBlock;
  }

  /**@description Adds the signature to the PDF */
  private addSignatureToPDF(
    base64Signature: Base64Pdf,
    doc: jsPDF,
    pdfSettings: PDFSettings,
    positionListEnd: number,
  ): void {
    // X and Y possibly confusing here, because during rotation the whole thing changes
    const signatureSettings = pdfSettings.UnterschriftSettings;
    const signaturePositionWidth = signatureSettings?.startPositionX;
    const imageSizeX = 45;

    const img = new Image(imageSizeX);
    img.src = base64Signature.data;
    const imageSizeY = img.height;
    const signaturePositionX = signaturePositionWidth + imageSizeX;
    const signaturePositionY = imageSizeY > 0 ? positionListEnd - imageSizeY : positionListEnd + newline;
    if (signatureSettings.show) {
      if (base64Signature.landScape)
        doc.addImage(
          img.src,
          'JPEG',
          signaturePositionX - imageSizeX,
          signaturePositionY + 2 * imageSizeY,
          imageSizeX,
          imageSizeY,
          'unterschrift',
          'SLOW',
        );
      else {
        doc.addImage(
          img.src,
          'JPEG',
          signaturePositionX - imageSizeX,
          signaturePositionY + newline,
          imageSizeY,
          imageSizeX,
          'unterschrift',
          'SLOW',
        );
      }
    }
    const showDate = pdfSettings.DatumAuftragsabschluss?.show || false;
    if (!showDate) return;
    const settingsDateOrderCompletion = pdfSettings.DatumAuftragsabschluss;
    this.setTextStyle(doc, settingsDateOrderCompletion);
    const dateOrderCompletionX = settingsDateOrderCompletion.startPositionX;
    const completionDate = moment(new Date()).format(deDateFormat);
    doc.text(completionDate, dateOrderCompletionX, signaturePositionY + imageSizeX);
  }

  private createNewPDF(letterPaper: Briefpapier): jsPDF {
    const doc = new jsPDF('portrait', 'mm', 'a4');
    this.addWritingPaper(letterPaper, doc);
    this.setTextStyle(doc, neutralBlockSetting);
    return doc;
  }

  private addWritingPaper(letterPaper: Briefpapier, doc: jsPDF): void {
    if (!letterPaper?.base64Data) return;
    const maxWidth = doc.internal.pageSize.getWidth();
    const maxHeight = doc.internal.pageSize.getHeight();
    const paperImage = new Image();
    const base64Header = 'data:image/jpeg;base64,';
    const headerNeeded = !letterPaper.base64Data.startsWith(base64Header);
    const writingPaperSource = headerNeeded ? base64Header + letterPaper.base64Data : letterPaper.base64Data;
    paperImage.src = writingPaperSource;
    doc.addImage(paperImage, 'JPEG', 0, 0, maxWidth, maxHeight, 'SLOW');
  }
}
