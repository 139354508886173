import { GlobalHelper } from '@handwerk-pwa/shared';
import { HWAnlage } from '../../repository/HWAnlage';
import { Anschrift } from '../Anschrift';

export class Anlagenstandort {
  // Name, Straße , plz und ort kommen direkt aus dem Kunden und sind nicht änderbar,ohne den Kunden zu ändern
  NAME: string = null; // Name des Kunden vom Standort
  STRASSE: string = null; // Strasse des Kunden
  PLZ: string = null; // OLZ des Kunden
  ORT: string = null; // ORT des Kunden
  STANDORT1: string = null; // standort 1
  STANDORT2: string = null; // standort 2
  TEL: string = null; // Telefon
  MONTEUR: string = null; // Personalnummer des Monteurs
  MONTEUR2: string = null; // Personalnummer des Monteurs 2
  ANFAHRTKM: string = null; // Anfahrt in km
  ANFAHRT: number = null; // Anfahrtszeit in Minuten
  WDAUER: number = null; // Wartungsdauer: string = null ;
  StandortAnschrift: Anschrift = null; // Wartungsdauer: string = null ;

  constructor(data: Anlagenstandort | HWAnlage) {
    GlobalHelper.assignIfPropertyExists(this, data);
    this.StandortAnschrift = new Anschrift(this.STRASSE, this.PLZ, this.ORT);
  }

  /**@description Gives the PrintService the information to print. Each array entry will be printed in a separate line  */
  getMaintenanceSystemInformationForPDF(): string[] {
    const information: string[] = [];
    if (this.NAME) information.push(this.NAME);
    if (this.STRASSE) information.push(this.STRASSE);
    if (this.PLZ || this.ORT) information.push(this.PLZ + ' ' + this.ORT);
    if (this.STANDORT1) information.push('Standort1: ' + this.STANDORT1);
    if (this.STANDORT2) information.push('Standort2: ' + this.STANDORT2);
    if (this.TEL) information.push('Telefon: ' + this.TEL);
    if (this.ANFAHRTKM) information.push('Anfahrt: ' + this.ANFAHRTKM + 'km');
    return information;
  }
}
