import { GlobalHelper, MeasurementHelper } from '@handwerk-pwa/shared';
import * as uuid from 'uuid';
import { RoomBook } from '../../repository/RoomBook';
import { BuildingElement } from './BuildingElement';
import { CoordinatePoint } from './CoordinatePoint';
import { RoomBookPosition } from './RoomBookPosition';

export class MeasurementRoute {
  startCoordinatePoint: CoordinatePoint = null;
  endCoordinatePoint: CoordinatePoint = null;
  middleCoordinatePoint: CoordinatePoint = null;
  length: number = null;
  lengthInput = '';
  unit = 'm';
  label: string = null;
  labelLong: string = null;
  erfasst = false;
  slope: number = null;
  yAchsenSchnitt: number = null;
  uuid: string = null;
  formula?: string = null;
  isFloor = false;
  isCeiling = false;
  pointDistance: number = null;

  constructor(startCoordinatePoint: CoordinatePoint, endCoordinatePoint: CoordinatePoint, index: number) {
    if (!startCoordinatePoint) return;
    this.uuid = uuid.v4();
    this.startCoordinatePoint = startCoordinatePoint;
    this.endCoordinatePoint = endCoordinatePoint;
    this.label = 'w' + index;
    this.labelLong = 'Wand' + index;
    this.slope = this.calculateSlope(startCoordinatePoint, endCoordinatePoint);
    this.yAchsenSchnitt = this.calculateYAchsenSchnitt(this.startCoordinatePoint, this.slope);
    this.middleCoordinatePoint = this.calculateMiddlePoint(startCoordinatePoint, endCoordinatePoint);
    this.pointDistance = this.calculatePointDistance(startCoordinatePoint, endCoordinatePoint);
  }

  /**@description A route is closed if it contains more than 2 sections, and the last section has its end point at the start point of the first section. */
  static isRouteClosed(routes: MeasurementRoute[]): boolean {
    if (routes?.length < 3) return false;
    const startRoute = routes[0];
    const endRoute = routes[routes.length - 1];
    if (
      this.isSameCoordinatePoint(startRoute.startCoordinatePoint, endRoute.endCoordinatePoint) &&
      !this.isSameCoordinatePoint(startRoute.middleCoordinatePoint, endRoute.middleCoordinatePoint)
    )
      return true;
    return false;
  }

  private static isSameCoordinatePoint(point1: CoordinatePoint, point2: CoordinatePoint): boolean {
    return point1.xCoordinate === point2.xCoordinate && point1.yCoordinate === point2.yCoordinate;
  }

  /**@description Calculates the length of the distance of the drawn CoordinatePointe */
  calculatePointDistance(startCoordinatePoint: CoordinatePoint, endCoordinatePoint: CoordinatePoint): number {
    const x1 = startCoordinatePoint.xCoordinate;
    const x2 = endCoordinatePoint.xCoordinate;
    const xAbsolute = Math.abs(x2 - x1);
    const y1 = startCoordinatePoint.yCoordinate;
    const y2 = endCoordinatePoint.yCoordinate;
    const yAbsolute = Math.abs(y2 - y1);
    return Math.hypot(xAbsolute, yAbsolute);
  }

  /**@description Returns for a CoordinatePoint the closest CoordinatePoint within the route */
  getPointOnRoute(clickedPoint: CoordinatePoint): CoordinatePoint {
    const xClicked = MeasurementHelper.getEdgeIfOver(
      clickedPoint.xCoordinate,
      this.startCoordinatePoint.xCoordinate,
      this.endCoordinatePoint.xCoordinate,
    );
    const yClicked = MeasurementHelper.getEdgeIfOver(
      clickedPoint.yCoordinate,
      this.startCoordinatePoint.yCoordinate,
      this.endCoordinatePoint.yCoordinate,
    );
    const yCalculatedFromX = xClicked * this.slope + this.yAchsenSchnitt;
    if (this.slope === 0) return new CoordinatePoint(xClicked, yCalculatedFromX);
    if (this.slope === Infinity || this.slope === -Infinity) {
      const xOfVerticalLine = this.startCoordinatePoint.xCoordinate;
      const pointOnVerticalLine = new CoordinatePoint(xOfVerticalLine, yClicked);
      return pointOnVerticalLine;
    }
    const xCalculatedFromY = (yCalculatedFromX - this.yAchsenSchnitt) / this.slope;
    const calculatedPoint = new CoordinatePoint(xCalculatedFromY, yCalculatedFromX);
    return calculatedPoint;
  }

  /**@description Calculates the y-axis intercept for the linear equation */
  calculateYAchsenSchnitt(startCoordinatePoint: CoordinatePoint, slope: number): number {
    const yCoordinate = startCoordinatePoint.yCoordinate;
    const xCoordinate = startCoordinatePoint.xCoordinate;
    const yAchsenSchnitt = yCoordinate - slope * xCoordinate;
    return yAchsenSchnitt;
  }

  /**@description Looks at the standard straight line equation to see if the calculated y-value of the CoordinatePoints is within the tolerance of the value */
  isPointOnMeasurementRoute(point: CoordinatePoint, tolerance: number): boolean {
    const xCoordinate = point.xCoordinate;
    const yCoordinate = point.yCoordinate;
    if (this.slope === Infinity || this.slope === -Infinity) {
      // Slope Infinite => Vertical, Only one X-coordinate
      const xOfLine = this.startCoordinatePoint.xCoordinate;
      const yOfLineStart = this.startCoordinatePoint.yCoordinate;
      const yOfLineEnd = this.endCoordinatePoint.yCoordinate;
      const nearHorizontalReal = MeasurementHelper.isInTolerance(xOfLine, xCoordinate, tolerance);
      const nearVerticalLine =
        MeasurementHelper.isInTolerance(yOfLineStart, yCoordinate, tolerance) ||
        MeasurementHelper.isInTolerance(yOfLineEnd, yCoordinate, tolerance) ||
        MeasurementHelper.isBetween(yCoordinate, yOfLineStart, yOfLineEnd);
      return nearHorizontalReal && nearVerticalLine;
    }
    if (this.slope === 0) {
      // Slope 0 => Horizontal, Only one Y-coordinate
      const yOfLine = this.startCoordinatePoint.yCoordinate;
      const xOfLineStart = this.startCoordinatePoint.xCoordinate;
      const xOfLineEnd = this.endCoordinatePoint.xCoordinate;
      const nearHorizontalReal = MeasurementHelper.isInTolerance(yOfLine, yCoordinate, tolerance);
      const nearVerticalLine =
        MeasurementHelper.isInTolerance(xOfLineStart, xCoordinate, tolerance) ||
        MeasurementHelper.isInTolerance(xOfLineEnd, xCoordinate, tolerance) ||
        MeasurementHelper.isBetween(xCoordinate, xOfLineStart, xOfLineEnd);
      return nearHorizontalReal && nearVerticalLine;
    }
    const calculatedYValue = xCoordinate * this.slope + this.yAchsenSchnitt;
    const pointOnStrecke = yCoordinate - tolerance < calculatedYValue && calculatedYValue < yCoordinate + tolerance;
    if (pointOnStrecke) {
      return true;
    }
    return false;
  }

  /**@description General equation of a straight line for calculating the slope */
  calculateSlope(startCoordinatePoint: CoordinatePoint, endCoordinatePoint: CoordinatePoint): number {
    const deltaY = endCoordinatePoint.yCoordinate - startCoordinatePoint.yCoordinate;
    const deltaX = endCoordinatePoint.xCoordinate - startCoordinatePoint.xCoordinate;
    return deltaY / deltaX;
  }

  calculateMiddlePoint(startCoordinatePoint: CoordinatePoint, endCoordinatePoint: CoordinatePoint): CoordinatePoint {
    const xStart = startCoordinatePoint.xCoordinate;
    const xEnd = endCoordinatePoint.xCoordinate;
    const yStart = startCoordinatePoint.yCoordinate;
    const yEnd = endCoordinatePoint.yCoordinate;
    const middleX = (xStart + xEnd) / 2;
    const middleY = (yStart + yEnd) / 2;
    const middleCoordinatePoint = new CoordinatePoint(middleX, middleY);
    return middleCoordinatePoint;
  }

  /**@description Converts the measurements of a distance meter to the correct values (splits units) */
  setInputsClean(measurementRoute: MeasurementRoute): void {
    if (measurementRoute.erfasst) {
      return;
    }
    const length = measurementRoute.lengthInput;
    let numberPart = '';
    let unitPart = '';
    for (const char of length) {
      const parsedInt = parseInt(char, 10);
      const isANumber = isNaN(parsedInt) === false;
      if (isANumber || char === '.' || char === ',') {
        numberPart = numberPart + char;
        continue;
      }
      unitPart = unitPart + char;
    }
    if (GlobalHelper.isNullOrUndefined(unitPart) || unitPart === '') {
      unitPart = 'm';
    }
    const route = parseFloat(numberPart);
    measurementRoute.unit = unitPart;
    measurementRoute.length = route;
    measurementRoute.lengthInput = route.toString();
    measurementRoute.erfasst = true;
  }

  toRoomBookEntry(currentEntires: RoomBook[], room: RoomBookPosition, raumHoehe: number, formula?: string): RoomBook {
    const entry = new RoomBook(null, room);
    //For a surface the second dimension is the room height, for a building element it is the respective measured value.
    const secondDimension = this instanceof BuildingElement ? this.height : raumHoehe;
    if (this instanceof BuildingElement && this.template.alternatingFormula)
      formula = MeasurementHelper.createFormulaFromAlternatingFormula(this, this.template.alternatingFormula);
    entry.fillFromMeasurement(currentEntires, room, this, secondDimension, formula);
    return entry;
  }
}
