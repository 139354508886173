import { Injectable } from '@angular/core';
import { GlobalHelper } from '@handwerk-pwa/shared';
import { IDBPDatabase, IDBPIndex, IDBPObjectStore, openDB } from 'idb';
import idbReady from 'safari-14-idb-fix';
import version from '../../../../../../deployment/version/version.json';
import { GlobalSettings } from '../../config';
import {
  ActivityTracker,
  AppOnlySettings,
  Aufmass,
  BaseInformationTracker,
  HWAddress,
  HWAnlage,
  HWBeleg,
  HWContact,
  HWContactPerson,
  HWDatenblatt,
  HWEmailData,
  HWFile,
  HWGlobalSetting,
  HWMonteur,
  HWNachricht,
  HWObjectAddress,
  HWOffeneposten,
  HWRepairOrder,
  HWRepairOrderItem,
  HWTermin,
  HWUmsatz,
  Medien,
  Projekt,
  RoomName,
  RoomTemplate,
  ServiceAuftrag,
} from '../../entities';

type AccessMethod = 'readwrite' | 'readonly';
class DBStore {
  database: IDBPDatabase<unknown>;
  store: IDBPObjectStore<unknown, [string], string, AccessMethod>;
}

@Injectable({
  providedIn: 'root',
})
export class Database {
  dbVersion = 1;
  version = version;

  stores = [
    HWAddress,
    HWBeleg,
    HWContact,
    HWContactPerson,
    HWEmailData,
    HWMonteur,
    HWNachricht,
    HWObjectAddress,
    HWOffeneposten,
    HWRepairOrder,
    HWTermin,
    HWUmsatz,
    HWFile,
    HWAnlage,
    HWDatenblatt,
    HWRepairOrderItem,
    ServiceAuftrag,
    RoomName,
    Projekt,
    Aufmass,
    Medien,
    RoomTemplate,
    HWGlobalSetting,
    ActivityTracker,
    BaseInformationTracker,
  ];

  /**
   * @description: Funktion um die Version der Datenbank zu ändern. In der Methode 'InitStores' wird das Versioning gehandelt.
   * @returns Promise vom Typ void
   */
  public async setDbVersion(): Promise<void> {
    await this.initStores(this.dbVersion);
  }

  /**
   *
   * @description Methode um in bestimmte Tabelle in der Datenbank zu schreiben
   * @param <storeName: string, list: object[]>
   * @returns void
   */
  public async insert(storeName: string, list: unknown[]): Promise<void> {
    if (!list || list.length === 0) return;
    const dbStore = await this.accessStore(storeName, 'readwrite');
    const results = [];
    for (const element of list) results.push(dbStore.store.put(element));
    await Promise.all(results);
    dbStore.database.close();
  }

  /**
   *
   * @description Methode um aus bestimmter Tabelle in der Datenbank alle Einträge auszulesen
   * @param <storeName: string>
   * @returns Promise<object[]>
   */
  public async getAllData<Type>(storeName: string): Promise<Type> {
    return await this.getAll<Type>(storeName);
  }

  /**
   *
   * @description Methode um aus bestimmter Tabelle in der Datenbank bestimme Einträge auszulesen
   * @param <storeName: string, index: string, keyValue: string>
   * @returns Promise<object[]>
   */
  public async getAllSpecificData<Type>(storeName: string, index: string, keyValue: string): Promise<Type> {
    return await this.getAll<Type>(storeName, keyValue, index);
  }

  /**
   * @description      Funktion, um aus bestimmter Tabelle in der Datenbank unter Angabe eines Schlüssels alle Einträge auszulesen
   * @param key:       string (Schlüssel für die Abfrage in der Datenbank)
   * @returns          Promise<object[]>
   */
  public async getAllSpecificDataWithKey<Type>(storeName: string, keyValue: string): Promise<Type> {
    return await this.getAll<Type>(storeName, keyValue);
  }

  /**
   *
   * @description Methode um aus bestimmter Tabelle in der Datenbank bestimme Einträge zu löschen
   * @param <storeName: string, index: string, keyValue: string>
   * @returns Promise<object[]>
   */
  public async deleteSpecificData(storeName: string, index: string, keyValue: string): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    const cursor = await dbStore.store.openCursor();
    while (!GlobalHelper.isNullOrUndefined(cursor)) {
      try {
        if (cursor.value[index] === keyValue) {
          await cursor.delete();
        }
        await cursor.continue();
      } catch {
        return;
      }
    }
    dbStore.database.close();
  }

  public async deleteSpecificData2(
    storeName: string,
    index: string,
    keyValue: string,
    index2: string,
    keyValue2: string,
  ): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    const cursor = await dbStore.store.openCursor();
    while (!GlobalHelper.isNullOrUndefined(cursor)) {
      try {
        if (cursor.value[index] === keyValue && cursor.value[index2] === keyValue2) {
          await cursor.delete();
        }
        await cursor.continue();
      } catch {
        return;
      }
    }
    dbStore.database.close();
  }

  public async deleteData(storeName: string, keyValue: string): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    await dbStore.store.delete(keyValue);
    dbStore.database.close();
  }

  public async clearStore(storeName: string): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    await dbStore.store.clear();
    dbStore.database.close();
  }

  public async rebuildDB(dbVersion: number): Promise<void> {
    this.deleteOldIDB();
    await this.initStores(dbVersion);
  }

  private deleteOldIDB(): void {
    const request = indexedDB.deleteDatabase('handwerkpwa');
    request.onsuccess = (): void => {};
    request.onerror = (): void => {};
    request.onblocked = (): void => {};
  }

  private async initStores(dbVersion: number): Promise<void> {
    const stores = this.stores;
    let storesInitialized = false;

    const db = await openDB('handwerkpwa', dbVersion, {
      upgrade(upgradeDB, oldVersion, newVersion, transaction) {
        for (const storeName of stores) {
          storeName.InitStore(upgradeDB);
        }
        storesInitialized = true;
      },
      blocked() {},
      blocking() {},
      terminated() {},
    });
    db.close();
    if (storesInitialized) {
      await this.insert(HWGlobalSetting.toString(), [{ key: GlobalSettings.AppVersion, value: this.version.version }]);
      await this.insert(HWGlobalSetting.toString(), [
        { key: GlobalSettings.AppOnlySettings, value: new AppOnlySettings(undefined) },
      ]);
    }
  }

  private getIndex(dbStore: DBStore, index: string): IDBPIndex<unknown, [string], string, string, AccessMethod> {
    try {
      return dbStore.store.index(index);
    } catch (error) {
      let errorMessage = `Index: ${index} of store: ${dbStore.store.name} not found\n`;
      errorMessage += 'The Store needs the selector to be a IndexField instead of a DataField';
      throw new Error(errorMessage);
    }
  }

  private async getAll<Type>(storeName: string, keyValue?: string, index?: string): Promise<Type> {
    const dbStore = await this.accessStore(storeName, 'readonly');
    const accessor = index ? this.getIndex(dbStore, index) : dbStore.store;
    try {
      const data = await accessor.getAll(keyValue);
      dbStore.database.close();
      return data as Type;
    } catch (error) {
      const keyValueText = keyValue ? ' with keyValue: ' + keyValue : '';
      const indexText = index ? ' with index: ' + index : '';
      throw new Error(
        'getAll in dbStore: ' + dbStore?.store?.name + indexText + ' ' + keyValueText + ' resulted in an error',
      );
    }
  }

  /**@description StoreZugriff zentralisiert */
  private async accessStore(storeName: string, accessMethod: AccessMethod): Promise<DBStore> {
    let db: IDBPDatabase<unknown>;
    try {
      await idbReady().then(async () => {
        db = await openDB('handwerkpwa', this.dbVersion);
      });
      const tx = db.transaction(storeName, accessMethod);
      const store = tx.objectStore(storeName);
      return { database: db, store: store };
    } catch (error) {
      throw new Error('Error accessing Store: ' + storeName + ' with accessMethod: ' + accessMethod);
    }
  }
}
