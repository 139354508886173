import { Component, OnInit } from '@angular/core';
import { GlobalHelper, RoutingService, UserInfo } from '@handwerk-pwa/shared';
import { environment } from '../../../environments/environment';
import { GlobalSettingService, LoginService, StateService } from '../../services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  qrEnabled = false;
  isProduction: boolean;
  manuellLogin = false;
  userInfo: UserInfo;

  constructor(
    private loginService: LoginService,
    private globalSettingService: GlobalSettingService,
    private routingService: RoutingService,
    private stateService: StateService,
  ) {}

  async logOut(): Promise<void> {
    await this.loginService.logOut();
  }

  async ngOnInit(): Promise<void> {
    const hostname = window.location.hostname;
    const productionUrl =
      hostname.startsWith('192.') || hostname.startsWith('local') ? false : true;
    this.isProduction = environment.production && productionUrl;

    const userInfoFromParams = this.checkForRouteparametersForManuallLogin();
    if (!GlobalHelper.isNullOrUndefined(userInfoFromParams)) {
      this.userInfo = userInfoFromParams;
      this.manuellLogin = true;
      return;
    }

    const userInfoFromIDB = await this.globalSettingService.getUserInfo();
    this.userInfo = GlobalHelper.isNullOrUndefined(userInfoFromIDB) ? new UserInfo(null) : userInfoFromIDB;
    if (!GlobalHelper.isNullOrUndefined(this.userInfo) && !GlobalHelper.isNullOrUndefined(this.userInfo.pin)) {
      await this.loginUser(this.userInfo, this.userInfo.updateDonePwa);
    }
  }

  /**@description Logt den Nutzer per Button Login ein */
  createUserInfoFromLogindata(uuidValue: string, monteurString: string, mobilePin: string, mandantValue: string): void {
    const userInfo = new UserInfo(null);
    userInfo.uuid = uuidValue;
    userInfo.monteur = monteurString;
    userInfo.employeeNumber = userInfo.monteur;
    userInfo.pin = mobilePin;
    userInfo.mandant = mandantValue;

    void this.loginUser(userInfo);
  }

  /**@description Logt den Nutzer über seinen gescannten QR-Code ein */
  qrLogin(userInfoData: UserInfo): void {
    const userInfo = new UserInfo(userInfoData);
    void this.loginUser(userInfo);
  }

  /**@description Wird aufgerufen, wenn in der QRComponent auf 'Abbrechen' geklickt wird */
  scanStop(event: boolean): void {
    this.qrEnabled = event;
  }

  activateQrScan(): void {
    this.qrEnabled = true;
  }

  /**@description Ließt url parameter und wenn zumindest die uuid gefunden wurde, wird ein manueller login damit getriggert */
  private checkForRouteparametersForManuallLogin(): UserInfo {
    const employeeNumberValue = this.routingService.getRouteParam('employeeNumber');
    const brandingValue = this.routingService.getRouteParam('branding');
    const mandantValue = this.routingService.getRouteParam('mandant');
    const uuidValue = this.routingService.getRouteParam('uuid');
    const userInfo = new UserInfo(null);
    userInfo.monteur = employeeNumberValue;
    userInfo.mandant = mandantValue;
    userInfo.branding = brandingValue;
    userInfo.uuid = uuidValue;

    if (!mandantValue) return null;
    return userInfo;
  }

  async loginUser(userInfo: UserInfo, withSync?: boolean): Promise<void> {
    await this.stateService.clickButton(async (): Promise<void> => {
      await this.loginService.loginUser(userInfo, withSync);
    });
  }
}
