import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@handwerk-pwa/shared';
import { MeasurementTypes, SliderNames, measurementSearch } from '../../../config';
import { Aufmass } from '../../../entities';
import { ConnectionDialogues, ConnectionService, MeasurementService, SyncObjectService } from '../../../services';

@Component({
  selector: 'app-free-measurement-index',
  templateUrl: './free-measurement-index.component.html',
  styleUrls: ['./free-measurement-index.component.scss'],
})
export class FreeMeasurementIndexComponent implements OnInit {
  measurements: Aufmass[];
  sliderNames = SliderNames;
  searchExpressions = measurementSearch;

  constructor(
    private measurementService: MeasurementService,
    private routingService: RoutingService,
    private connectionService: ConnectionService,
    private syncObjectService: SyncObjectService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.measurements = await this.measurementService.getAllBy('Type', MeasurementTypes.freeMeasurement);
    this.syncObjectService.currentSyncObjects.next([Aufmass]);
  }

  async createFreeMeasurement(): Promise<void> {
    const online = await this.connectionService.checkOnline(ConnectionDialogues.CreateData, false);
    if (online) await this.routingService.navigateTo('/aufmass/neu/type/' + MeasurementTypes.freeMeasurement);
  }
}
