import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { serviceOrderSearch } from '../../../../config';
import { ServiceAuftrag } from '../../../../entities';
import {
  AddressService,
  AppointmentService,
  GlobalSettingService,
  MaintenanceSystemService,
  ServiceOrderService,
  SyncObjectService,
} from '../../../../services';

@Component({
  selector: 'app-service-orders-index',
  templateUrl: './service-orders-index.component.html',
  styleUrls: ['./service-orders-index.component.scss'],
})
export class ServiceOrdersIndexComponent implements OnInit {
  serviceAuftraege: BehaviorSubject<ServiceAuftrag[]> = new BehaviorSubject<ServiceAuftrag[]>(null);
  searchExpressions = serviceOrderSearch;

  constructor(
    private serviceOrderService: ServiceOrderService,
    private maintenanceAndService: MaintenanceSystemService,
    private addressService: AddressService,
    private appointmentService: AppointmentService,
    private globalsettingService: GlobalSettingService,
    private syncObjectService: SyncObjectService,
  ) {}

  async ngOnInit(): Promise<void> {
    const monteure = await this.addressService.getAllBy('ADRTYP', 'M');
    const allAnlagen = await this.maintenanceAndService.getAnlagenFromIDB();
    const userInfo = await this.globalsettingService.getUserInfo();
    const termine = await this.appointmentService.getAllAppointmentsFromIDB(userInfo);
    this.serviceAuftraege.next(
      await this.serviceOrderService.getAllServiceOrdersWithAllDetailsFromIDB(monteure, allAnlagen, termine),
    );
    this.syncObjectService.currentSyncObjects.next([ServiceAuftrag]);
  }
}
