import { Component, OnInit } from '@angular/core';
import { RightsService, RoutingService } from '@handwerk-pwa/shared';
import { objectAddressSearch } from '../../../../config';
import { HWAddress, HWObjectAddress } from '../../../../entities';
import { AddressService, GlobalSettingService, ObjectaddressService } from '../../../../services';

@Component({
  selector: 'app-object-addresses-index',
  templateUrl: './object-addresses-index.component.html',
  styleUrls: ['./object-addresses-index.component.scss'],
})
export class ObjectAddressesIndexComponent implements OnInit {
  searchExpressions = objectAddressSearch;
  objectAddresses: HWObjectAddress[] = [];
  address: HWAddress;
  canEditObjAddresses = false;

  constructor(
    private globalSettingService: GlobalSettingService,
    private routingService: RoutingService,
    private addressService: AddressService,
    private objectAdressService: ObjectaddressService,
    private rightsService: RightsService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadObjectAddresses();
    const rights = this.rightsService.getCurrentRight();
    this.canEditObjAddresses = rights.employeeRights.addressRights.editObjectAddresses;
  }
  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  private async loadObjectAddresses(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const addressGuid = this.routingService.getRouteParam('guid');
    this.address = await this.addressService.findOneBy('Guid', addressGuid);
    this.objectAddresses = await this.objectAdressService.getObjektAddressesForKundennummerByChoice(
      userInfo,
      this.address.KU_NR,
    );
  }
}
