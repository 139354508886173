import { GlobalHelper, Right } from '@handwerk-pwa/shared';
import { HWAddress } from '../repository/HWAddress';
import { HWNachricht } from '../repository/HWNachricht';
import { HWObjectAddress } from '../repository/HWObjectAddress';
import { HWRepairOrder } from '../repository/HWRepairOrder';
import { HWTermin } from '../repository/HWTermin';
import { ServiceAuftrag } from '../repository/ServiceAuftrag';
import { SyncObject } from './SyncObject';

/**Einen Wert von -1 gibt der Webservice zurück bei nicht vorhanden sein von Timestamps */
export class TimestampTable {
  Adressen = -1;
  Auftrag = -1;
  Material = -1;
  Leistungen = -1;
  Calender = -1;
  OwnRights = -1;
  Nachricht = -1;
  Serviceauftrag = -1;

  constructor(inputFromWebservice: TimestampTable) {
    GlobalHelper.assignIfPropertyExists(this, inputFromWebservice);
  }

  /**@description Prüft ob der Webservice an der jeweiligen Stelle noch einen Timestamp zu einem Datensatz fand und ob dieser timestamp sich vom vorherigen unterscheidet */
  checkForUpdates(lastTimestampTable: TimestampTable, right: Right): SyncObject[] {
    let changesIn: SyncObject[] = [];
    if (this.Adressen !== -1 && this.Adressen > lastTimestampTable.Adressen)
      changesIn = changesIn.concat([HWAddress, HWObjectAddress]);
    if (this.Calender !== -1 && this.Calender > lastTimestampTable.Calender) changesIn.push(HWTermin);
    const hasLicense = right.employeeRights.showObjektadressen;
    if (!hasLicense) return changesIn;
    if (
      this.Auftrag !== -1 &&
      this.Auftrag > lastTimestampTable.Auftrag &&
      right.employeeRights.repairOrderRights.enableModule
    )
      changesIn.push(HWRepairOrder);
    if (
      this.Nachricht !== -1 &&
      this.Nachricht > lastTimestampTable.Nachricht &&
      right.employeeRights.repairOrderRights.enableModule
    )
      changesIn.push(HWNachricht);
    if (
      this.Serviceauftrag !== -1 &&
      this.Serviceauftrag > lastTimestampTable.Serviceauftrag &&
      right.employeeRights.maintenanceRights.handleOrder
    )
      changesIn.push(ServiceAuftrag);
    return changesIn;
  }
}

export class TimeStampTableRequestNew {
  mandant: string;
  employeeNumber: string;
  repairOrderNumbersAlreadyInApp: string[];
  serviceOrderNumbersAlreadyInApp: string[];
  allTermine: boolean;

  constructor(
    mandant: string,
    employeeNumber: string,
    repairOrderNumbersAlreadyInApp: string[],
    serviceOrderNumbersAlreadyInApp: string[],
    allTermine: boolean,
  ) {
    this.mandant = mandant;
    this.employeeNumber = employeeNumber;
    this.repairOrderNumbersAlreadyInApp = repairOrderNumbersAlreadyInApp;
    this.serviceOrderNumbersAlreadyInApp = serviceOrderNumbersAlreadyInApp;
    this.allTermine = allTermine;
  }
}
