import { GlobalHelper } from '../../../helper';

export class EmployeeSettings {
  autoWage = 'Aus';
  showPrices = false;
  printPrices = false;
  pdfPreview = false;
  sendOrderMail = false;
  sumUp = false;
  addWorkingHoursToPDF = true;

  constructor(employeeSettings: EmployeeSettings) {
    GlobalHelper.assignIfPropertyExists(this, employeeSettings);
  }
}
