import { CoordinatePoint } from './CoordinatePoint';

export class CoordinateLabel {
  CoordinatePoint: CoordinatePoint;
  text: string;

  constructor(point: CoordinatePoint, text: string) {
    this.CoordinatePoint = point;
    this.text = text;
  }
}
