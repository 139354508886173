/* eslint-disable @typescript-eslint/quotes */
import { GlobalHelper } from '@handwerk-pwa/shared';

export class ConvertHelper {
  static convertToPlain(rtf: string): string {
    rtf = rtf.replace(/\\par[d]?/g, '');
    const plainTextNonGerman = rtf.replace(/\{\*?\\[^{}]+;}|[{}]|\\[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, '').trim();
    const plainTextGerman = this.convertRTFSequenceToGermanSigns(plainTextNonGerman);
    const htmlText = this.convertFormattedHandwerkTextToHtml(plainTextGerman);
    return htmlText;
  }

  private static convertRTFSequenceToGermanSigns(plaintextWithSequences: string): string {
    let returnString = plaintextWithSequences.split("\\'F6").join('ö');
    returnString = returnString.split("\\'DF").join('ß');
    returnString = returnString.split("\\'FC").join('ü');
    returnString = returnString.split("\\'E4").join('ä');
    returnString = returnString.split("\\'D6").join('Ä');
    returnString = returnString.split("\\'C4").join('Ä');
    returnString = returnString.split("\\'DC").join('Ü');
    return returnString;
  }

  /**@description Ersetzt HandwerksMergeFelder ( mergeFelder sehen bspw. so aus: \*MERGEFIELD PH142\Ansprechpartner (Anlage)\ ) durch Formatierten text innerhalb von
   * kursivem html
   */
  private static convertFormattedHandwerkTextToHtml(textInput: string): string {
    if (GlobalHelper.isNullOrUndefined(textInput) || textInput === '') {
      return '';
    }
    const patternStart = '\\*MERGEFIELD PH';
    const mergeFieldExpression = new RegExp(patternStart + '[0-9]*');
    const firstReplace = textInput.split(mergeFieldExpression).join('#~');
    const secondReplace = '\\#~\\';
    const splitAtBeginOfMergeField = firstReplace.split(secondReplace);
    const replacedStrings = [];
    for (const splitElementString of splitAtBeginOfMergeField) {
      const splitElementStringReplace = splitElementString.replace('\\', '}</b></i>');
      replacedStrings.push(splitElementStringReplace);
    }
    const htmlString = replacedStrings.join('<i><b>{');
    const htmlStringWithLineBreaks = htmlString.replace(/(?:\r\n|\r|\n)/g, '<br>');
    return htmlStringWithLineBreaks;
  }
}
