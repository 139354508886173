import * as uuid from 'uuid';
import { Anschrift } from '../../entities';
import { BaseAddress, UuidEntity } from '../../interfaces';
import { SyncObject } from '../models/SyncObject';
import { HWAddress } from './HWAddress';
import { HWFile } from './HWFile';
import { IndexedDBTypes } from './dbType';

export class HWObjectAddress extends IndexedDBTypes.DbType implements BaseAddress, UuidEntity, SyncObject {
  @IndexedDBTypes.KlassenName('HWObjectAddress') KlassenName: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: number;
  @IndexedDBTypes.IndexField('string') LfdNr: string;
  @IndexedDBTypes.IndexField('string') KuNr: string;
  @IndexedDBTypes.IndexField('string') Guid: string;
  @IndexedDBTypes.DataField('string') STANDORT1: string;
  @IndexedDBTypes.DataField('string') STANDORT2: string;
  @IndexedDBTypes.DataField('string') Name: string;
  @IndexedDBTypes.DataField('string') LAND: string;
  @IndexedDBTypes.DataField('string') PLZ: string;
  @IndexedDBTypes.DataField('string') STRASSE: string;
  @IndexedDBTypes.DataField('string') ORT: string;
  @IndexedDBTypes.DataField('string') Telefon: string;
  @IndexedDBTypes.DataField('string') BEMERKUNG: string;
  @IndexedDBTypes.DataField('string') Zusatz: string[];
  @IndexedDBTypes.DataField('number') Anfahrt: number;
  @IndexedDBTypes.DataField('number') KM: number;
  @IndexedDBTypes.DataField('string') Such: string;
  /** Nur für die alten HW Datenbanken unter der 73.0, damals wurden die Daten noch als Dokument gespeichert
   * Sind sonst nur noch in HWAdressen vorhanden und Werden beim sync direkt mit geholt
   * Die neuen HWDatenbanken speichern die Datein al Medien ab
   */
  @IndexedDBTypes.DataField('Files') Files: HWFile[] = [];
  @IndexedDBTypes.DataField('Data') addedToIdb: Date;
  @IndexedDBTypes.DataField('string') Anschrift: Anschrift;
  @IndexedDBTypes.DataField('HWAddress') Kunde: HWAddress;
  ADRTYP = 'O';

  //Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: HWObjectAddress, customerNumber?: string) {
    super();
    Object.assign(this, data);
    this.addTimeStampIfNotExisting();

    const guid = uuid.v4();
    if (!this.LfdNr) {
      this.setDefault();
      this.LfdNr = guid;
    }

    this.Anschrift = new Anschrift(this.STRASSE, this.PLZ, this.ORT);

    if (customerNumber) {
      this.KuNr = customerNumber;
    }
    if (!this.Guid) {
      this.Guid = guid;
    }
    if (!this.Files) {
      this.Files = [];
    }
  }

  static toString(): string {
    return 'HWObjectAddress';
  }

  getUuid(): string {
    return this.Guid;
  }

  getCustomerNumber(): string {
    return this.KuNr;
  }

  getLfdNr(): string {
    return this.LfdNr;
  }

  addCustomer(kunde: HWAddress): void {
    this.Kunde = kunde;
  }

  /**@description Wenn noch keiner vorhanden ist, wird der timestamp hinzugefügt */
  addTimeStampIfNotExisting(): void {
    if (this.addedToIdb) {
      return;
    }
    const now = new Date();
    this.addedToIdb = now;
  }

  /**@description Setzt alle Werte leer ( scheint wichtig, lässt man das weg, erkennt der Webservice das Objekt nicht mehr als Objektadresse und legt es nicht an) */
  setDefault(): void {
    this.LfdNr = '';
    this.KuNr = '';
    this.STANDORT1 = '';
    this.STANDORT2 = '';
    this.Name = '';
    this.LAND = 'D';
    this.PLZ = '';
    this.STRASSE = '';
    this.ORT = '';
    this.Telefon = '';
    this.BEMERKUNG = '';
    this.Zusatz = ['', '', '', '', ''];
    this.Anfahrt = 0;
    this.KM = 0;
  }
}
