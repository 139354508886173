import { RepairOrderStates } from '../../config';

export class RepairOrderStatus {
  stateName: string;
  stateColor: string;
  stateClass?: string;
  actionText: string;
  stateNumber: number;
  nextStates: number[];

  constructor(state: RepairOrderStates) {
    this.stateNumber = state;
    switch (state) {
      case RepairOrderStates.All: {
        this.stateName = 'Alle';
        this.stateColor = 'white';
        this.stateClass = 'default';
        break;
      }
      case RepairOrderStates.Open: {
        this.stateName = 'Offen';
        this.stateColor = '#FDA619';
        this.actionText = 'Offen';
        this.nextStates = [RepairOrderStates.Accepted, RepairOrderStates.Rejected, RepairOrderStates.InProgress];
        this.stateClass = 'open';
        break;
      }
      case RepairOrderStates.Accepted: {
        this.stateName = 'Angenommen';
        this.stateColor = '#01A00C';
        this.nextStates = [RepairOrderStates.Interrupted, RepairOrderStates.InProgress];
        this.actionText = 'Annehmen';
        this.stateClass = 'accepted';
        break;
      }
      case RepairOrderStates.Rejected: {
        this.stateName = 'Abgelehnt';
        this.stateColor = '#A00101';
        this.nextStates = [RepairOrderStates.Accepted];
        this.actionText = 'Ablehnen';
        this.stateClass = 'declined';
        break;
      }
      case RepairOrderStates.Interrupted: {
        this.stateName = 'Unterbrochen';
        this.stateColor = '#B800B1';
        this.nextStates = [RepairOrderStates.InProgress];
        this.actionText = 'Unterbrechen';
        this.stateClass = 'paused';
        break;
      }
      case RepairOrderStates.InProgress: {
        this.stateName = 'In Arbeit';
        this.stateColor = '#007dc1';
        this.nextStates = [RepairOrderStates.Interrupted];
        this.actionText = 'Beginnen';
        this.stateClass = 'working';
        break;
      }
      case RepairOrderStates.Completed: {
        this.stateName = 'Beendet';
        this.stateColor = '#FFF';
        this.actionText = 'Beenden';
        break;
      }
      case RepairOrderStates.Done: {
        this.stateName = 'Erledigt';
        this.stateColor = '#FFF';
        this.actionText = 'Erledigen';
        break;
      }
    }
  }

  static createAllStates(asFilter?: boolean): RepairOrderStatus[] {
    let wantedStates = [
      RepairOrderStates.Open,
      RepairOrderStates.Accepted,
      RepairOrderStates.Rejected,
      RepairOrderStates.Interrupted,
      RepairOrderStates.InProgress,
      RepairOrderStates.Completed,
      RepairOrderStates.Done,
    ];
    if (asFilter) {
      wantedStates = [
        RepairOrderStates.All,
        RepairOrderStates.Open,
        RepairOrderStates.Accepted,
        RepairOrderStates.Rejected,
        RepairOrderStates.Interrupted,
        RepairOrderStates.InProgress,
      ];
    }
    const states: RepairOrderStatus[] = [];
    for (const state of wantedStates) {
      states.push(new RepairOrderStatus(state));
    }
    return states;
  }

  /**@description Setzt die  der verfügbaren Folgestati anhand des gewählten */
  getAvailableStates(allStates: RepairOrderStatus[]): RepairOrderStatus[] {
    const nextStates = this.nextStates;
    const availableStates = allStates.filter(searchState => nextStates.includes(searchState.stateNumber));
    if (this.stateName === 'Unterbrochen') {
      availableStates[0].actionText = 'Fortführen';
    }
    return availableStates;
  }
}
