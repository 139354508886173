import { Component, OnInit } from '@angular/core';
import { DialogService, MeasurementHelper, RoutingService } from '@handwerk-pwa/shared';
import { Aufmass, MeasurementConstruct, RoomBook, RoomBookPosition } from '../../../entities';
import { MeasurementService, RoomBookService } from '../../../services';

@Component({
  selector: 'app-raum-edit',
  templateUrl: './raum-edit.component.html',
  styleUrls: ['./raum-edit.component.scss'],
})
export class RaumEditComponent implements OnInit {
  measurement: Aufmass;
  room: RoomBookPosition;
  measurementConstruct: MeasurementConstruct;
  roomBookEntries: RoomBook[];
  entriesExists: boolean;
  sums: {
    gross: number;
    net: number;
    difference: number;
    grossWalls: number;
    netWalls: number;
    differenceWalls: number;
  };
  measurementPath: string;

  constructor(
    private routingService: RoutingService,
    private measurementService: MeasurementService,
    private dialogService: DialogService,
    private roomBookService: RoomBookService,
  ) {}

  async ngOnInit(): Promise<void> {
    const guid = this.routingService.getRouteParam('uuid');
    const roomId = this.routingService.getRouteParam('roomId');
    this.measurement = await this.measurementService.findOneBy('Uuid', guid);
    this.measurementPath = `aufmass/${this.measurement.Uuid}/raumbuch`;
    const roomBookPositions = this.measurement.getRoomBookPosition();
    this.room = roomBookPositions.find(position => position.Uuid === roomId);
    this.roomBookEntries = await this.roomBookService.getAllRoomBooksByPosition(this.room, this.measurement);
    this.entriesExists = !!this.room.reconstructMeasurementConstruct();
    if (this.roomBookEntries?.length > 0) this.sums = MeasurementHelper.calculateGrossAndNetSums(this.roomBookEntries);
  }

  async drawGroundPlan(): Promise<void> {
    if (this.entriesExists) {
      const confirmation = await this.dialogService.openConfirmDialog(
        'Achtung',
        'Es existieren bereits Positionen. Wollen Sie wirklich einen neuen Grundriss erstellen? Vorhandene Daten werden überschrieben.',
        'Erstellen',
        'Abbrechen',
        false,
      );
      if (!confirmation) return;
    }

    void this.routingService.navigateTo(`aufmass/grundriss/${this.measurement.Uuid}/${this.room.Uuid}`);
  }

  async editMeasurement(): Promise<void> {
    const confirmation = await this.dialogService.openConfirmDialog(
      'Achtung',
      'Einzelaufmaße die nicht Teil des ursprünglichen Grundrisses waren, gehen hierbei verloren.',
      'Editieren',
      'Abbrechen',
      false,
    );
    if (!confirmation) return;
    void this.routingService.navigateTo(`aufmass/messung/${this.measurement.Uuid}/${this.room.Uuid}`);
  }

  gotoDimensionChain(dimensionChain: RoomBook): void {
    const dimensionChainId = dimensionChain?.Uuid ? dimensionChain.Uuid : 'neu';
    void this.routingService.navigateTo(
      `aufmass/${this.measurement.Uuid}/raum/${this.room.Uuid}/masskette/${dimensionChainId}`,
    );
  }
}
