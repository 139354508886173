import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthorizationService, DialogService, GlobalHelper, RightsService, RoutingService } from '@handwerk-pwa/shared';
import {
  PageSetting,
  featureUnavailableLong,
  featureUnavailableShort,
  noLicenceLong,
  noLicenceShort,
  noRightLong,
  noRightShort,
} from './config';
import { ActivityTrackerService, PageSettingService, StateService } from './services';

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService {
  private defaultPage = 'startseite';
  private loginPage = 'Login';

  constructor(
    private routingService: RoutingService,
    private rightsService: RightsService,
    private authorizationService: AuthorizationService,
    private dialogService: DialogService,
    private stateService: StateService,
    private activityTrackerService: ActivityTrackerService,
    private pageSettingService: PageSettingService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const isLoggedIn = this.stateService.loggedIn.value;
    const isAuthPage = [
      'Login/UUID/:uuid/Mandant/:mandant/Employeenumber/:employeeNumber/Branding/:branding',
      'Login',
    ].includes(route.routeConfig.path);
    const isNoLoginNeededPage = ['impressum'].includes(route.routeConfig.path);

    if (isLoggedIn && isAuthPage) {
      await this.routingService.navigateTo(this.defaultPage);
      return false;
    }

    if (!isLoggedIn && !isAuthPage && !isNoLoginNeededPage) {
      await this.routingService.navigateTo(this.loginPage);
      return false;
    }

    const pageSetting = this.getPageSetting(route);

    const hasRight = GlobalHelper.isNullOrUndefined(pageSetting) ? true : pageSetting.hasRight;

    const hasFeature = this.checkFeatureAvailable(pageSetting);

    const hasLicence = this.checkLicenceForPage(pageSetting);

    await this.notifyUserOnError(hasRight, hasFeature, hasLicence);

    const continueNavigation = hasRight && hasFeature && hasLicence;
    if (!continueNavigation) {
      await this.routingService.navigateTo(this.defaultPage);
    }

    await this.privateCountActivityUp(route);

    return continueNavigation;
  }

  /**@description Benachrichtigt den Nutzer über einen Fehler, bei mehreren nur den Schwerwiegensten */
  private async notifyUserOnError(hasRight: boolean, hasFeature: boolean, hasLicence: boolean): Promise<void> {
    if (hasRight && hasFeature && hasLicence) return;
    if (hasLicence && hasFeature) {
      await this.dialogService.openErrorMessage(noRightShort, noRightLong);
    }
    if (!hasLicence) {
      await this.dialogService.openErrorMessage(noLicenceShort, noLicenceLong);
    }
    if (!hasFeature) {
      await this.dialogService.openErrorMessage(featureUnavailableShort, featureUnavailableLong);
    }
  }

  private getPageSetting(route: ActivatedRouteSnapshot): PageSetting {
    const currentPath = route.routeConfig.path;
    const pageSetting = this.pageSettingService.currentPageSettings.value?.find(
      setting => setting.path === currentPath,
    );
    return pageSetting;
  }

  /**@description Prüft ob das Feature möglich ist aufgrund des aktuellen Stands */
  private checkFeatureAvailable(pageSetting: PageSetting): boolean {
    const authorization = this.authorizationService.current.getValue();
    if (pageSetting) return authorization.featureCheck(pageSetting.featureName).available;
    return true;
  }

  /**@description Ist die Lizenz an, true, ansonsten wird geprüft ob, sie gebraucht wird*/
  private checkLicenceForPage(pageSetting: PageSetting): boolean {
    const licenseActive = this.rightsService.isPremium();
    if (licenseActive) return true;
    if (pageSetting?.licenseNeeded) return false;
    return true;
  }

  private async privateCountActivityUp(route: ActivatedRouteSnapshot): Promise<void> {
    const currentRoute = route.routeConfig.path;

    if (currentRoute === 'Login' || currentRoute === 'startseite' || currentRoute === '') return null;

    const loggedIn = this.stateService.loggedIn.value;
    if (!loggedIn) return;

    const activity = await this.activityTrackerService.getCurrentActivityFromIDB();
    if (currentRoute.includes('adressen')) activity.CallsAddresses++;
    else if (currentRoute.includes('kalender')) activity.CallsCalendar++;
    else if (currentRoute.includes('entdecken')) activity.CallsDiscover++;
    else if (currentRoute.includes('wartungsauftraege')) activity.CallsMaintenanceOrders++;
    else if (currentRoute.includes('wartungsanlagen')) activity.CallsMaintenanceSystems++;
    else if (currentRoute.includes('reparaturauftraege')) activity.CallsRepairOrder++;
    else if (currentRoute.includes('nachrichten')) activity.CallsMessages++;
    else if (currentRoute.includes('reparaturauftraege')) activity.CallsRepairOrder++;
    else if (currentRoute.includes('aufmasse')) activity.CallsRoomMeasurement++;
    else if (currentRoute.includes('belege')) activity.CallsSupportingDocuments++;
    await this.activityTrackerService.saveActivityToIDB(activity);
  }
}
