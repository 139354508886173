import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { newPageCloseButton } from '../../config';
import { HWFile, Medien } from '../../entities';

@Component({
  selector: 'app-bss-picture-popup',
  templateUrl: './bss-picture-popup.component.html',
  styleUrls: ['./bss-picture-popup.component.scss'],
})
export class BssPicturePopupComponent implements OnChanges {
  @Input() InputFile: HWFile | Medien;
  @Input() showPopup = false;
  @Input() newVersion: boolean;
  @Output() closePopupEvent = new EventEmitter<boolean>();
  description: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.InputFile) {
      this.setDescription();
    }
  }

  setDescription() {
    this.description = this.newVersion ? (this.InputFile as Medien).Beschreibung : undefined;
  }

  openInFullscreen(InputFile: HWFile | Medien): void {
    const newPicWindow = window.open('');
    setTimeout(() => {
      newPicWindow.document.write(
        newPageCloseButton +
          `<img style="-webkit-user-select: none;max-width: 100%;margin: auto;" src="${InputFile.Data}">`,
      );
    }, 0);
  }

  closePopup(): void {
    this.closePopupEvent.emit(true);
  }
}
