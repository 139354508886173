import { GlobalHelper } from '../../../../helper';
import { Module } from './RightCategory';

export class AddressRights extends Module {
  showCustomers = true;
  editCustomers = true;
  showSuppliers = true;
  editSuppliers = true;
  showEmployees = true;
  editEmployees = true;
  showObjectAddresses = true;
  editObjectAddresses = true;
  showFreeAddresses = true;
  editFreeAddresses = true;
  showOpenPositions = true;
  showTurnover = true;

  constructor(addressRights: AddressRights) {
    super();
    GlobalHelper.assignIfPropertyExists(this, addressRights);
  }

  restrictEverything(): void {
    super.restrictEverything();
    // showCustomers always has to stay on for sync reasons
    this.showCustomers = true;
  }
}
