import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@handwerk-pwa/shared';
import { addressContactPersonSearch } from '../../../config';
import { AddressService, ContactPersonService } from '../../../services';

@Component({
  selector: 'app-address-contact-person',
  templateUrl: './address-contact-person.component.html',
  styleUrls: ['./address-contact-person.component.scss'],
})
export class AddressContactPersonComponent implements OnInit {
  searchExpressions = addressContactPersonSearch;
  contactPersons = [];

  constructor(
    private contactPersonService: ContactPersonService,
    private routingService: RoutingService,
    private addressService: AddressService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadContactPersons();
  }

  private async loadContactPersons(): Promise<void> {
    const addressGuid = this.routingService.getRouteParam('guid');
    const address = await this.addressService.findOneBy('Guid', addressGuid);
    this.contactPersons = await this.contactPersonService.getAllBy('KuNr', address.KU_NR, true);
  }
}
