<div class="content-block login">
  <div *ngIf="!qrEnabled" class="form-block">
    <div *ngIf="isProduction" class="image-container">
      <img src="../../assets/images/LoginLogo.svg" class="header-image" />
    </div>

    <div *ngIf="!isProduction" class="devLogins">
      <div class="devLoginsRow">
        <div class="devLoginItem">
          <button
            class="loginButton"
            (click)="createUserInfoFromLogindata('8efa5c0c-2611-450d-b4c9-06e26e0b2c90', '90001', '000', '00900')"
          >
            AP
          </button>
        </div>
        <div class="devLoginItem">
          <button
            class="loginButton"
            (click)="createUserInfoFromLogindata('956728bc-30bc-4b7f-9c7f-f3f803ec866c', '90001', '000', '00900')"
          >
            AS
          </button>
        </div>
      </div>
      <div class="devLoginsRow">
        <div class="devLoginItem">
          <button
            class="loginButton"
            (click)="createUserInfoFromLogindata('bb223e7b-0670-46fe-8a84-af0e25487744', '90001', '000', '00900')"
          >
            CS
          </button>
        </div>
        <div class="devLoginItem">
          <button
            class="loginButton"
            (click)="createUserInfoFromLogindata('a19e9fa0-c8b5-48b0-9322-f66ccd8d8a69', '90001', '1111', '00902')"
          >
            DK
          </button>
        </div>
      </div>
      <div class="devLoginsRow">
        <div class="devLoginItem">
          <button
            class="loginButton"
            (click)="createUserInfoFromLogindata('5017093a-3ef8-48e0-aa4b-ef7fa4ee4695', '90001', '1111', '00902')"
          >
            TK
          </button>
        </div>
      </div>
      <div class="devLoginsRow">
        <div class="devLoginItem">
          <button class="loginButton" (click)="logOut()">Logout(Dev)</button>
        </div>

        <div class="devLoginItem">
          <button
            class="loginButton"
            (click)="createUserInfoFromLogindata('aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa', '01000', '000', '00001')"
          >
            Login kaputter Webservice-Kunde
          </button>
        </div>
      </div>
    </div>

    <button
      class="loginButton demoButton"
      (click)="createUserInfoFromLogindata('b542ca02-7a85-4fa9-a8b3-cd0be3e78e97', '50003', 'bssh', '00001')"
    >
      DEMO
    </button>
    <button class="loginButton" (click)="activateQrScan()">Mitarbeitercode scannen</button>
    <button class="loginButton" (click)="manuellLogin = true">Manueller Login</button>
  </div>
</div>

<app-qrcode *ngIf="qrEnabled" (scanResult)="qrLogin($event)" (stopEvent)="scanStop($event)"> </app-qrcode>

<!-- Manuelles Login -->
<app-manuell-login
  [showPopup]="manuellLogin"
  [userInfo]="userInfo"
  (cancled)="manuellLogin = false"
></app-manuell-login>
