import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DialogService, RecursiveHelper, RoutingService } from '@handwerk-pwa/shared';
import { DxTreeViewComponent, DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { Item } from 'devextreme/ui/tree_view';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  noLicenceLong as noLicenseLong,
  noLicenceShort as noLicenseShort,
  noRightLong,
  noRightShort,
} from '../../../config';
import { NavigationItem } from '../../../entities';
import { NavigationItemService, SyncService } from '../../../services';

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss'],
})
export class SideNavigationMenuComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild(DxTreeViewComponent, { static: true })
  menu: DxTreeViewComponent;
  @Output() selectedItemChanged = new EventEmitter<string>();
  @Input() selectedItem: string;
  newThings: BehaviorSubject<boolean>;
  newThingsSub: Subscription;
  navigationItems: BehaviorSubject<NavigationItem[]>;
  private menuOpened$: Subscription;

  constructor(
    private routingService: RoutingService,
    private dialogService: DialogService,
    private navigationItemService: NavigationItemService,
    private syncService: SyncService,
    private changeDetectorRef: ChangeDetectorRef,
    private recursiveHelper: RecursiveHelper,
  ) {
    this.navigationItems = this.navigationItemService.currentNavigationItems;
  }

  ngAfterViewInit(): void {
    this.newThings = this.syncService.newThingsDiscovered;
    this.newThingsSub = this.newThings.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
    this.menuOpened$ = this.routingService.menuOpen.subscribe(openState => {
      this.toggleModuleEntries(openState);
    });
  }

  ngOnDestroy(): void {
    this.menuOpened$?.unsubscribe();
    this.newThingsSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedItemChange = changes.selectedItem;
    if (selectedItemChange) {
      const currentItemPath = selectedItemChange.currentValue as string;
      this.markSelectedItem(currentItemPath);
      return;
    }
  }

  onItemClick(item: NavigationItem | Item): void {
    if (!item.hasRight) {
      void this.dialogService.openErrorMessage(noRightShort, noRightLong);
      this.routingService.nextMenuState(false);
    }
    if (!item.hasLicense) {
      void this.dialogService.openErrorMessage(noLicenseShort, noLicenseLong);
      this.routingService.nextMenuState(false);
    }
    const menuOpen = this.routingService.menuOpen.getValue();
    if (!menuOpen) {
      this.routingService.menuOpen.next(true);
      return;
    }

    const path = item.path;
    this.selectedItemChanged.emit(path);
    return;
  }

  /**@description Marks the menu Item, so that its color changes */
  private markSelectedItem(currentItemPath: string): void {
    const flatNavigationItems = this.recursiveHelper.recursiveFlatMap(this.navigationItems.value, 'items');
    const markItem = flatNavigationItems.find(e => e.path === currentItemPath);
    if (!markItem) return;
    this.menu?.instance?.selectItem(flatNavigationItems.find(e => e.path === currentItemPath));
  }

  /**
   * @description When the menu is being closed all sub menus should be closed
   * otherwise it will leave blank spaces in the side menu
   * When it is being opened it should open all parent menus
   */
  private toggleModuleEntries(openState: boolean): void {
    const currentRoute = this.routingService.getCurrentRoute();
    if (!openState) {
      this.menu.instance.collapseAll();
      return;
    }

    const navigationItems = this.recursiveHelper.recursiveFlatMap(this.navigationItems.value, 'items');
    const navigationItem = navigationItems.find(e => e.path === currentRoute);

    const itemsToExpand = this.getParents(navigationItems, navigationItem);

    const mappedMenu = navigationItems.map(item => {
      if (itemsToExpand.includes(item)) {
        item.isExpanded = true;
        return item;
      } else {
        item.isExpanded = false;
        return item;
      }
    });

    this.navigationItems.next(this.recursiveInsert(mappedMenu));
  }

  /**
   * @description Gets all the parents recursively by text
   * @returns The recursive parents sorted by the
   */
  private getParents(allNavigationItems: NavigationItem[], searchItem: NavigationItem): NavigationItem[] {
    // Filter out all Items that don't contain sub items
    allNavigationItems = allNavigationItems.filter(e => e.items.length !== 0);

    const parents: NavigationItem[] = [];

    let parent: NavigationItem;
    do {
      parent = allNavigationItems.find(navigationItem =>
        navigationItem.items?.some(subItems => subItems?.text === searchItem?.text),
      );

      if (parent) {
        parents.push(parent);
        searchItem = parent;
      }
    } while (parent);

    return parents;
  }

  /**
   * @description undoes the changes from the recursiveFlatMap
   */
  private recursiveInsert(flattenedValues: NavigationItem[]): NavigationItem[] {
    for (let i = flattenedValues.length - 1; i >= 0; i--) {
      const value = flattenedValues[i];

      const parents = this.getParents(flattenedValues, value);
      if (parents.length === 0) continue;

      const parent = parents[0];
      const parentIndex = flattenedValues.findIndex(e => e.text === parent.text);
      const subItemIndex = parent.items.findIndex(item => item.text === value.text);
      flattenedValues[parentIndex].items[subItemIndex] = value;

      flattenedValues = flattenedValues.filter(item => item !== value);
    }
    return flattenedValues;
  }
}
@NgModule({
  imports: [DxTreeViewModule, CommonModule],
  declarations: [SideNavigationMenuComponent],
  exports: [SideNavigationMenuComponent],
})
export class SideNavigationMenuModule {}
