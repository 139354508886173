import { Component, Input } from '@angular/core';
import { RoomBookPosition, RoomTemplate } from '../../../../entities';

@Component({
  selector: 'app-room-card',
  templateUrl: './room-card.component.html',
  styleUrls: ['./room-card.component.scss'],
})
export class RoomBookCardComponent {
  @Input() room: RoomTemplate | RoomBookPosition;
}
