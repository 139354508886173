import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, GlobalHelper, RoutingService, TimeHelper, UserInfo } from '@handwerk-pwa/shared';
import config from 'devextreme/core/config';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { DxDateType, RepairOrderStates, deDateTimeFormat } from '../../../config';
import { HWAddress, HWObjectAddress, HWRepairOrder, RepairOrderStatus } from '../../../entities';
import {
  ActivityTrackerService,
  AddressService,
  GlobalSettingService,
  ObjectaddressService,
  RepairOrderService,
} from '../../../services';

@Component({
  selector: 'app-repair-order-new',
  templateUrl: './repair-order-new.component.html',
  styleUrls: ['./repair-order-new.component.scss'],
})
export class RepairOrderNewComponent implements OnInit, OnDestroy {
  kundenListe: HWAddress[] = [];
  kunde: HWAddress;
  objektadressListe: HWObjectAddress[];
  objektadresse: HWObjectAddress;
  block: boolean;
  userInfo: UserInfo;
  subscription: Subscription;
  order: HWRepairOrder;
  newDate = new Date();
  minEnde: Date;
  maxEnde: Date;
  currentEndTime: Date;
  repairOrderStates = RepairOrderStates;
  dateType = DxDateType;

  constructor(
    private globalSettingService: GlobalSettingService,
    private addressService: AddressService,
    private objectAddressService: ObjectaddressService,
    private repairOrderService: RepairOrderService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private activityTrackerService: ActivityTrackerService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.configFloatingActionButton();
    this.kundenListe = await this.addressService.getAllBy('ADRTYP', 'K');
    this.userInfo = await this.globalSettingService.getUserInfo();
    this.order = new HWRepairOrder(null, null, true);
    this.order.createDefault(this.userInfo.monteur);
    this.parseMinFromAppointment(new Date(), false);
    this.subscription = this.routingService.save.subscribe(() => {
      void this.saveNewRepairOrder(10);
    });
    await this.checkPreselectedAddress(this.kundenListe);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    config({
      floatingActionButtonConfig: { icon: 'add', shading: true },
    });
  }

  async onKundeChange(userInfo: UserInfo, kunde: HWAddress): Promise<void> {
    if (GlobalHelper.isNullOrUndefined(kunde) || kunde === this.kunde) return;
    this.objektadresse = undefined;
    this.kunde = kunde;
    await this.getObjectAddressList(userInfo, kunde);
    this.handlePossibleDeliveryStop(kunde);
  }

  createNewAddress(): void {
    void this.routingService.navigateTo('/adresse/neu/repairOrder');
  }

  checkForMissingData(kunde: HWAddress): boolean {
    if (GlobalHelper.isNullOrUndefined(kunde)) {
      this.dialogService.openInformDialog('Fehler', 'Sie haben noch keinen Kunden ausgewählt.', 'OK');
      return true;
    }
    const kundenNummer = kunde.KU_NR;
    if (kundenNummer === '-1') {
      this.dialogService.openInformDialog(
        'Fehler',
        'Für nicht übertragene neue Kunden können keine Reparaturaufträge eingestellt werden',
        'OK',
      );
      return true;
    }
    return false;
  }

  async saveNewRepairOrder(status: number): Promise<void> {
    if (this.block === true) return;
    const kunde = this.kunde;
    const order = this.order;
    const missingData = this.checkForMissingData(kunde);
    if (missingData) return;

    // Auftrag
    void this.dialogService.openLoadingDialog('Auftragserstellung', 'Auftrag wird angelegt und synchronisiert...');
    order.addKundeninformation(kunde);
    order.addAuftragsortInformation(kunde, this.objektadresse);
    order.StatusObject = new RepairOrderStatus(status);
    order.Status = status;
    if (status === RepairOrderStates.InProgress) order.addArbeitszeit();
    await this.activityTrackerService.countUpAutoSyncs();
    const newRepairOrder = await this.repairOrderService.addRepairOrder(order);
    if (GlobalHelper.isNullOrUndefined(newRepairOrder) || newRepairOrder.Nummer === '-1') return;
    this.dialogService.closeLoadingDialog();
    this.routingService.dataChanged.next(false);
    await this.routingService.navigateTo(`/reparaturauftrag/${newRepairOrder.Guid}/edit`);
  }

  /**
   * @description Wenn sich der Termin ändert - um anschließend das MinDatum(Uhrzeit zu Parsen),
   */
  parseMinFromAppointment(termin: Date, change = true): void {
    if (change) this.order.Termin = moment(termin).format(deDateTimeFormat);
    const minDate = new Date(termin);
    this.minEnde = minDate;
    const maxDate = new Date(termin);
    maxDate.setHours(23, 59, 59);
    this.maxEnde = maxDate;
    this.currentEndTime = this.repairOrderService.getEndTimeDate(termin, this.order);
  }

  getEndTimeDate(termin: Date, endzeit: string): Date {
    const endTimeDate = new Date(termin);
    const split = endzeit?.split(':');
    if (split?.length !== 2) return endTimeDate;
    endTimeDate.setHours(parseInt(split[0], 10), parseInt(split[0], 10));
    if (endTimeDate < termin) {
      this.order.Endzeit = `${TimeHelper.padTime(termin.getHours())}:${TimeHelper.padTime(termin.getMinutes())}`;
      return termin;
    }
    return endTimeDate;
  }

  private configFloatingActionButton(): void {
    config({
      floatingActionButtonConfig: { icon: 'check', shading: true },
    });
  }

  private handlePossibleDeliveryStop(kunde: HWAddress): void {
    const lieferSperre = kunde.LIEFERSPERRE;
    if (lieferSperre)
      this.dialogService.openInformDialog('Liefersperre', 'Für diesen Kunden ist eine Liefersperre hinterlegt.', 'OK');
  }

  /**@description Holt die Objektadresse aus der IDB und guckt ob sie Nicht leer ist um die auswahl freizuschalten */
  private async getObjectAddressList(userInfo: UserInfo, kunde: HWAddress): Promise<void> {
    this.objektadressListe = await this.objectAddressService.getObjektAddressesForKundennummerByChoice(
      userInfo,
      kunde.KU_NR,
      true,
    );
  }

  private async checkPreselectedAddress(kundenListe: HWAddress[]): Promise<void> {
    if (!this.routingService.getCurrentRoute().includes('neu')) return;
    const addressType = this.routingService.getRouteParam('address_type');
    const addressGuid = this.routingService.getRouteParam('guid');
    if (addressType === 'objektadresse') {
      const objectAdresse = await this.objectAddressService.findOneBy('Guid', addressGuid);
      this.objektadresse = objectAdresse;
      this.kunde = objectAdresse.Kunde;
      return;
    }
    if (addressType === 'adresse') this.kunde = kundenListe?.find(findKunde => findKunde.Guid === addressGuid);
    if (this.kunde) {
      this.handlePossibleDeliveryStop(this.kunde);
      await this.getObjectAddressList(this.userInfo, this.kunde);
    }
  }
}
