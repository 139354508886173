import { Injectable } from '@angular/core';
import {
  AuthorizationService,
  DialogService,
  FeatureNames,
  GlobalHelper,
  RestService,
  UserInfo,
} from '@handwerk-pwa/shared';
import { HWFile, HWObjectAddress, Medien, SyncObject } from '../../entities';
import { ControllerService } from '../../services';
import { ConnectionDialogues, ConnectionService } from '../globalServices/connection.service';
import { AddressService } from './address.service';
import { BaseService } from './base.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class ObjectaddressService implements DataService {
  serviceName = 'ObjectaddressService';
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private dialogService: DialogService,
    private addressService: AddressService,
    private baseService: BaseService,
    private connectionService: ConnectionService,
    private authorizationService: AuthorizationService,
  ) {}

  /**
   * @description Holt eine Adressen aus der IDB mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden sol
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async findOneBy(selector: string, value: string): Promise<HWObjectAddress> {
    return await this.baseService.findOneBy(HWObjectAddress, selector, value);
  }

  async getAllBy(selector: string, value: string): Promise<HWObjectAddress[]> {
    return await this.baseService.getAllBy(HWObjectAddress, selector, value);
  }

  async getAll(): Promise<HWObjectAddress[]> {
    return await this.baseService.getAll(HWObjectAddress);
  }

  /**@description Holt eine Objektadresse anhand der angaben zu Kundenummer und LFdnr */
  async getSpecificObjectaddressFromIDB(kundenNummer: string, lfdnr: string): Promise<HWObjectAddress> {
    return await this.baseService.findOneByTwoSelector(HWObjectAddress, 'KuNr', kundenNummer, 'LfdNr', lfdnr);
  }

  /**@description Nimmt die Adressen aus der IDB oder vom Webservice, je nach Konditionen */
  async getObjektAddressesForKundennummerByChoice(
    userInfo: UserInfo,
    kundenNummer: string,
    silent = false,
  ): Promise<HWObjectAddress[]> {
    const currentObjectAddresses = await this.getAllBy('KuNr', kundenNummer);
    const isOnline = await this.connectionService.checkOnline(ConnectionDialogues.GetData);
    if (!isOnline) return currentObjectAddresses;

    const liveObjectAddresses = await this.getObjektAddressesForKundennummerFromWebservice(
      userInfo,
      kundenNummer,
      silent,
    );
    return liveObjectAddresses;
  }

  /**@description Überschreibt die jeweilige Adresse lokal in der IDB */
  async updateDocumentsInAddressLocally(documents: HWFile[]): Promise<void> {
    for (const document of documents) {
      const kundenNummer = document.Kundennummer;
      const lfdNr = document.LfdNr;
      const objectAddress = await this.getSpecificObjectaddressFromIDB(kundenNummer, lfdNr);
      if (!objectAddress) continue;
      documents.forEach(file => objectAddress.Files.unshift(file));
      await this.deleteAddressInIDB(objectAddress);
      await this.writeObjectAddressesToIDB([objectAddress], false);
    }
  }

  /**@description Überschreibt das Dokument innerhalb einer Adresse in der IDB */
  async updateDocumentInAddress(bssFile: HWFile): Promise<void> {
    const kundenNummer = bssFile.Kundennummer;
    const lfdNr = bssFile.LfdNr;
    const objectAddress = await this.getSpecificObjectaddressFromIDB(kundenNummer, lfdNr);
    if (!objectAddress) return;
    let index = 0;
    for (const tempFile of objectAddress.Files) {
      if (tempFile.Name === bssFile.Name) {
        objectAddress.Files[index] = bssFile;
        break;
      }
      index++;
    }
    await this.writeObjectAddressesToIDB([objectAddress], false);
  }

  /**@description Sendet die Objektadresse an den Webservice und somit ans Backend (Lfdnr ist länger als 5 Zeichen => dann erstellt er eine neue, sonst update)
   * Möchte man also eine neue Objektadresse anlegen, setzt man Lfdnr bspw. auf xxxxxx - das Backend gibt eine neue Lfdnr
   */
  async sendAddressToWebservice(objectAddressInput: HWObjectAddress, silent = false): Promise<void> {
    const targetUrl = 'SetObjAdresse';
    const response = await this.restService.returnData<HWObjectAddress>(targetUrl, objectAddressInput, silent);
    if (!response) {
      await this.writeObjectAddressesToIDB([objectAddressInput], false);
      return;
    }
    const objectAddress = new HWObjectAddress(response);
    objectAddress.Kunde = objectAddressInput.Kunde;
    if (objectAddressInput.Files) objectAddress.Files = objectAddressInput.Files;
    await this.replaceAddressInIDB(objectAddress);
  }

  async resetImagesInIDB(): Promise<void> {
    const allObjectAddresses = await this.getAll();
    const addressesWithFiles = allObjectAddresses.filter(object => object.Files.length > 0);
    const addressesWithoutFiles = allObjectAddresses.filter(object => object.Files.length === 0);
    for (const fileAddress of addressesWithFiles) {
      for (const file of fileAddress.Files) file.Data = null;
    }
    await this.writeObjectAddressesToIDB(addressesWithFiles, true);
    await this.writeObjectAddressesToIDB(addressesWithoutFiles, false);
  }

  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Objektadressen...');
    await this.pushToWebService();
    await this.getFromWebService(userInfo, silent);
  }

  /**@description Holt alle Objekt Adressen */
  async getFromWebService(userInfo: UserInfo, silent: boolean): Promise<void> {
    const objectAddresses: HWObjectAddress[] = [];
    const targetUrl = `ObjektAdr/mandant/${userInfo.mandant}/username/${userInfo.monteur}/GEAENDERT/${userInfo.geaendert}`;
    const allObjectAddressesData = await this.restService.returnData<HWObjectAddress[]>(targetUrl, null, silent);
    if (GlobalHelper.isNullOrUndefined(allObjectAddressesData)) {
      return;
    }
    const hasNewDatabase = this.authorizationService.current.value.featureCheck(FeatureNames.mediaTable2).available;
    for (const objectAddressData of allObjectAddressesData) {
      const objectAddress = new HWObjectAddress(objectAddressData);
      const customer = await this.addressService.findOneBy('KU_NR', objectAddress.KuNr);
      objectAddress.addCustomer(customer);
      // Old method below version 73.0 saves the files as documents
      if (!hasNewDatabase) objectAddress.Files = GlobalHelper.hWFileArrayGetUniques(objectAddress.Files);
      objectAddresses.push(objectAddress);
    }
    await this.writeObjectAddressesToIDB(objectAddresses, true);
  }

  /**@description Baut aus den Daten von neu anzulegenden Adressen Adressen und verschickt diese  */
  async pushToWebService(): Promise<void> {
    const allObjectAddresses = await this.getAll();
    const unpushedElements = allObjectAddresses.filter(object => object.KuNr.length > 10); // lfdnr größer 10? dann uuid und ungepusht
    const response = [];
    for (const unpushedElement of unpushedElements) {
      response.push(this.sendAddressToWebservice(unpushedElement, true));
    }
    await Promise.all(response);
  }

  getRequiredObjects(): SyncObject[] {
    return [HWFile, Medien];
  }

  private async getObjektAddressesForKundennummerFromWebservice(
    userInfo: UserInfo,
    kundenNummer: string,
    silent = false,
  ): Promise<HWObjectAddress[]> {
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole Objektadressen...');
    const objektAdressen: HWObjectAddress[] = [];
    const targetEndpoint = `ObjektadresseForKundennummer/mandant/${userInfo.mandant}/kundennummer/${kundenNummer}`;
    const objectAdressData = await this.restService.returnData<HWObjectAddress[]>(targetEndpoint);
    if (GlobalHelper.isNullOrUndefined(objectAdressData)) {
      await this.replaceAllObjectAddressesOfAddressInIDB([], kundenNummer);
      this.dialogService.closeLoadingDialog();
      return objektAdressen;
    }
    const kundeZuObjekt = await this.addressService.findOneBy('KU_NR', kundenNummer);
    for (const data of objectAdressData) {
      const objektAdresse = new HWObjectAddress(data);
      objektAdresse.addCustomer(kundeZuObjekt);
      objektAdresse.Files = GlobalHelper.hWFileArrayGetUniques(objektAdresse.Files);
      objektAdressen.push(objektAdresse);
    }
    await this.replaceAllObjectAddressesOfAddressInIDB(objektAdressen, kundenNummer);
    if (!silent) this.dialogService.closeLoadingDialog();
    return objektAdressen;
  }

  private async deleteAddressInIDB(objectAddress: HWObjectAddress): Promise<void> {
    await this.controllerService.deleteData('HWObjectAddress', 'Guid', objectAddress.Guid);
  }

  /**@description Schreibt die ObjectAddresses in die IDB */
  private async writeObjectAddressesToIDB(objectAddresses: HWObjectAddress[], clear: boolean): Promise<void> {
    if (clear) {
      await this.controllerService.clearStore('HWObjectAddress');
    }
    await this.controllerService.setData('HWObjectAddress', objectAddresses);
  }

  /**@description Löscht zu einer Kundennummer alle Objektadressen und ersetzt Sie mit dem aktuellen Satz */
  private async replaceAllObjectAddressesOfAddressInIDB(
    objectAddress: HWObjectAddress[],
    kundenNummer: string,
  ): Promise<void> {
    await this.controllerService.deleteData('HWObjectAddress', 'KuNr', kundenNummer);
    await this.writeObjectAddressesToIDB(objectAddress, false);
  }

  /**@description Überschreibt eine Objektadresse in der IDB */
  private async replaceAddressInIDB(objectaddress: HWObjectAddress): Promise<void> {
    const exists = await this.controllerService.getData<HWObjectAddress>('HWObjectAddress', objectaddress.Guid, 'Guid');
    if (exists) {
      await this.controllerService.deleteData('HWObjectAddress', 'Guid', objectaddress.Guid);
    }
    await this.writeObjectAddressesToIDB([objectaddress], false);
  }
}
