import { Injectable } from '@angular/core';
import { DialogService, GlobalHelper, RestService, RightsService, UserInfo } from '@handwerk-pwa/shared';
import { GlobalSettings } from '../../config';
import {
  Aufmass,
  HWAddress,
  MeasurementBaseData,
  MeasurementFormulaRaw,
  Medien,
  Projekt,
  RoomBookPosition,
  SyncObject,
} from '../../entities';
import { ControllerService } from '../globalServices/controller.service';
import { GlobalSettingService } from '../globalServices/globalSetting.service';
import { BaseService } from './base.service';
import { DataService } from './data.service';
import { RoomBookService } from './roomBook.service';
import { RoomNameService } from './roomName.service';
import { RoomTemplateService } from './roomTemplate.service';
@Injectable({
  providedIn: 'root',
})
export class MeasurementService implements DataService {
  serviceName = 'MeasurementService';
  constructor(
    private baseService: BaseService,
    private controllerService: ControllerService,
    private dialogService: DialogService,
    private restService: RestService,
    private roomBookService: RoomBookService,
    private roomNameService: RoomNameService,
    private roomTemplateService: RoomTemplateService,
    private rightsService: RightsService,
    private globalSettingService: GlobalSettingService,
  ) {}

  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    await this.pushToWebService();
    await this.getFromWebService(userInfo, silent);
  }

  async getFromWebService(userInfo: UserInfo, silent: boolean): Promise<void> {
    const license = this.rightsService.getCurrentRight().employeeRights.showObjektadressen;
    if (!license) return;
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole Aufmaß Grunddaten...');
    const targetUrl = 'AufmassGrunddaten';
    await this.pushToWebService();
    const responseData = await this.restService.returnData<MeasurementBaseData>(targetUrl, userInfo);
    if (!responseData) return;
    const measurementBaseData = new MeasurementBaseData(responseData);
    await this.roomNameService.overrideLocal(measurementBaseData.Raumnamen);
    await this.roomTemplateService.overrideLocal(measurementBaseData.Raumvorlagen);
    await this.overrideAllLocal(measurementBaseData.Aufmasse);
    await this.getFormulasFromWebService(userInfo, silent);
  }

  public async overrideAllLocal(measurements: Aufmass[]): Promise<void> {
    await this.controllerService.clearStore('Aufmass');
    await this.controllerService.setData('Aufmass', measurements);
  }

  public async getAll(): Promise<Aufmass[]> {
    const all = await this.baseService.getAll(Aufmass);
    const sorted = all.sort((a, b) => GlobalHelper.compareFunction(a.AufmId, b.AufmId, true));
    return sorted;
  }

  public async getAllBy(selector: string, value: string): Promise<Aufmass[]> {
    const all = await this.baseService.getAllBy(Aufmass, selector, value);
    const sorted = all.sort((a, b) => GlobalHelper.compareFunction(a.AufmId, b.AufmId, true));
    return sorted;
  }

  public async findOneBy(selector: string, value: string): Promise<Aufmass> {
    return await this.baseService.findOneBy(Aufmass, selector, value);
  }

  public async overrideOneLocal(measurement: Aufmass): Promise<void> {
    await this.destroy('Uuid', measurement.Uuid);
    await this.controllerService.setData('Aufmass', [measurement]);
  }

  async pushToWebService(): Promise<void> {
    const measurements = await this.getAll();
    const untransferred = measurements.filter(measurement => measurement.fullyTransferred === false);
    for (const measurement of untransferred) {
      await this.syncUntransferredPositionOfAufmass(measurement);
      await this.roomBookService.syncAllUntransferred(measurement);
    }
  }

  public async addRoom(
    measurement: Aufmass,
    roomBookPosition: RoomBookPosition,
    syncAfterFail: boolean,
    silent: boolean,
  ): Promise<void> {
    const targetUrl = 'neuerRaum';
    if (!syncAfterFail) measurement.addRoomToRoomBookPositions(roomBookPosition);
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...füge Raum hinzu...');
    const successData = await this.restService.returnData<boolean>(targetUrl, roomBookPosition);
    const success = GlobalHelper.isNullOrUndefined(successData) ? false : successData;
    measurement.fullyTransferred = success;
    roomBookPosition.transferred = success;
    await this.overrideOneLocal(measurement);
    if (!silent) this.dialogService.closeLoadingDialog();
  }

  async deleteRoom(measurement: Aufmass, roomBookPosition: RoomBookPosition, silent: boolean): Promise<void> {
    const deletePositions = measurement.removeRoomFromRoomBookPositions(roomBookPosition);
    const targetUrl = 'deleteRooms';
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...entferne Raumbuchposition...');
    await this.restService.returnData(targetUrl, deletePositions);
    await this.overrideOneLocal(measurement);
    if (!silent) this.dialogService.closeLoadingDialog();
  }

  public async saveAufmass(aufmass: Aufmass, userInfo: UserInfo, silent: boolean): Promise<boolean> {
    const targetUrl = 'Aufmass';
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...speichere Aufmass...');
    const measurementId = await this.restService.returnData<string>(targetUrl, aufmass, true);
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(measurementId)) {
      const retry = await this.dialogService.openConfirmDialog(
        'Fehler',
        'Eine Aufmaßanlage ist nur online möglich. Prüfen Sie Ihre Internetverbindung und stellen sicher, dass der Webservice korrekt ausgeführt wird.',
        'Erneut versuchen',
        'Abbrechen',
      );
      if (retry) return await this.saveAufmass(aufmass, userInfo, silent);
      return false;
    }
    aufmass.AufmId = measurementId;
    aufmass.assignAufmassIdToRoomBookPositions();
    await this.overrideOneLocal(aufmass);
    if (!silent) this.dialogService.closeLoadingDialog();
    return true;
  }

  getRequiredObjects(): SyncObject[] {
    return [Medien, HWAddress, Projekt];
  }

  private async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(Aufmass, selector, value);
  }

  private async syncUntransferredPositionOfAufmass(aufmass: Aufmass): Promise<void> {
    const untransferredRooms = aufmass.getRoomBookPosition().filter(pos => pos.transferred === false);
    const results = [];
    for (const room of untransferredRooms) results.push(this.addRoom(aufmass, room, true, false));
    await Promise.all(results);
  }

  async getFormulasFromWebService(userInfo: UserInfo, silent: boolean): Promise<void> {
    const license = this.rightsService.getCurrentRight().employeeRights.showObjektadressen;
    if (!license) return;
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole Aufmaß-Formeln...');
    const targetUrl = 'Formeln/mandant/' + userInfo.mandant;
    const responseData = await this.restService.returnData<MeasurementFormulaRaw>(targetUrl, userInfo);
    if (!silent) this.dialogService.closeLoadingDialog();
    if (!responseData) return;
    await this.globalSettingService.setEntity(responseData, GlobalSettings.Formeln);
  }
}
