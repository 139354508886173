import { Component, OnInit } from '@angular/core';
import { maintenanceSystemSearch } from '../../../../config';
import { HWAnlage } from '../../../../entities';
import { MaintenanceSystemService, SyncObjectService } from '../../../../services';

@Component({
  selector: 'app-systems-index',
  templateUrl: './systems-index.component.html',
  styleUrls: ['./systems-index.component.scss'],
})
export class SystemsIndexComponent implements OnInit {
  anlagen: HWAnlage[];
  searchExpressions = maintenanceSystemSearch;

  constructor(
    private maintenanceSystemService: MaintenanceSystemService,
    private syncObjectService: SyncObjectService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.anlagen = await this.maintenanceSystemService.getAnlagenFromIDB();
    this.syncObjectService.currentSyncObjects.next([HWAnlage]);
  }
}
