import { Component, OnInit } from '@angular/core';
import { GlobalHelper, RoutingService } from '@handwerk-pwa/shared';
import { RepairOrderStates, repairOrderSearch } from '../../../config';
import { HWRepairOrder, RepairOrderStatus } from '../../../entities';
import { GlobalSettingService, RepairOrderService, SyncObjectService } from '../../../services';

@Component({
  selector: 'app-repair-orders-index',
  templateUrl: './repair-orders-index.component.html',
  styleUrls: ['./repair-orders-index.component.scss'],
})
export class RepairOrderIndexComponent implements OnInit {
  statusTypes: RepairOrderStatus[] = RepairOrderStatus.createAllStates(true);
  selectedStatusType: RepairOrderStatus;
  allRepairOrders: HWRepairOrder[];
  filteredRepairOrders: HWRepairOrder[];
  searchExpression = repairOrderSearch;

  constructor(
    private repairOrderService: RepairOrderService,
    private globalSettingService: GlobalSettingService,
    private routingService: RoutingService,
    private syncObjectService: SyncObjectService,
  ) {}

  /**@description Delay sonst sind Daten noch nicht vorhanden*/
  async ngOnInit(): Promise<void> {
    this.allRepairOrders = this.repairOrderService.sortRepairOrders(
      await this.repairOrderService.getAllRepairOrdersFromIDB(),
    );
    this.filteredRepairOrders = this.allRepairOrders;
    const lastSelectionStatus = this.globalSettingService.lastRepairOrderSelectiontype;
    if (GlobalHelper.isNullOrUndefined(lastSelectionStatus)) {
      this.filterStatusTrigger(new RepairOrderStatus(RepairOrderStates.All));
    } else {
      this.filterStatusTrigger(lastSelectionStatus);
    }

    this.syncObjectService.currentSyncObjects.next([HWRepairOrder]);
  }

  filterStatusTrigger(newState: RepairOrderStatus): void {
    const index = this.statusTypes.findIndex(state => state.stateNumber === newState.stateNumber);
    this.selectedStatusType = this.statusTypes[index];
    this.filteredRepairOrders = this.allRepairOrders.filter(repairOrder => repairOrder.Status === newState.stateNumber);
    if (newState.stateName === 'Alle') {
      this.filteredRepairOrders = this.allRepairOrders;
    }
    this.globalSettingService.lastRepairOrderSelectiontype = newState;
  }

  async newRepairOrder(): Promise<void> {
    await this.routingService.navigateTo('reparaturauftrag/neu');
  }
}
