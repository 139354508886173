import { Injectable } from '@angular/core';
import { DialogService, RestService, RightsService, UserInfo } from '@handwerk-pwa/shared';
import {
  Anlagentermin,
  HWAddress,
  HWAnlage,
  HWDatenblatt,
  Medien,
  Messwerttabelle,
  NotificationWay,
  SyncObject,
} from '../../entities';
import { MaintenanceHelper } from '../../helper';
import { ControllerService } from '../globalServices/controller.service';
import { AddressService } from './address.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceSystemService implements DataService {
  serviceName = 'MaintenanceSystemService';
  constructor(
    private dialogService: DialogService,
    private restService: RestService,
    private controllerService: ControllerService,
    private addressService: AddressService,
    private rightsService: RightsService,
  ) {}

  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    await this.pushToWebService(userInfo);
    await this.getFromWebService(userInfo, silent);
  }

  /**@description Holt alle Anlagen aus der IDB */
  async getAnlagenFromIDB(): Promise<HWAnlage[]> {
    const anlagenData = await this.controllerService.getData<HWAnlage[]>('HWAnlage');
    const anlagen: HWAnlage[] = [];
    for (const data of anlagenData) anlagen.push(data);
    return anlagen;
  }

  /**
   * @description Holt eine Anlage aus der IDB mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async findOneAnlageBy(selector: string, value: string): Promise<HWAnlage> {
    const anlagenData = await this.controllerService.getData<HWAnlage[]>('HWAnlage', value, selector);
    return anlagenData[0];
  }

  async findOneAnlageForMediaViewBy(selector: string, value: string): Promise<HWAnlage> {
    const anlagenData = await this.controllerService.getData<HWAnlage[]>('HWAnlage', value, selector);
    return new HWAnlage(anlagenData[0], [], []);
  }

  async getFromWebService(userInfo: UserInfo, silent: boolean): Promise<void> {
    const hasRight = this.rightsService.getCurrentRight().employeeRights.maintenanceRights.enableMaintenanceSystems;
    if (!hasRight) return;

    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole Anlagen...');
    const customers = await this.addressService.getAll();
    const datenblaetter = await this.getDatenblaetterFromWebservice(userInfo, silent);
    const anlagen: HWAnlage[] = [];
    const anlagenData = await this.restService.returnData<HWAnlage[]>(
      `WAnlagen/mandant/${userInfo.mandant}/username/${userInfo.monteur}`,
    );
    for (const data of anlagenData) {
      const anlage = new HWAnlage(data, customers, datenblaetter);
      anlage.addAdditionalAnlagendata(datenblaetter);
      anlagen.push(anlage);
    }
    await this.writeAnlagenToIDB(anlagen, true);
  }

  async getDatenblaetterFromWebservice(userInfo: UserInfo, silent: boolean): Promise<HWDatenblatt[]> {
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole Datenblätter...');
    const datenblaetter: HWDatenblatt[] = [];
    const datenblaetterData = await this.restService.returnData<HWDatenblatt[]>(
      `WDatenbls/mandant/${userInfo.mandant}/username/${userInfo.monteur}`,
    );
    if (!datenblaetterData) return [];
    for (const data of datenblaetterData) {
      const datenblatt = new HWDatenblatt(data);
      datenblaetter.push(datenblatt);
    }
    await this.writeDatenblaetterToIDB(datenblaetter, true);
    return datenblaetter;
  }

  /**@description Sendet einen Messwer ans Handwerk */
  async sendMessungToWebservice(
    anlage: HWAnlage,
    messung: Messwerttabelle,
    userInfo: UserInfo,
    silent = false,
  ): Promise<void> {
    const sendMessung = MaintenanceHelper.transformMessungForHandwerkDb(anlage, messung, userInfo);
    const send = await this.restService.returnData<boolean>('WriteAnlagenmessung', sendMessung, silent);
    messung.notTransferred = !send;
    anlage.Messwertsatz.Istwerttabellen.push(messung);
    await this.updateAnlageInIdb(anlage);
  }

  /**@description Schickt die Messungen die nicht übertragen wurden an das Handwerk */
  async pushToWebService(userInfo: UserInfo): Promise<void> {
    const anlagen = await this.getAnlagenFromIDB();
    const anlageAndMessung = MaintenanceHelper.getUntransferredMessungen(anlagen);
    if (anlageAndMessung.length === 0) return;
    const results = [];
    for (const dataset of anlageAndMessung) {
      const anlage = dataset.Anlage;
      const messungen = dataset.Messungen;
      for (const messung of messungen) results.push(this.sendMessungToWebservice(anlage, messung, userInfo, true));
    }
    await Promise.all(results);
  }

  getWayOfNotification(anlagenTermin: Anlagentermin): string {
    const notificationNumber = anlagenTermin.NACHRICHT;
    switch (notificationNumber) {
      case NotificationWay.Telephone: {
        return 'Telefonisch';
      }
      case NotificationWay.Written: {
        return 'Schriftlich';
      }
      case NotificationWay.Fax: {
        return 'Fax';
      }
      case NotificationWay.EMail: {
        return 'E-Mail';
      }
      case NotificationWay.NoNotification: {
        return 'Keine Benachrichtigung';
      }
      case NotificationWay.NotPlanned: {
        return 'Ohne Planung';
      }
    }
  }

  getRequiredObjects(): SyncObject[] {
    return [HWAddress, Medien];
  }

  /*******************************************************************************
   *
   *
   *
   * PRIVATE FUNCTIONS
   *
   *
   *
   ******************************************************************************** */

  /**@description  Überschreibt die anlage in der IDb */
  private async updateAnlageInIdb(anlage: HWAnlage): Promise<void> {
    await this.controllerService.deleteData('HWAnlage', 'ANLAGE', anlage.ANLAGE);
    await this.controllerService.setData('HWAnlage', [anlage]);
  }

  private async writeDatenblaetterToIDB(datenblaetter: HWDatenblatt[], clear: boolean): Promise<void> {
    if (clear) await this.controllerService.clearStore('HWDatenblatt');
    await this.controllerService.setData('HWDatenblatt', datenblaetter);
  }

  private async writeAnlagenToIDB(anlagen: HWAnlage[], clear: boolean): Promise<void> {
    if (clear) await this.controllerService.clearStore('HWAnlage');
    await this.controllerService.setData('HWAnlage', anlagen);
  }
}
