import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AuthorizationService,
  ContactHelper,
  DialogService,
  FeatureNames,
  GlobalHelper,
  Right,
  RightsService,
  RoutingService,
  UserInfo,
} from '@handwerk-pwa/shared';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppointmentService } from '../../..//services';
import { AddressShortTypes, GlobalSettings, countryCodes } from '../../../config';
import { HWAddress, HWFile, Zusatzbezeichnungen } from '../../../entities';
import { AddressTypeHelper } from '../../../helper';
import {
  ActivityTrackerService,
  AddressService,
  BelegService,
  ConnectionDialogues,
  ConnectionService,
  ContactPersonService,
  ContactsService,
  GlobalSettingService,
  MediaService,
  ObjectaddressService,
  OffenepostenService,
  UmsatzService,
} from '../../../services';

@Component({
  selector: 'app-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss'],
})
export class AddressEditComponent implements OnInit, OnDestroy {
  addressTypes: Array<'Kunden' | 'Freie Adressen'> = ['Kunden', 'Freie Adressen'];
  selectedType: 'Kunden' | 'Freie Adressen' = 'Kunden';
  address: HWAddress;
  addressRight: boolean;
  documentRight: boolean;
  salesRight: boolean;
  vacantPositionsRight: boolean;
  showAdditionalInfo = false;
  addressEditRight = true;
  appointmentCount = '';
  objectAddressesCount = '';
  mediaButtonEnabled = false;
  additionalDesignations: Zusatzbezeichnungen;
  selectedFile: HWFile;
  userInfo: UserInfo;
  currentOpenItemsStartAmount = '(?)';
  currentTurnoverStartAmount = '(?)';
  currentReceiptsStartAmount = '(?)';
  currentContactPeopleStartAmount = '(?)';
  currentContactsStartAmount = '(?)';
  currentFilesAmount: string;
  countryCodes = countryCodes;
  viewFinished: boolean; // Put at the end of the onInit otherwise were wrongly assume that new data is available
  dataChanged: BehaviorSubject<boolean>;
  subscription: Subscription;
  addressFullType: string;
  createdForRepairOrder = false;
  isNewAddress: boolean;
  callPhone = ContactHelper.callPhone;
  goToHomepage = ContactHelper.goToHomepage;
  mailTo = ContactHelper.mailTo;
  rights: BehaviorSubject<Right>;
  isPremium: boolean;

  constructor(
    private globalSettingService: GlobalSettingService,
    private addressService: AddressService,
    private appointmentService: AppointmentService,
    private rightsService: RightsService,
    private openItemsService: OffenepostenService,
    private turnOverService: UmsatzService,
    private receiptService: BelegService,
    private contactPersonService: ContactPersonService,
    private contactService: ContactsService,
    private objectAddressService: ObjectaddressService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private activityTrackerService: ActivityTrackerService,
    private authorizationService: AuthorizationService,
    private connectionService: ConnectionService,
    private mediaService: MediaService,
  ) {
    this.rights = this.rightsService.getCurrentRightAsync();
  }

  async ngOnInit(): Promise<void> {
    this.createdForRepairOrder = this.routingService.getCurrentRoute()?.includes('repairOrder');
    this.userInfo = await this.globalSettingService.getUserInfo();
    this.subscription = this.routingService.save.subscribe(() => {
      void this.save(this.createdForRepairOrder);
    });
    this.dataChanged = this.routingService.dataChanged;
    this.address = await this.getAddress();

    const isNew = this.address.KU_NR === '-1';
    this.isNewAddress = isNew;

    if (!isNew) this.addressFullType = AddressTypeHelper.getAddressesFullType(this.address.ADRTYP);

    const passedCheck = await this.handleRights(isNew);
    if (!passedCheck) {
      this.routingService.routeBack();
      return;
    }

    if (!isNew) {
      await this.getAdditionalAddressData(this.userInfo);
    }
    this.isPremium = this.rightsService.isPremium();
    this.viewFinished = true;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  selectedNextType(type: 'Kunden' | 'Freie Adressen', address: HWAddress): void {
    if (type === 'Kunden') address.ADRTYP = 'K';
    if (type === 'Freie Adressen') address.ADRTYP = 'F';
  }
  hasAddressChangeRight(typ: AddressShortTypes): boolean {
    const addressRights = this.rightsService.getCurrentRight().employeeRights.addressRights;
    switch (typ) {
      case 'F':
        return addressRights.editFreeAddresses;
      case 'K':
        return addressRights.editCustomers;
      case 'L':
        return addressRights.editSuppliers;
      case 'M':
        return addressRights.editEmployees;
      case 'O':
        return addressRights.editCustomers;
    }
  }

  async save(createdForRepairOrder: boolean): Promise<void> {
    const hasConnection = await this.connectionService.checkOnline(ConnectionDialogues.CreateData, false);
    if (!hasConnection) return;
    if (!this.address.NAME) {
      this.dialogService.openInformDialog('Fehler', 'Es wurde noch kein Name eingegeben.', 'Ok');
      return;
    }
    void this.dialogService.openLoadingDialog('Speichern', 'Speichere Datensatz.');
    if (this.isNewAddress) this.address.SUCH = this.address.SUCH ?? this.address.NAME.toLocaleUpperCase();
    await this.activityTrackerService.countUpAutoSyncs();
    const newAddress = await this.addressService.save(this.address);
    this.dialogService.closeLoadingDialog();
    this.routingService.dataChanged.next(false);
    const customerGuid = newAddress.Guid;
    const nextUrl = createdForRepairOrder
      ? `reparaturauftrag/neu/adresse/${customerGuid}`
      : `adresse/${customerGuid}/edit`;
    await this.routingService.navigateTo(nextUrl);
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  async newAppointment(): Promise<void> {
    const dateInMs = new Date().getTime();
    await this.routingService.navigateTo(`termin/neu/${dateInMs}/${this.address.KU_NR}`);
  }

  async newImages(): Promise<void> {
    await this.routingService.navigateTo(`medien/Address/${this.address.Guid}/upload`);
  }

  openMaps(item: HWAddress): void {
    const place = item.ORT;
    const street = item.STRASSE;
    window.open(`https://www.google.com/maps/search/?api=1&query=${street}%2C+${place}_blank`);
  }

  private async getAdditionalAddressData(userInfo: UserInfo): Promise<void> {
    const customerNumber = this.address.KU_NR;
    this.mediaButtonEnabled = ['K', 'M', 'F', 'O'].includes(this.address.ADRTYP);
    const currentItems = await this.openItemsService.getOffenePostenByKundennummerFromIDB(customerNumber);
    this.currentOpenItemsStartAmount = GlobalHelper.calculateDataAmount(currentItems);
    const newVersion = this.authorizationService.current.getValue().featureCheck(FeatureNames.mediaTable2).available;
    const files = newVersion
      ? await this.mediaService.getAllBy('Baseuuid', this.address.getUuid())
      : this.address.Files;
    this.currentFilesAmount = GlobalHelper.calculateDataAmount(files);
    const currentTurnover = await this.turnOverService.getAllBy('Kundennummer', customerNumber);
    this.currentTurnoverStartAmount = GlobalHelper.calculateDataAmount(currentTurnover);
    const currentReceipts = await this.receiptService.getAllBy('kunde', customerNumber);
    this.currentReceiptsStartAmount = GlobalHelper.calculateDataAmount(currentReceipts);
    const currentContacts = await this.contactService.getAllBy('KU_NR', customerNumber);
    this.currentContactsStartAmount = GlobalHelper.calculateDataAmount(currentContacts);
    const currentContactPeople = await this.contactPersonService.getAllBy('KuNr', customerNumber);
    this.currentContactPeopleStartAmount = GlobalHelper.calculateDataAmount(currentContactPeople);
    const currentObjectAddresses = await this.objectAddressService.getAllBy('KuNr', customerNumber);
    this.objectAddressesCount = GlobalHelper.calculateDataAmount(currentObjectAddresses);
    this.additionalDesignations = await this.globalSettingService.getEntity<Zusatzbezeichnungen>(
      GlobalSettings.AdditionalDesignations,
    );
    const customerAppointments = await this.appointmentService.getAllAppointmentsFromIDB(userInfo, customerNumber);
    this.appointmentCount = `(${customerAppointments.length})`;
  }

  private async getAddress(): Promise<HWAddress> {
    if (this.routingService.getCurrentRoute().includes('neu')) return await this.addressService.createNewAddress();
    const addressGuid = this.routingService.getRouteParam('guid');
    return await this.addressService.findOneBy('Guid', addressGuid);
  }

  /**@description Gets and sets the rights */
  private async handleRights(isNewAddress: boolean): Promise<boolean> {
    const editCreateFrei = this.hasAddressChangeRight('F');
    const editCreateKunden = this.hasAddressChangeRight('K');
    const creationRight = editCreateFrei || editCreateKunden;
    if (isNewAddress && !creationRight) {
      await this.dialogService.openErrorMessage('Fehler', 'Ihnen fehlt das Recht zur Anlage einer neuen Adresse.');
      return false;
    }
    if (!editCreateFrei) this.addressTypes = GlobalHelper.removeFromArray(this.addressTypes, 'Freie Adressen');
    if (!editCreateKunden) this.addressTypes = GlobalHelper.removeFromArray(this.addressTypes, 'Kunden');
    if (isNewAddress) {
      this.selectedType = this.addressTypes[0];
      this.selectedNextType(this.selectedType, this.address);
    }
    const rights = this.rightsService.getCurrentRight();
    this.documentRight = rights.employeeRights.receiptRights.enableModule;
    this.salesRight = rights.employeeRights.addressRights.showTurnover;
    this.vacantPositionsRight = rights.employeeRights.addressRights.showOpenPositions;
    const typ = this.address.ADRTYP;
    this.addressEditRight = isNewAddress ? true : this.hasAddressChangeRight(typ);
    return true;
  }
}
