import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, RightsService, RoutingService, UserInfo } from '@handwerk-pwa/shared';
import { Subscription } from 'rxjs';
import { positionsSearch, readonlyPosition } from '../../../config';
import { Dokumentposition, HWRepairOrder, HWRepairOrderItem, PositionsArt, ServiceAuftrag } from '../../../entities';
import { BaseAuftrag, BaseDocumentPosition } from '../../../interfaces';
import {
  ActivityTrackerService,
  GlobalSettingService,
  RepairOrderService,
  ServiceOrderService,
} from '../../../services';

@Component({
  templateUrl: './order-item-edit.component.html',
  styleUrls: ['./order-item-edit.component.scss'],
})
export class OrderItemEditComponent implements OnInit, OnDestroy {
  currentItem: BaseDocumentPosition;
  order: BaseAuftrag;
  menge: number;
  einzelpreisDescription: string;
  gesamtPreisDescription: string;
  userInfo: UserInfo;
  leistungsPositionen: BaseDocumentPosition[];
  currentType: PositionsArt;
  einzelPreis: number;
  gesamtPreis: number;
  showPrices: boolean;
  orderType: string;
  orderId: string;
  itemId: string;
  editable: boolean;
  readonlyPositionText = readonlyPosition;
  saveSubscription: Subscription;
  searchExpressions = positionsSearch;

  constructor(
    private globalSettingService: GlobalSettingService,
    private repairOrderService: RepairOrderService,
    private dialogService: DialogService,
    private serviceOrderService: ServiceOrderService,
    private routingService: RoutingService,
    private rightsService: RightsService,
    private activityTrackerService: ActivityTrackerService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.saveSubscription = this.routingService.save.subscribe(nextRoute => void this.saveChanges(nextRoute));

    this.userInfo = await this.globalSettingService.getUserInfo();

    this.editable = await this.loadOrder(this.userInfo);
    this.loadItem();

    const showPricesRight = this.rightsService.getCurrentRight().employeeSettings.showPrices;
    this.showPrices = this.order.getShowPrices(showPricesRight);
    this.getPrices(this.currentItem, this.order.getKunde().priceGroup);
    this.currentType = this.currentItem.getLongtype();
    this.einzelpreisDescription = this.currentItem.getEinzelpreisDescription();
    this.gesamtPreisDescription = this.currentItem.getGesamtpreisDescription();
    this.leistungsPositionen = this.order.getSubPositionsOfServices(this.currentItem);
  }

  async loadOrder(userInfo: UserInfo): Promise<boolean> {
    this.orderType = this.routingService.getRouteParam('type');
    this.orderId = this.routingService.getRouteParam('id');

    if (this.orderType === 'reparaturauftrag') {
      this.order = await this.repairOrderService.findOneBy('Guid', this.orderId);
    } else if (this.orderType === 'wartungsauftrag') {
      this.order = await this.serviceOrderService.findOneBy('UUID', this.orderId);
    }
    return this.order.isEditable(userInfo);
  }

  loadItem(): void {
    this.itemId = this.routingService.getRouteParam('position_uuid');
    this.currentItem = this.order.findOrderItemByUniqueIdentifier(this.itemId);
  }

  /**@description Wird von alle Änderungen aufgerufen */
  korrektur(
    currentItem: BaseDocumentPosition,
    order: BaseAuftrag,
    neuerWert: number | string,
    changeType: 'Preis' | 'Zeit' | 'Menge' | 'Bezeichnung' | 'Langtext',
    isGesamtpreis?: boolean,
  ): void {
    const isRepairOrder = order.auftragsArt === 'Reparaturauftrag';
    const currentCastedItem = isRepairOrder ? (currentItem as HWRepairOrderItem) : (currentItem as Dokumentposition);
    const castedItemInOrder = order.findOrderItem(currentCastedItem);
    castedItemInOrder.updateItem(changeType, neuerWert, isGesamtpreis);
    const priceGroup = order.getKunde().priceGroup;
    this.getPrices(castedItemInOrder, priceGroup);
    this.routingService.dataChanged.next(true);
  }

  /**@description Wird durch den Data changed Mechanismus aus dem RoutingService getriggert um einmalig am Ende beim Verlassen bei Änderungen zu speichern */
  async saveChanges(nextRoute: string): Promise<void> {
    const isRepairOrder = this.order.auftragsArt === 'Reparaturauftrag';
    const isServiceOrder = this.order.auftragsArt === 'Serviceauftrag';

    await this.activityTrackerService.countUpAutoSyncs();

    if (isRepairOrder) await this.repairOrderService.sendRepairOrderToWebService(this.order as HWRepairOrder);
    if (isServiceOrder) await this.serviceOrderService.sendServiceOrderToWebService(this.order as ServiceAuftrag, true);
    this.dialogService.closeLoadingDialog();
    this.routingService.dataChanged.next(false);
    void this.routingService.navigateTo(nextRoute);
  }

  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
  }

  private getPrices(castedItemInOrder: BaseDocumentPosition, priceGroup: string): void {
    this.einzelPreis = castedItemInOrder.getEinzelDisplayprice(priceGroup);
    this.gesamtPreis = castedItemInOrder.getGesamtDisplayPrice(priceGroup);
  }
}
