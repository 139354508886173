import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SyncObject } from '../../entities';

@Injectable({
  providedIn: 'root',
})
export class SyncObjectService {
  currentSyncObjects = new BehaviorSubject<SyncObject[]>([]);
}
